import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Card, Switch, Alert } from "antd";
import Button from "../../components/Design/Button";
import * as Yup from "yup";
import { auth } from "../../apis";
import UserIcon from "../../assets/user.png";
import Logo from "../../assets/logo_white.png";
import RakuLineLogoImage from "../../assets/logo_rakuline_white.png";
import Humans from "../../assets/humans.png";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export default () => {
  const { translate, changeLanguage, lang } = useTranslate();
  const [message, setMessage] = React.useState(null);
  const [alertBgColor, setAlertBgColor] = React.useState(colors.primaryDark);

  const onSubmitRecoveryPassword = async (data) => {
    try {
      await auth.forgetPassword(data);
      setAlertBgColor("#58D77C");
      setMessage(<div style={{ color: "white", fontWeight: "600" }}><CheckCircleOutlined style={{ marginRight: "6px" }}/> {translate("login.register.checkmail", "Please check your email for a message with your verify link.")}</div>);

    } catch (error) {

      setAlertBgColor("#E35755");
      setMessage(notfication(error.message, "error"));
    }
  };
  const notfication = (data, type) =>{
    if (data === "USER_NOTFOUND") {
      if (type === "error")
        return <div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("forgot.warning", "This E-mail isn't registered !")}</div>;
    }
    if (data === "USER_NOT_VERIFIED") {
      if (type === "error")
        return <div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate(" login.user.not.verified", "Your account hasn't been verified !")}</div>;
    }
  };

  const FormSchemaREcovryPassword = (translate) => Yup.object().shape({
    email: Yup.string().required(translate("system.message.required", "This field is required!")),
  });

  return (
    <div>
      <AuthWrapper>
        <Card className="login">
          <div className="logo">
            <img className="logo" src={process.env.REACT_APP_WEBSITE_MODE == "rakuline" ? RakuLineLogoImage : Logo} />
          </div>
          <div>
            <p className="find-account-text">
              {translate("forgot.email", "E-mail")}
            </p>
          </div>
          {message && (
            <Alert
              style={{
                marginBottom: 20,
                background  : alertBgColor,
                border      : "none",
                borderRadius: "10px",
              }}
              message={<div style={{ color: "white" }}>{message}</div>}
            />
          )}
          <Formik
            initialValues={{
              email: undefined,
            }}
            validationSchema={() => FormSchemaREcovryPassword(translate)}
            onSubmit={onSubmitRecoveryPassword}
          >
            {({ isSubmitting }) => (
              <Form layout="vertical">
                <FormItem name="email">
                  <Input
                    className="form-input"
                    prefix={<img style={{ width: "14px" }} src={UserIcon} />}
                    name="email"
                    placeholder={translate("login.mail", "Example@yahoo.com")}
                  />
                </FormItem>

                <Button
                  className="form-button"
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                  block
                >
                  <span>{translate("forgot.reset", "Reset password")}</span>
                </Button>

                <p className="form-footer-text">
                  {translate(
                    "login.register.text.hasaccount",
                    "Already has an acount ?"
                  )}{" "}
                  <Link className="form-text" to="/login">
                    {translate("login.register.hyperlink", "Log in")}
                  </Link>
                </p>
              </Form>
            )}
          </Formik>
          <Switch
            style={{ marginTop: "10px" }}
            checkedChildren="JP"
            unCheckedChildren="EN"
            onChange={() => changeLanguage(lang === "EN" ? "JP" : "EN")}
            defaultChecked={lang === "EN"}
          />
          <img className="humans-image" src={Humans} />
        </Card>
      </AuthWrapper>
    </div>
  );
};

const AuthWrapper = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;

  .find-account-text {
    font-size: 16px;
    color: white;
    font-weight: 900;
  }
  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .login {
    background: ${colors.primary};
    border-radius: 10px;
    width: 400px;
    margin: auto;
    padding-bottom: 10px;

    .form-input .icon {
      width: 14px;
    }
    .form-input {
      height: 45px;
      border-radius: 10px;
    }
    .logo {
      text-align: center;
      margin: 10px 0 30px 0;
      img {
        height: 80px;
        margin: auto;
      }
    }
    .forgot {
      text-align: right;
      margin-top: 10px;
    }
    .form-text {
      color: white;
      font-weight: 400;
      font-size: 14px;
      transition: all 0.3s ease-in;
      &:hover {
        color: #007b3b;
        text-decoration-line: underline;
      }
    }
    .form-button {
      margin-top: 10px;
      height: 40px;
      border-radius: 10px;
      background-color: ${colors.primaryDark};
      border: none;
      font-size: 21px;
      font-weight: bold;
      transition: all 0.3s ease-in;
      &:hover {
        background-color: #007b3b;
      }
    }
    .register {
      text-align: center;
      margin-top: 10px;
    }
    .humans-image {
      position: relative;
      bottom: -20px;
      left: -20;
    }
    .form-logo-image {
      position: absolute;
      left: -141px;
    }
    .form-footer-text {
      font-size: 12px;
      padding-top: 7px;
      font-weight: 500;
      text-align: center;
      color: white;
    }
  }
`;