import React from "react";
import { FormItem, Input, Select, Checkbox } from "formik-antd";
import { useTranslate } from "../../../hooks";
import { useSelector } from "react-redux";
import { Alert, Tooltip, Button, Modal, Tag } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import SelectList from "../../Coupon/SelectList";
import { ecm as ecmApi } from "../../../apis";
import colors from "../../../colors";
import styled from "styled-components";
import CustomTable from "../../../components/CustomTable";

const { Option } = Select;

const getNestedValues = (values, key) => {
  return key.split(".").reduce((acc, key) => (acc ? acc[key] : undefined), values);
};

export default ({ disabled, setFieldValue, setFieldTouched, values, actionType, linkUri, externalBrowser, isTagged, tagType, taggedUrl, couponCode, additionalParameter, })=> {

  const onClearTaggedUrl = (setFieldValue, index, e) => {
    if (!e){
      setFieldValue(isTagged, false);
      setFieldValue(tagType, 0);
      setFieldValue(taggedUrl, undefined);
    }
    if (!e?.target?.value || e?.target?.value === ""){
      setFieldValue(isTagged, false);
      setFieldValue(tagType, 0);
      setFieldValue(taggedUrl, undefined);
    }
    if (e?.target?.value && (e?.target?.value.includes("mailto:") || e?.target?.value.includes("tel:"))){
      setFieldValue(`${externalBrowser}`, false);
    }
  };

  const externalBrowserDisabled = (value) => {
    if (!value) return false;
    return value.includes("mailto:") || value.includes("tel:");
  };

  const { translate } = useTranslate();
  // const disabled = !values.;
  const { account } = useSelector(state => state.general);

  switch (getNestedValues(values, actionType)) {
    case "URL":
      return (
        <>
          <FormItem label={translate("richmenu.action.linkUri", "Link")} name={linkUri} required>
            <Input disabled={disabled} name={linkUri} placeholder="https://... , tel:0000000000, mailto:example@example.com" onChange={(e) => onClearTaggedUrl(setFieldValue, e)}/>
          </FormItem>
          <FormItem name={externalBrowser} required>
            <Checkbox disabled={externalBrowserDisabled(getNestedValues(values, linkUri)) || disabled} name={externalBrowser}>{translate("imagemap.external.browser", "Open in external browser")}</Checkbox>
            {(getNestedValues(values, linkUri) > 900 && getNestedValues(values, externalBrowser)) &&
              <Alert style={{ marginTop: 5 }} type="warning" message={
                <div>
                  {translate("actions.weblink.length.alert", "Link length maybe a too long to be processed by Line!")}
                </div>
              }
              />}
          </FormItem>
          {/* {<div>linkuri{getNestedValues(values, linkUri)}</div>} */}
          <FormItem name={isTagged} required>
            <Checkbox onChange={() => {
              setFieldValue(tagType, 0);
              setFieldValue(taggedUrl, undefined);
            }} disabled={!getNestedValues(values, linkUri) || disabled || (
              (process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && !account?.ecmid) ||
              (process.env.REACT_APP_WEBSITE_MODE === "rakuline" && !account?.rakurakuToken))} name={isTagged}>{
                <div>
                  { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                  <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                  </Tooltip>
                </div>
              }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
          </FormItem>
          <TaggedUrl
            values={values}
            disabled={disabled}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            tagType={tagType}
            isTagged={isTagged}
            couponCode={couponCode}
            additionalParameter={additionalParameter}
            taggedUrl={taggedUrl}
            linkUri={linkUri}
          />
        </>
      );
    case "COUPON":
      return (
        <>
          <FormItem name={couponCode}>
            <SelectList disabled={disabled} onChange={(e) => onClearTaggedUrl(setFieldValue, e)} name={couponCode} urlField={linkUri} />
          </FormItem>
          <FormItem style = {{ marginTop: "10px" }} name={additionalParameter} label={translate("imagemap.additional.parameter", "Additional Parameter")} >
            <Input disabled={disabled} name={additionalParameter} placeholder={translate("imagemap.additional.parameter.placeholder", "Additional Parameter")} />
          </FormItem>
          <FormItem style = {{ marginTop: "10px" }} name={externalBrowser} required>
            <Checkbox disabled={disabled} name={externalBrowser}>{translate("richmenu.external.browser", "Open in external browser")}</Checkbox>
          </FormItem>
          <FormItem name={isTagged} required>
            <Checkbox onChange={() => {
              setFieldValue(tagType, 0);
              setFieldValue(taggedUrl, undefined);
            }} disabled={!getNestedValues(values, couponCode) || disabled || (
              (process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && !account?.ecmid) ||
              (process.env.REACT_APP_WEBSITE_MODE === "rakuline" && !account?.rakurakuToken))} name={isTagged}>{
                <div>
                  { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                  <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                  </Tooltip>
                </div>
              }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
          </FormItem>
          <TaggedUrl
            values={values}
            disabled={disabled}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            tagType={tagType}
            isTagged={isTagged}
            couponCode={couponCode}
            additionalParameter={additionalParameter}
            taggedUrl={taggedUrl}
            linkUri={linkUri}
          />
        </>
      );
    default:
      return <div>{translate("actionfield.title.default", "Please select action type")}</div>;
  }
};

const CountDown = React.forwardRef((props, ref) => {
  const [timer, setTimer] = React.useState(0);
  const { callback } = props;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prev => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        if (prev !== 0) {
          return prev - 1;
        }
        return prev;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const startCount = async () => {
    setTimer(10);
  };

  React.useEffect(() => {
    if (timer >= 0) {
      callback(timer);
    }
  }, [timer]);

  React.useImperativeHandle(ref, () => ({
    async start(){
      startCount();
    }
  }));

  return (
    <div></div>
  );
});

const TaggedUrl = ({ values, setFieldValue, tagType, taggedUrl, setFieldTouched, linkUri, isTagged, couponCode, additionalParameter, disabled }) => {
  const countDownRef = React.useRef();
  const [timer, setTimer] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState([]);
  const { rakutentagTypes, taggedUrlDomains } = useSelector(state => state.general);
  const { translate } = useTranslate();
  const myTableRef = React.useRef();

  const onCancel = () => {
    setAction([null]);
  };

  const checkDomain = (url) => {
    try {
      const parsedUrl = new URL(url);
      const domain = parsedUrl.hostname;
      console.log("----------->domain:", domain, taggedUrlDomains.some(d => domain === d));
      return taggedUrlDomains.some(d => domain === d);
    } catch (err) {
      return false;
    }

  };

  const onGetTaggedUrl = async () => {
    let res;
    if ((getNestedValues(values, linkUri) && getNestedValues(values, linkUri) !== "")){
      if (!checkDomain(getNestedValues(values, linkUri))){
        setOpen(true);
        return;
      }
    }
    countDownRef.current.start();
    try {
      res = await ecmApi.taggedUrl({
        type               : getNestedValues(values, tagType),
        couponCode         : getNestedValues(values, couponCode),
        url                : getNestedValues(values, linkUri),
        additionalParameter: getNestedValues(values, additionalParameter)
      });
      setFieldValue(taggedUrl, res);
      console.log("----->", setFieldTouched);
      if (setFieldTouched){
        setFieldTouched(taggedUrl, true);
      }
    } catch (err) {
      console.log("==--get_tagurl---->", err);
      let error = {};
      if (err.message === "ECM_405") {
        error = {
          code   : "405",
          message: translate("action.form.failed.403", "Can't use this api with you Plan")
        };
      }
      else if (err.message === "ECM_HTTP_ERROR") {
        error = {
          code   : err.status_code,
          message: err.message
        };
      } else if (err.message === "ECM_RMS_LOGINERROR") {
        error = {
          code   : err.status_code,
          message: translate("action.form.failed.rmslogin", "RMS login error")
        };
      } else {
        error = {
          code   : err.message.code || err.code,
          message: err.message.message || err.message
        };
      }
      setAction(["error", error]);
    }
  };
  const ontagTypeChange = async () => {
    setFieldValue(taggedUrl, undefined);
  };

  if (getNestedValues(values, isTagged) === true) {
    return (
      <>
        <FormItem label={
          <div>
            {translate("action.form.tagtype", "Tag type")}
            <Tooltip placement="top" overlayInnerStyle={{ width: 700 }} overlayStyle={{ fontSize: 12, }} title={<div>
              <div>{translate("action.form.tooltip1", "Message type refers to each function of LINE Official Account.")}</div>
              <div>{translate("action.form.tooltip2", "By issuing a URL with a measurement tag for each message type, you can check the effect of attracting customers and sales by function on the report.")}</div>
              <div>{translate("action.form.tooltip3", "Usage:")}</div>
              <div>{translate("action.form.tooltip4", "・Normal message: Select this if the URL is used for LINE message delivery.")}</div>
              <div>{translate("action.form.tooltip5", "・Greeting message: Select this if the URL will be used for the greeting message automatically sent when the user adds the LINE official account as a friend.")}</div>
              <div>{translate("action.form.tooltip6", "・Rich menu: Select this if the URL is used for the rich menu that is fixed at the bottom of the LINE chat screen.")}</div>
              <div>{translate("action.form.tooltip7", "・Other: Select this if the URL is used for other functions such as timeline or chat.")}</div>
            </div>} hover><QuestionCircleOutlined />
            </Tooltip>
          </div>
        } name={tagType} required>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }} >
            <Select disabled={disabled} name={tagType} onChange={() => ontagTypeChange()} placeholder={translate("system.select", "Select")}>
              {
                rakutentagTypes.map((item, index) => {
                  return (<Option key={index} value={item.code}>{translate(`actionform.tagtype.${item.code}`, item.name)}</Option>);
                })
              }
            </Select>
            <Button disabled={timer > 0 || getNestedValues(values, tagType) === undefined || disabled} type="primary" onClick={onGetTaggedUrl}>
              {timer === 0 ? translate("actionform.geturl", "Get url"):(<div style={{ color: colors.primary }}>{timer}</div>)}
            </Button>
            <CountDown callback={(e) => setTimer(e)} ref={countDownRef} />
          </div>
        </FormItem>
        <FormItem label={translate("actionform.taggedurl", "Tagged link")} name={taggedUrl} required>
          <Input name={taggedUrl} style={{ cursor: "pointer", background: "#fff", color: "#000" }} disabled={true} placeholder="https://..." />
        </FormItem>
        {
          action && action[0] === "error" &&
          <Modal
            maskClosable={false}
            title={translate("action.form.failed.alert1", "Failed to get Rakuten tagged URL.")}
            visible={action && action[0] === "error"}
            destroyOnClose
            width={650}
            onCancel={onCancel}
            footer={false}>
            <Alert
              description={
                action[1]?.code === "405"
                  ? (<div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>)
                  : (<div>
                    <div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>
                    <div> {translate("action.form.failed.alert2", "From EC Masters Club or EC Masters Tools check")}</div>
                    <div> {translate("action.form.failed.alert3", "・R-Login ID")}</div>
                    <div> {translate("action.form.failed.alert4", "・R-Loginパスワード")}</div>
                    <div> {translate("action.form.failed.alert5", "・Rakuten user ID")}</div>
                    <div> {translate("action.form.failed.alert6", "・Rakute user password")}</div>
                    <div> {translate("action.form.failed.alert7", "are up to date.")}</div>
                    <br/>
                    <div> {translate("action.form.failed.alert8", "Also, make sure that the R-Login user authority is the [Normal] Rakuten member ID.")}</div>
                    <div> {translate("action.form.failed.alert9", "After confirming, please update the EC Masters Club or EC Masters Tools settings.")}</div>
                    <div> {translate("action.form.failed.alert10", "(Please perform update even if there is no change as a result of checking)")}</div>
                    <br/>
                  </div>)
              }
              type="warning"
              showIcon={false}
            />
          </Modal>
        }
        <Modal
          width={800}
          // maskClosable ={false}
          title={
            <div>
              {translate("taggedurl.error.modal.title", "Tagged url get error")}<Tag color="red">{translate("taggedurl.error.modal.tag", "Failed")}</Tag>
            </div>
          }
          visible={open}
          onCancel={() => setOpen(false)}
          destroyOnClose
          footer={false}>
          <Container>
            <div className="description">
              {translate("taggedurl.error.modal.desc", "楽天のドメイン以外のURLで計測タグ付きURLを発行することはできません。以下、計測可能なURL一覧です。こちらご確認のうえ発行をおこなってください。")}
            </div>
            <CheckTable
              ref={myTableRef}
              customPagination={false}
              dataSource ={taggedUrlDomains}
              bordered
              thead={() => (
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
                    <th className="ant-table-cell" rowSpan={2}>{translate("taggedurl.error.table.name", "Domain")}</th>
                    <th className="ant-table-cell" rowSpan={2}>{translate("taggedurl.error.table.description", "Description")}</th>
                  </tr>
                </thead>
              )}
              tbody={(row, index) => (
                <tbody key={2} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell">{index + 1}</td>
                    <td className="ant-table-cell ">{row}</td>
                    <td className="ant-table-cell ">{translate(`${row}.desc`, `${row}.desc`)}</td>
                  </tr>
                </tbody>
              )} />
          </Container>
        </Modal>
      </>
    );
  }
  return <div/>;
};


const CheckTable = styled(CustomTable)`
  .ant-table-content { 
    table {
      width : auto !important;
    }
  }
  .ant-table-cell {
    padding: 8px!important;
  }
`;

const Container = styled.div`
  margin-bottom : 20px;
  .description { 
    margin-bottom: 15px;
  }
  .btn-group {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }
  .ant-pagination { 
    display: none;
  }
`;


