/* eslint-disable no-fallthrough */
import React from "react";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { MyTable, RowAction } from "../../components";
import { useHistory } from "react-router-dom";
import { message } from "../../apis";
import { DatePicker, Empty, Input, Radio, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import { usePermission, useTranslate } from "../../hooks";
import moment from "moment";
import { ManualAlert, ManualLink } from "../../components/Manual";
import ManualDesc from "../../components/Manual/ManualDesc";

const { RangePicker } = DatePicker;

export default ()=>{
  const { translate } = useTranslate();
  const { checkRole } = usePermission();
  const myTableRef = React.useRef();
  const history = useHistory();
  const [query, setQuery] = React.useState("");
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]));
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  let firstDay = new Date(y, m - 1, 1);
  let lastDay = new Date(y, m + 1, 0);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query      : "",
    startDate  : moment(new Date(firstDay)).startOf("day").toDate(),
    endDate    : moment(new Date(lastDay)).endOf("day").toDate(),
    messageType: "MESSAGE"
  });

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const columns = useHeader({
    history,
    tableTitles: {
      no              : translate("system.number", "No."),
      title           : translate("system.title", "Title"),
      createdDate     : translate("message.createddate", "Created date"),
      type            : translate("message.type", "Type"),
      totalSendMessage: translate("message.totalsent", "Total send message"),
      sentType        : translate("message.senttype", "Sent type"),
      sentDate        : translate("message.sentdate", "Sent date"),
      action          : translate("system.action", "Action"),
      detail          : translate("system.detail", "Details"),
      reply           : translate("system.resend", "Resend"),
      remove          : translate("system.delete", "Remove"),
      followerName    : translate("message.followername", "LINEユーザー名"),
    },
    messageType: filters.messageType,
    onAction   : (key, item) => {
      switch (key) {
        case "reply": {
          history.push(`/message/send/reply/${item._id}`);
          break;
        }
        case "detail": {
          history.push(`/message/sent/detail/${item._id}`);
          break;
        }
        default:
          break;
      }
    },
    translate,
    disabled
  });

  React.useEffect(() => {
    setRangePicker({ startDate: moment(firstDay), endDate: moment(lastDay) });
  }, []);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.sent"></ManualLink>} title={translate("sent", "Sent list")}/>
      <ManualDesc translateKey ="manual.desc.messagesent" />
      <ManualAlert translateKey={"manual.alert.messagesent"} />
      <MessageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
          <Radio.Group defaultValue={filters.messageType} onChange={(e)=>{setFilters({ ...filters, messageType: e.target.value });}}>
            <Radio.Button value="MESSAGE">{translate("sent.message", "Message")}</Radio.Button>
            <Radio.Button value="SCENARIO">{translate("sent.scenario", "Scenario")}</Radio.Button>
            <Radio.Button value="TRIGGER">{translate("sent.trigger", "Trigger")}</Radio.Button>
            <Radio.Button value="THIRDPARTY">{translate("sent.thirdparty", "Third party")}</Radio.Button>
          </Radio.Group>
        </Space>
        <Space>
          {/* <RangePicker value={[filters.startDate, filters.endDate]} onChange={values => setFilters({ ...filters, startDate: values&& values[0], endDate: values&&values[1] })} /> */}
          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          {/* <Button type="default" onClick={() => setFilters({ ...filters, startDate: null, endDate: null })}>{translate("system.clear", "Clear")}</Button> */}
        </Space>
      </MessageFilter>
      <PageContent>
        <TableStyle>
          <MyTable
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            ref={myTableRef}
            filters={filters}
            rowKey={(record) => record._id}
            columns={columns}
            loadData={message.list("sent")}
            expandable
          />
        </TableStyle>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate, disabled, messageType, }) => {
  const columns = [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px",
  }, {
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title    : tableTitles.totalSendMessage,
    dataIndex: "totals",
  }, {
    title : tableTitles.sentDate,
    render: (item) => {
      return (
        <span>{moment(item.messageSentDate).format("YYYY-MM-DD HH:mm")}</span>
      );
    }
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      if (disabled && messageType !== "SCENARIO" && messageType !== "TRIGGER" && messageType !== "THIRDPARTY"){
        return <RowAction actions={{
          reply : tableTitles.reply,
          detail: tableTitles.detail,
        }} onClick={(key) => onAction(key, order)} />;
      }
      return <RowAction actions={{
        detail: tableTitles.detail,
      }} onClick={(key) => onAction(key, order)} />;
    }
  }];

  if (messageType === "MESSAGE"){
    let newcolumns = [...columns];
    newcolumns.splice(3, 0, {
      title : tableTitles.sentType,
      render: (record) => {
        switch (record.sendType) {
          case "NOW":
            return <div>{translate("system.constant.NOW", record.sendType)}</div>;
          case "DATE":
            return <div>{translate("system.constant.DATE", record.sendType)}</div>;
          case "REGULAR":
            return <div>{translate("system.constant.REGULAR", record.sendType)}</div>;
          default:
            return <div>{record.sendType || "-"}</div>;
        }
      },
    });
    return newcolumns;
  }
  if (messageType === "THIRDPARTY"){
    let newcolumns = [...columns];
    newcolumns.splice(4, 0, {
      title : tableTitles.followerName,
      render: (record) => {
        let followername = "-";
        if (record.followers?.length > 0) {
          followername = record.followers[0].name;
        }
        if (record.followers?.length > 1){
          followername += "、他";
        }
        return <div>{followername}</div>;
      },
    });
    return newcolumns;
  }

  return columns;
};

const TableStyle = styled.div`
.ant-table-row-expand-icon {
    display :none;
  }
`;

const MessageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;