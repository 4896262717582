import React from "react";
import { message, Spin } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { customer as customerService } from "../../apis";
import { useTranslate } from "../../hooks";
import EcmForm from "./components/EcmForm";
import RakurakuForm from "./components/RakurakuForm";


export default () => {
  const { translate } = useTranslate();
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [action, setAction] = React.useState(["update", null]);

  const reload = React.useCallback(
    async (signal) => {
      let res = await customerService.ecm(params.id, { signal });
      setAction(["update", {
        ...res,
      }]);
      setLoading(false);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  const onSubmit = async (data) =>{
    // console.log(data);
    // try {
    //   await customerService.ecmEdit(data);
    //   message.success(translate("system.message.success", "Successful"));
    //   history.push("/customer");

    // } catch (error) {
    //   console.log(error);
    //   message.error(translate("system.error", error.message));
    // }
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("rakuraku.add", "Add Rakuraku")} />
      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>
            {!loading ? <RakurakuForm action={action} onSubmit={onSubmit} /> : <Spin/>}
          </FormWrapper>
        </div>
      </PageContent>
    </PageContainer>
  );
};

const FormWrapper = styled.div`
  width: 100%;
  padding: 16px;
`;
