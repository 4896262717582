import { Collapse, Button, Alert, Modal, Tooltip, Tag } from "antd";
import { cooperation as cooperationApi, ecm as ecmApi } from "../../../../apis";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Select, Input, FormItem, Checkbox } from "formik-antd";
import CustomTable from "../../../../components/CustomTable";
import { useFetch, useTranslate } from "../../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import SelectList from "../../../Coupon/SelectList";
import { Formik, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../../colors";
import * as Yup from "yup";
import React from "react";
import EmojiTextField from "../../../../components/Message/components/EmojiTextField";

const ActionFormSchema = (translate) => Yup.object({
  type   : Yup.string().required(translate("system.message.required", "Required!")),
  // translate("webhook.url.invalid", "Format of the URL is wrong!")
  linkUri: Yup.string().matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("type", {
    is  : "uri" || "coupon",
    then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
  }),
  text: Yup.string().when("type", {
    is  : "message",
    then: Yup.string().max(250, translate("system.message.max250", "Max 250 character!")).required(translate("system.message.required", "Required!"))
  }),
  cooperation: Yup.string().when("type", {
    is  : "cooperation",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  couponCode: Yup.string().when("type", {
    is  : "coupon",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  lineBasicId: Yup.string().when("type", {
    is  : "share",
    then: Yup.string().required(translate("system.message.required", "Required!"))
  }),
  isTagged : Yup.boolean().nullable(),
  taggedUrl: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!"))
    .when("type", {
      is  : "uri",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    })
    .when("type", {
      is  : "coupon",
      then: Yup.string().when("isTagged", {
        is  : true,
        then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
      }).nullable()
    }).nullable(),
});

const FormSchema = (translate) => Yup.object({
  altText: Yup.string().required(translate("system.message.required", "Required!")).max(300, translate("system.message.imagemap.thirty", "Title must be under 30 characters !")),
  actions: Yup.array().of(ActionFormSchema(translate))
});

const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setValues } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    },
    async resetForm() {
      setValues({ ...values, actions: [] });
    },
    getValues() {
      return values;
    }
  }));

  return null;
});


const INITIALIZE_ACTION_DATA = {
  type           : undefined,
  linkUri        : undefined,
  text           : undefined,
  cooperation    : undefined,
  couponCode     : undefined,
  tagType        : 0,
  taggedUrl      : undefined,
  isTagged       : false,
  externalBrowser: false
};

export default React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { editable, templates, openCollapse } = props;
  const { imageMapSelect, imageMapActions, account } = useSelector(state => state.general);
  const [collapse, setCollapse] = React.useState(openCollapse);
  // const [isTagged, setisTagged] = React.useState(true);
  const [cooperations] = useFetch(cooperationApi.list)([]);
  const history = useHistory();
  const submitRef = React.useRef();
  const [data, setFormData] = React.useState({
    altText: undefined,
    actions: templates.map(() => ({
      ...INITIALIZE_ACTION_DATA
    })),
    ...(editable && editable[0] === "update" ? editable[1]: {})
  });


  const onChangeCollapse = (key) => {
    setCollapse(key);
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    },
    async clear() {
      await submitRef.current.resetForm();
    }
  }));

  const externalBrowserDisabled = (value) => {
    if (!value) return false;
    return value.includes("mailto:") || value.includes("tel:");
  };

  const onClearTaggedUrl = (setFieldValue, index, e) => {
    if (!e){
      setFieldValue(`actions[${index}].isTagged`, false);
      setFieldValue(`actions[${index}].tagType`, 0);
      setFieldValue(`actions[${index}].taggedUrl`, undefined);
    }
    if (!e?.target?.value || e?.target?.value === ""){
      setFieldValue(`actions[${index}].isTagged`, false);
      setFieldValue(`actions[${index}].tagType`, 0);
      setFieldValue(`actions[${index}].taggedUrl`, undefined);
    }
    if (e?.target?.value && (e?.target?.value.includes("mailto:") || e?.target?.value.includes("tel:"))){
      setFieldValue(`actions[${index}].externalBrowser`, false);
    }
  };

  const renderField = (value, index, setFieldValue, values, errors, setFieldTouched) => {
    switch (value.type) {
      case "message":
        return (
          <>
            <FormItem label={translate("richmenu.action.message", "Message")} name={`actions[${index}].text`} required>
              <TextArea name={`actions[${index}].text`} placeholder="..." />
            </FormItem>
          </>
        );
      case "uri":
        return (
          <>
            <FormItem label={translate("richmenu.action.linkUri", "Link")} name={`actions[${index}].linkUri`} required>
              <Input name={`actions[${index}].linkUri`} placeholder="https://... , tel:0000000000, mailto:example@example.com" onChange={(e) => onClearTaggedUrl(setFieldValue, index, e)}/>
            </FormItem>
            <FormItem name={`actions[${index}].externalBrowser`} required>
              <Checkbox disabled={externalBrowserDisabled(values?.actions[index]?.linkUri)} name={`actions[${index}].externalBrowser`}>{translate("imagemap.external.browser", "Open in external browser")}</Checkbox>
              {(values?.actions[index].linkUri?.length > 900 && values?.actions[index]?.externalBrowser) &&
                <Alert style={{ marginTop: 5 }} type="warning" message={
                  <div>
                    {translate("actions.weblink.length.alert", "Link length maybe a too long to be processed by Line!")}
                  </div>
                }
                />}
            </FormItem>
            <FormItem name={`actions[${index}].isTagged`} required>
              <Checkbox onChange={() => {
                setFieldValue(`actions[${index}].tagType`, 0);
                setFieldValue(`actions[${index}].taggedUrl`, undefined);
              }} disabled={!values.actions[index].linkUri || (
                (process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && !account?.ecmid) ||
                (process.env.REACT_APP_WEBSITE_MODE === "rakuline" && !account?.rakurakuToken)) } name={`actions[${index}].isTagged`}>{
                  <div>
                    { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                    <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
            </FormItem>
            <TaggedUrl values={values} index={index} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={`actions[${index}].tagType`} taggedUrl={`actions[${index}].taggedUrl`}/>
          </>
        );
      case "cooperation":
        return (
          <>
            <FormItem label={translate("cooperationform.title", "Cooperation form")} name={`actions[${index}].cooperation`} required>
              <Select name={`actions[${index}].cooperation`} placeholder={translate("system.select", "Select")} style={{ width: "100%" }}>
                {cooperations.filter(c => c.template).map((item, index2) => {
                  return item.link ? <Option key={index2} value={item._id}>{translate(`cooperation.${item.code}`, item.name)}</Option>
                    : <Option disabled key={index2}>{translate("rakuten.noform", "No rakuten form")}</Option>;
                })}
              </Select>
            </FormItem>
          </>
        );
      case "coupon":
        return (
          <>
            <FormItem name={`actions[${index}].couponCode`}>
              <SelectList onChange={(e) => onClearTaggedUrl(setFieldValue, index, e)} name={`actions[${index}].couponCode` } urlField={`actions[${index}].linkUri`} />
            </FormItem>
            <FormItem style = {{ marginTop: "10px" }} name={`actions[${index}].additionalParameter`} label={translate("imagemap.additional.parameter", "Additional Parameter")} >
              <Input name={`actions[${index}].additionalParameter`} placeholder={translate("imagemap.additional.parameter.placeholder", "Additional Parameter")} />
            </FormItem>
            <FormItem style = {{ marginTop: "10px" }} name={`actions[${index}].externalBrowser`} required>
              <Checkbox name={`actions[${index}].externalBrowser`}>{translate("richmenu.external.browser", "Open in external browser")}</Checkbox>
            </FormItem>
            <FormItem name={`actions[${index}].isTagged`} required>
              <Checkbox onChange={() => {
                setFieldValue(`actions[${index}].tagType`, 0);
                setFieldValue(`actions[${index}].taggedUrl`, undefined);
              }} disabled={!values.actions[index].couponCode || (
                (process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && !account?.ecmid) ||
                (process.env.REACT_APP_WEBSITE_MODE === "rakuline" && !account?.rakurakuToken))} name={`actions[${index}].isTagged`}>{
                  <div>
                    { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                    <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                    </Tooltip>
                  </div>
                }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
            </FormItem>
            <TaggedUrl values={values} index={index} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={`actions[${index}].tagType`} taggedUrl={`actions[${index}].taggedUrl`}/>
          </>
        );
      case "share":
        return (
          <>
            <FormItem label={translate("richmenu.action.share", "Share")} name={`actions[${index}].lineBasicId`} required>
              <Input name={`actions[${index}].lineBasicId`} placeholder={`${account?.lineBasicId}`} />
            </FormItem>
          </>
        );
      default:
        return null;
    }
  };

  const onChangeAction = (e, values, setValues, index) => {
    let actions = [...values.actions];

    actions[index] = {
      ...values.actions[index],
      lineBasicId        : e === "share" ? account?.lineBasicId : undefined,
      linkUri            : undefined,
      label              : undefined,
      text               : undefined,
      couponCode         : undefined,
      cooperations       : undefined,
      externalBrowser    : false,
      additionalParameter: undefined,
      type               : e,
      isTagged           : false,
      taggedUrl          : undefined,
      tagType            : 0,
    };
    setValues({ ...values, actions: actions });
  };

  const onRemove = (template) => {
    const payload = submitRef.current.getValues();
    setFormData({
      ...payload,
      actions: templates.reduce((accumulator, iterator, index) => {
        if (iterator === template)
          return accumulator;

        return [...accumulator, payload.actions[index]];
      }, []),
    });
    dispatch({
      type   : "general.imagemapSelect.templates.remove",
      payload: template
    });
  };

  React.useEffect(() => {
    setCollapse(openCollapse);
  }, [openCollapse]);

  React.useEffect(() => {
    let values = submitRef.current.getValues();

    setFormData({
      ...values,
      actions: templates.map((t, index) => ({
        ...INITIALIZE_ACTION_DATA,
        ...(values.actions && values.actions[index] ? values.actions[index] : {})
      })),
    });
  }, [templates]);

  return (
    <RichFormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema(translate)}
        onSubmit={() => {}}>
        {({ values, setValues, setFieldValue, errors, touched, setFieldTouched }) => {
          return (
            <Form layout="vertical">
              <FormItem label={translate("imagemap.title", "Title")} name="altText" required>
                <EmojiTextField setFieldValue={setFieldValue} text={values.altText} name="altText" placeholder={translate("imagemap.title", "Title")} />
              </FormItem>
              <Collapse activeKey={collapse} onChange={onChangeCollapse}>
                {templates.map((template, index) => {
                  return (
                    <Panel key={template} header={(
                      <ActionPanelHeader>
                        {template}
                        {imageMapSelect && imageMapSelect.type === "draw" && <Button type="danger" size="small" onClick={() => onRemove(template, index)}><DeleteOutlined /> {translate("system.delete", "Remove")}</Button>}
                      </ActionPanelHeader>
                    )}>
                      <FormItem label={translate("richmenu.action", "Action type")} name={`actions[${index}].type`} required>
                        <Select name={`actions[${index}].type`} placeholder={translate("system.select", "Select")} style={{ width: "100%" }} onChange={(e) => onChangeAction(e, values, setValues, index)}>
                          {imageMapActions.map((type, index2) => {
                            return <Option key={index2} value={type.code}>{translate(`richmenu.action.${type.code}`, type.name)}</Option>;
                          })}
                        </Select>
                      </FormItem>
                      {values && values.actions && values.actions[index] && renderField(values.actions[index], index, setFieldValue, values, errors, setFieldTouched)}
                    </Panel>
                  );
                })}
              </Collapse>

              {templates.length === 0 && (
                <Alert description={translate("imagemap.action.alert", "DraErrorMessagew on the image to create actions.")} type="warning" />
              )}

              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>

    </RichFormWrapper>
  );
});
export const URL_REGEX = /^(https?:\/\/(?:(?!www)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})|tel:\+?#?\d{10,13}$|mailto:[a-zA-Z0-9]+)/;
// export const URL_REGEX = /^(https?:\/\/(?:(?!www)[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9]+\.[^\s]{2,})|tel?:[0-9][0-9]*|mailto?:[a-zA-Z0-9]+)/;
// export const URL_REGEX = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|tel?:[0-9][0-9]*|mailto?:[a-zA-Z0-9]*|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,},)/;

export const TaggedUrl = ({ values, index, setFieldValue, tagType, taggedUrl, setFieldTouched }) => {
  const countDownRef = React.useRef();
  const [timer, setTimer] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState([]);
  const { rakutentagTypes, taggedUrlDomains } = useSelector(state => state.general);
  const { translate } = useTranslate();
  const myTableRef = React.useRef();

  const onCancel = () => {
    setAction([null]);
  };

  const checkDomain = (url) => {
    try {
      const parsedUrl = new URL(url);
      const domain = parsedUrl.hostname;
      console.log("----------->domain:", domain, taggedUrlDomains.some(d => domain === d));
      return taggedUrlDomains.some(d => domain === d);
    } catch (err) {
      return false;
    }

  };

  const onGetTaggedUrl = async () => {
    let res;
    if ((values.actions[index]?.linkUri && values.actions[index]?.linkUri!== "")){
      if (!checkDomain(values.actions[index]?.linkUri)){
        setOpen(true);
        return;
      }
    }
    if (values.actions[index]?.uri && values.actions[index]?.uri !== ""){
      if (!checkDomain(values.actions[index]?.uri)){
        setOpen(true);
        return;
      }
    }
    countDownRef.current.start();
    try {
      res = await ecmApi.taggedUrl({
        type               : values.actions[index].tagType,
        couponCode         : values.actions[index]?.couponCode,
        url                : values.actions[index]?.linkUri || values.actions[index]?.uri,
        additionalParameter: values.actions[index]?.additionalParameter
      });
      setFieldValue(taggedUrl, res);
      console.log("----->", setFieldTouched);
      if (setFieldTouched){
        setFieldTouched(taggedUrl, true);
      }
    } catch (err) {
      console.log("==--get_tagurl---->", err);
      let error = {};
      if (err.message === "ECM_405") {
        error = {
          code   : "405",
          message: translate("action.form.failed.403", "Can't use this api with you Plan")
        };
      }
      else if (err.message === "ECM_HTTP_ERROR") {
        error = {
          code   : err.status_code,
          message: err.message
        };
      } else if (err.message === "ECM_RMS_LOGINERROR") {
        error = {
          code   : err.status_code,
          message: translate("action.form.failed.rmslogin", "RMS login error")
        };
      } else {
        error = {
          code   : err.message.code || err.code,
          message: err.message.message || err.message
        };
      }
      setAction(["error", error]);
    }
  };
  const ontagTypeChange = async () => {
    setFieldValue(taggedUrl, undefined);
  };

  if (values.actions[index].isTagged === true) {
    return (
      <>
        <FormItem label={
          <div>
            {translate("action.form.tagtype", "Tag type")}
            <Tooltip placement="top" overlayInnerStyle={{ width: 700 }} overlayStyle={{ fontSize: 12, }} title={<div>
              <div>{translate("action.form.tooltip1", "Message type refers to each function of LINE Official Account.")}</div>
              <div>{translate("action.form.tooltip2", "By issuing a URL with a measurement tag for each message type, you can check the effect of attracting customers and sales by function on the report.")}</div>
              <div>{translate("action.form.tooltip3", "Usage:")}</div>
              <div>{translate("action.form.tooltip4", "・Normal message: Select this if the URL is used for LINE message delivery.")}</div>
              <div>{translate("action.form.tooltip5", "・Greeting message: Select this if the URL will be used for the greeting message automatically sent when the user adds the LINE official account as a friend.")}</div>
              <div>{translate("action.form.tooltip6", "・Rich menu: Select this if the URL is used for the rich menu that is fixed at the bottom of the LINE chat screen.")}</div>
              <div>{translate("action.form.tooltip7", "・Other: Select this if the URL is used for other functions such as timeline or chat.")}</div>
            </div>} hover><QuestionCircleOutlined />
            </Tooltip>
          </div>
        } name={tagType} required>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }} >
            <Select name={tagType} onChange={() => ontagTypeChange()} placeholder={translate("system.select", "Select")}>
              {
                rakutentagTypes.map((item, index) => {
                  return (<Option key={index} value={item.code}>{translate(`actionform.tagtype.${item.code}`, item.name)}</Option>);
                })
              }
            </Select>
            <Button disabled={timer > 0 || values.actions[index]?.tagType === undefined} type="primary" onClick={onGetTaggedUrl}>
              {timer === 0 ? translate("actionform.geturl", "Get url"):(<div style={{ color: colors.primary }}>{timer}</div>)}
            </Button>
            <CountDown callback={(e) => setTimer(e)} ref={countDownRef} />
          </div>
        </FormItem>
        <FormItem label={translate("actionform.taggedurl", "Tagged link")} name={taggedUrl} required>
          <Input name={taggedUrl} style={{ cursor: "pointer", background: "#fff", color: "#000" }} disabled={true} placeholder="https://..." />
        </FormItem>
        {
          action && action[0] === "error" &&
          <Modal
            maskClosable={false}
            title={translate("action.form.failed.alert1", "Failed to get Rakuten tagged URL.")}
            visible={action && action[0] === "error"}
            destroyOnClose
            width={650}
            onCancel={onCancel}
            footer={false}>
            <Alert
              description={
                action[1]?.code === "405"
                  ? (<div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>)
                  : (<div>
                    <div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>
                    <div> {translate("action.form.failed.alert2", "From EC Masters Club or EC Masters Tools check")}</div>
                    <div> {translate("action.form.failed.alert3", "・R-Login ID")}</div>
                    <div> {translate("action.form.failed.alert4", "・R-Loginパスワード")}</div>
                    <div> {translate("action.form.failed.alert5", "・Rakuten user ID")}</div>
                    <div> {translate("action.form.failed.alert6", "・Rakute user password")}</div>
                    <div> {translate("action.form.failed.alert7", "are up to date.")}</div>
                    <br/>
                    <div> {translate("action.form.failed.alert8", "Also, make sure that the R-Login user authority is the [Normal] Rakuten member ID.")}</div>
                    <div> {translate("action.form.failed.alert9", "After confirming, please update the EC Masters Club or EC Masters Tools settings.")}</div>
                    <div> {translate("action.form.failed.alert10", "(Please perform update even if there is no change as a result of checking)")}</div>
                    <br/>
                  </div>)
              }
              type="warning"
              showIcon={false}
            />
          </Modal>
        }
        <Modal
          width={800}
          // maskClosable ={false}
          title={
            <div>
              {translate("taggedurl.error.modal.title", "Tagged url get error")}<Tag color="red">{translate("taggedurl.error.modal.tag", "Failed")}</Tag>
            </div>
          }
          visible={open}
          onCancel={() => setOpen(false)}
          destroyOnClose
          footer={false}>
          <Container>
            <div className="description">
              {translate("taggedurl.error.modal.desc", "楽天のドメイン以外のURLで計測タグ付きURLを発行することはできません。以下、計測可能なURL一覧です。こちらご確認のうえ発行をおこなってください。")}
            </div>
            <CheckTable
              ref={myTableRef}
              customPagination={false}
              dataSource ={taggedUrlDomains}
              bordered
              thead={() => (
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
                    <th className="ant-table-cell" rowSpan={2}>{translate("taggedurl.error.table.name", "Domain")}</th>
                    <th className="ant-table-cell" rowSpan={2}>{translate("taggedurl.error.table.description", "Description")}</th>
                  </tr>
                </thead>
              )}
              tbody={(row, index) => (
                <tbody key={2} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell">{index + 1}</td>
                    <td className="ant-table-cell ">{row}</td>
                    <td className="ant-table-cell ">{translate(`${row}.desc`, `${row}.desc`)}</td>
                  </tr>
                </tbody>
              )} />
          </Container>
        </Modal>
      </>
    );
  }
  return <div/>;
};

export const CountDown = React.forwardRef((props, ref) => {
  const [timer, setTimer] = React.useState(0);
  const { callback } = props;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prev => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        if (prev !== 0) {
          return prev - 1;
        }
        return prev;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const startCount = async () => {
    setTimer(10);
  };

  React.useEffect(() => {
    if (timer >= 0) {
      callback(timer);
    }
  }, [timer]);

  React.useImperativeHandle(ref, () => ({
    async start(){
      startCount();
    }
  }));

  return (
    <div></div>
  );
});

const ActionPanelHeader = styled.div`
  display:flex;
  width :100%;
  justify-content: space-between;
`;
const RichFormWrapper = styled.div`
.ant-collapse-header {
  display: flex;
  align-items: center;
  padding: 6px 16px 6px 40px!important; 
    .ant-collapse-arrow {
      top: 11px!important;
    } 
}
  .button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }
`;
const Container = styled.div`
  margin-bottom : 20px;
  .description { 
    margin-bottom: 15px;
  }
  .btn-group {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }
  .ant-pagination { 
    display: none;
  }
`;

const CheckTable = styled(CustomTable)`
  .ant-table-content { 
    table {
      width : auto !important;
    }
  }
  .ant-table-cell {
    padding: 8px!important;
  }
`;