import { user } from "../apis";
import { INIT_RICHMENU } from "../pages/RichMenuAlias/components/Form";

const initialState = {
  user          : {},
  accounts      : [],
  account       : null,
  customer      : null,
  customers     : [],
  richmenuAlias : [{ ...INIT_RICHMENU }, { ...INIT_RICHMENU }],
  richmenuSelect: {
    template: {},
    image   : undefined,
    chatBar : ""
  },
  stickerPackages        : [],
  stickers               : [],
  emojiProducts          : [],
  emojis                 : [],
  tagTypes               : [],
  referenceTypes         : [],
  businessTypes          : [],
  serviceTypes           : [],
  mappedReferenceTypes   : {},
  cooperationTypes       : [],
  rakutenDiscountTypes   : [],
  rakutenMemberLevels    : [],
  rakutenItemTypes       : [],
  rakutenCouponStatuses  : [],
  rakutenCouponConditions: [],
  rakutenTargetTypes     : [],
  rakutenCategories      : [],
  rakutenCategoriesTree  : [],
  messageChildTypes      : [],
  carouselTypes          : [],
  exportStatus           : [],
  exportTypes            : [],
  richMenuTemplates      : {
    large  : {},
    compact: {}
  },
  imageMapTemplates: {
    custom: {},
    large : {}
  },
  imageMapSelectTemplates: ["A", "B", "C", "D"],
  checkRakutenConnective : false,
  imageMapActions        : [],
  imageMapSelectBounds   : [],
  isBalanceNotfication   : false,
  demographics           : {},
  processEvents          : [],
  segmentTypes           : [],
  tags                   : [],
  translate              : {},
  logTypes               : {},
  permissions            : [],
  planPermissions        : [],
  categoryTree           : [],
  lang                   : null,
  loading                : true,
  logged                 : false,
  mobileHost             : null,
  hostName               : null,
  rakutentagTypes        : [],
  regularSets            : [],
  tokenPermissions       : [],
  taggedUrlDomains       : [],
  number                 : {
    min: 0,
    max: 100
  },
  isSubmit: false
};

const general = (state = initialState, action) => {
  switch (action.type) {
    case "general.init": {
      const { rakutentagTypes, tokenPermissions, regularSets, logged, tagTypes, exportStatus, exportTypes, messageChildTypes, cooperationTypes, referenceTypes, businessTypes, serviceTypes, logTypes, richMenuTemplates, richMenuActions, segmentTypes, translate, lang, shopId, gmoUrl, demographics, emojiProducts, emojis, stickerPackages, stickers, processEvents, planPermissions } = action.payload;
      const { rakutenDiscountTypes, categoryTree, taggedUrlDomains, rakutenMemberLevels, rakutenItemTypes, rakutenCouponConditions, imageMapTemplates, imageMapActions, rakutenTargetTypes, rakutenCategories, rakutenCouponStatuses, permissions, carouselTypes, ecm_verifyUrl, ecm_verifyUrl_sp, mobileHost, hostName } = action.payload;

      return {
        ...state,
        tagTypes,
        categoryTree,
        tokenPermissions,
        rakutentagTypes,
        referenceTypes,
        regularSets,
        mappedReferenceTypes: referenceTypes.reduce((accumulator, iterator) => {
          return {
            ...accumulator,
            [iterator.code]: iterator
          };
        }, {}),
        permissions,
        exportStatus, exportTypes,
        imageMapTemplates,
        imageMapActions,
        businessTypes,
        serviceTypes,
        cooperationTypes,
        richMenuTemplates,
        richMenuActions,
        demographics,
        segmentTypes,
        logTypes,
        translate,
        shopId,
        gmoUrl,
        // loading: false,
        logged,
        processEvents,
        lang,
        carouselTypes,
        emojiProducts, emojis,
        stickerPackages, stickers,
        ecm_verifyUrl, ecm_verifyUrl_sp,
        mobileHost,
        hostName,
        planPermissions,
        rakutenDiscountTypes, rakutenMemberLevels, rakutenItemTypes, rakutenCouponConditions, rakutenTargetTypes, rakutenCategories, rakutenCouponStatuses, messageChildTypes,
        taggedUrlDomains     : ["www.rakuten.ne.jp", "www.rakuten.co.jp", ...taggedUrlDomains],
        rakutenCategoriesTree: []
      };
    }
    case "loading": {
      console.log("change.loading : ", action.payload);
      return {
        ...state,
        loading: action.payload
      };
    }
    case "general.tags": {
      const { rows, min, max } = action.payload;
      return {
        ...state,
        tags  : rows,
        number: {
          min, max
        }
      };
    }
    case "general.change.language": {
      return {
        ...state,
        lang: action.payload
      };
    }
    case "general.imagemapSelect": {
      return {
        ...state,
        imageMapSelect: action.payload
      };
    }
    case "general.imagemapSelect.templates": {
      return {
        ...state,
        imageMapSelectTemplates: action.payload
      };
    }
    case "general.imagemapSelect.templates.remove" : {
      return {
        ...state,
        imageMapSelectTemplates: state.imageMapSelectTemplates.filter(template => template !== action.payload)
      };
    }
    case "general.imagemapSelect.bounds": {
      return {
        ...state,
        imageMapSelectBounds: action.payload
      };
    }
    case "general.richmenuSelect": {
      return {
        ...state,
        richmenuSelect: {
          ...state.richmenuSelect,
          template: action.payload
        }
      };
    }
    case "general.richmenuSelect.update": {
      return {
        ...state,
        richmenuSelect: action.payload
      };
    }
    case "general.richmenuSelect.text": {
      return {
        ...state,
        richmenuSelect: {
          ...state.richmenuSelect,
          chatBar: action.payload
        }
      };
    }
    case "general.richmenuAlias": {
      return {
        ...state,
        richmenuAlias: action.payload
      };
    }
    case "general.customer.change": {
      return {
        ...state,
        user: {
          ...state.user
        },
        customer: action.payload
      };
    }
    case "socket.account":
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload
        }
      };
    case "checkRakutenConnective":
      return {
        ...state,
        checkRakutenConnective: action.payload
      };
    case "auth.account":
      return {
        ...state,
        account: action.payload
      };
    case "socket.file.export":
      return {
        ...state,
        account: { ...state.account, fileExportStatus: action.payload.fileExportStatus }
      };
    case "balance.notfication":
      return {
        ...state,
        isBalanceNotfication: action.payload
      };
    case "general.isSubmit": {
      return {
        ...state,
        isSubmit: action.payload
      };
    }
    case "auth.change": {
      const { logged } = action.payload;
      return {
        ...state,
        logged,
        loading: true
      };
    }
    case "auth.me":
      const { logged, account, accounts, customers, customer, rakutenAccessHide, accountSetting, ...rest } = action.payload;
      return {
        ...state,
        user: rest,
        account,
        accountSetting,
        accounts,
        customer,
        customers,
        rakutenAccessHide,
        // loading: false,
        logged,
      };
    case "auth.logout": {
      return {
        ...state,
        logged: false,
        user  : {}
      };
    }
    default:
      return state;
  }
};

export default general;