import React from "react";
import NewTrigger from "./New";
import EditTrigger from "./Edit";
import { trigger as triggerApi, tags as tagsApi } from "../../apis";
import { Space, message, Empty, Button, Radio } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined, ExclamationCircleOutlined, PlusOutlined, SwapRightOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Modal, Input, Tag, Select } from "antd";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import styled from "styled-components";
import colors from "../../colors";
import { useDispatch, useSelector } from "react-redux";
import DateForm from "./components/DateForm";
import TagForm from "./components/TagForm";
import TimeForm from "./components/TimeForm";
import TextForm from "./components/TextForm";
import moment from "moment";
import ManualDesc from "../../components/Manual/ManualDesc";
import { ManualLink } from "../../components/Manual";

export { NewTrigger, EditTrigger };

const { Option } = Select;
const { confirm } = Modal;

export default () => {
  const { translate, lang } = useTranslate();
  const { checkRole } = usePermission();
  const { tags } = useSelector(state => state.general);
  const dispatch = useDispatch();
  const myTableRef = React.useRef();
  const history = useHistory();
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const { processEvents } = useSelector(state => state.general);
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const [isAdmin] = React.useState(checkRole(["ADMIN"]));

  useFetch(tagsApi.select(dispatch))();

  const [filters, setFilters] = React.useState({
    query      : "",
    eventType  : "",
    activeEvent: null,
    hide       : false,
    isRakuraku : false,
  });
  const convertArrayToObject = () => {
    const initialValue = {};
    if (processEvents){
      return processEvents.reduce((obj, item) => {
        return {
          ...obj,
          [item.code]: item,
        };
      }, initialValue);
    }
  };
  const columns = useHeader({
    history,
    translate,
    tags,
    eventTypes : convertArrayToObject(),
    lang,
    isAdmin,
    tableTitles: {
      no        : translate("system.number", "No."),
      name      : translate("trigger.list.name", "Trigger set"),
      eventType : translate("trigger.list.type", "Start event"),
      action    : translate("system.action", "Action"),
      edit      : translate("system.edit", "Edit"),
      remove    : translate("system.delete", "Remove"),
      hide      : translate("trigger.action.hide", "Hide"),
      show      : translate("trigger.action.show", "Show"),
      copy      : translate("trigger.list.copy", "Copy"),
      status    : translate("system.status", "Status"),
      detail    : translate("system.detail", "Detail"),
      activate  : translate("trigger.action.activate", "Activate"),
      deactivate: translate("trigger.action.deactivate", "Deactivate"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "activate": {
          switch (item.eventType) {
            case "TAG":
            case "TIME":
            case "RAKUTEN_PURCHASE":
            case "TEXT":
            case "RAKUTEN_REVIEW": {
              setAction([item.eventType, { ...process?.event, index: 0, _id: process.event, processId: item._id }]);
              break;
            }
            default: {
              await triggerApi.setEvent(item._id, { index: 0, type: item.eventType, active: true });

              myTableRef.current.reload();
              message.success(translate("system.message.success", "Success!"));
            }
          }
          break;
        }
        case "hide": {
          await triggerApi.hide(item._id);
          myTableRef.current.reload();
          message.success(translate("system.message.success", "Success!"));
          break;
        }
        case "deactivate": {
          await triggerApi.stopEvent(item._id);

          myTableRef.current.reload();
          message.success(translate("system.message.success", "Success!"));
          break;
        }
        case "edit": {
          history.push(`/products/trigger/${item._id}/editable`);
          break;
        }
        case "detail": {
          history.push(`/products/trigger/${item._id}`);
          break;
        }
        case "copy": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              let copied = await triggerApi.copy(item._id);
              history.push(`/products/trigger/${copied._id}/editable`);
            },
          });
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await triggerApi.remove(item._id);
              message.success(translate("system.message.success", "Success!"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
    disabled
  });

  const onSubmit = async (data) => {
    switch (action && action[0]) {
      case "TIME": {
        await triggerApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "TIME", date: data.date });
        break;
      }
      case "RAKUTEN_REVIEW":
      case "RAKUTEN_PURCHASE": {
        await triggerApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: action && action[0], days: data.days, time: data.time });
        break;
      }
      case "TAG": {
        await triggerApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "TAG", target: data.target });
        break;
      }
      case "TEXT": {
        await triggerApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "TEXT", text: data.text, isDefault: data.isDefault });
        break;
      }
      default:
    }

    message.success(translate("system.message.success", "Success!"));

    myTableRef.current.reload();

    onCancel();
  };

  const onCancel = () => {
    setAction(null);
  };

  const onChangeEvent = (type) => {
    setFilters({
      ...filters,
      eventType: type
    });
  };
  const onChangeIsHide = (type) => {
    setFilters({
      ...filters,
      hide: type
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.trigger"></ManualLink>} title={translate("trigger.action.title", "Trigger action")} extra={[
        disabled && (<Button key="Create button" type="primary" onClick={() => history.push("/products/trigger/new")}><PlusOutlined />  {translate("trigger.create", "Create new")}</Button>)
      ]} />
      <ManualDesc translateKey ="manual.desc.trigger" />
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
          <Select name="eventType" style={{ width: 250 }} onChange={onChangeEvent} placeholder= {translate("trigger.action.all", "All trigger")} allowClear>
            {processEvents.map((event, index) => <Option value={event.code} key={index}>{event[`${lang?.toLowerCase()}`]}</Option>)}
          </Select>
          <Select defaultValue={filters.hide} name="eventType" style={{ width: 180 }} onChange={onChangeIsHide} placeholder={translate("trigger.action.filter.hide", "Hide")}>
            <Option value={false}>{translate("trigger.action.filter.show", "Show")}</Option>
            <Option value={true} key={"1"}>{translate("trigger.action.filter.hide", "Hide")}</Option>
            <Option value={null}>{translate("system.all", "All")}</Option>
          </Select>
          <Radio.Group defaultValue={filters.isRakuraku} onChange={(e)=>{setFilters({ ...filters, isRakuraku: e.target.value });}}>
            <Radio.Button value={false}>{translate("rakuraku.integ.normal", "Normal")}</Radio.Button>
            <Radio.Button value={true}>{translate("rakuraku.integ.external", "External")}</Radio.Button>
          </Radio.Group>
        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={triggerApi.list}
        />

        {action && action[0] && action[0] !== "confirm" && action[1] && (
          <Modal
            title={translate("trigger.action.activate", "Activate")}
            maskClosable={false}
            footer={null}
            width={700}
            onCancel={onCancel}
            visible={action && action[0] && action[0] !== "confirm"}>
            {(() => {
              switch (action[0]) {
                case "TIME":
                  return <DateForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
                case "RAKUTEN_REVIEW":
                case "RAKUTEN_PURCHASE":
                  return <TimeForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
                case "TEXT":
                  return <TextForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
                default:
                  return <TagForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
              }
            })()}
          </Modal>
        )}

      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate, disabled, tags, eventTypes, lang, isAdmin }) => {
  if (Object.keys(eventTypes).length > 0){
    return [{
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title : tableTitles.name,
      render: (record) => {
        return `${record.name}`;
      },
    }, {
      title : tableTitles.eventType,
      render: (record) => {
        if (record.event)
          return <Tag color="green">{eventTypes[record.eventType][lang.toLowerCase()]}</Tag>;
        return eventTypes[record.eventType][lang.toLowerCase()];
      }
    }, {
      title : tableTitles.status,
      render: (row) => {
        const RenderDate = ({ date, translate }) => {
          if (date) {
            return (
              <div>
                {moment(date).format("YYYY-MM-DD HH:mm")}
                <Tag color="green" style={{ float: "left" }}>{translate("trigger.event.status.active", "Trigger is active")}</Tag>
              </div>
            );
          }

          return <Tag color="red" style={{ float: "left" }}>{translate("trigger.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
        };

        const RenderTime = ({ days, time, translate }) => {
          if (time) {
            return (
              <div>
                {days} {translate("scenario.event.days", "Days")}  {moment(time).format("HH:mm")}
                <Tag color="green" style={{ float: "left" }}>{translate("trigger.event.status.active", "Trigger is active")}</Tag>
              </div>
            );
          }

          return <Tag color="red" style={{ float: "left" }}>{translate("trigger.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
        };

        const RenderTags = ({ tags = [], target = {}, translate })=> {
          let values = (target.tags || []).map((id, index) => {
            let tag = tags.find(tag => tag._id === id);
            switch (tag?.type) {
              case "SELECT": {
                let value = tag.values.find(tag => tag._id === target.tagValues[index].value);
                return (
                  <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {translate(`default.tag.${value.value}`, value.name)} {target.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
                );
              }
              case "NUMBER": {
                return (
                  <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {target.tagValues[index].value[0]} <SwapRightOutlined /> {target.tagValues[index].value[1]} {target.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
                );
              }
              case "CHECKBOX": {
                return (
                  <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {target.tagValues[index].value ? `${translate("system.yes", "Yes")}` : `${translate("system.no", "No")}`}</Tag>
                );
              }
              case "DATE": {
                return (
                  <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {moment(target.tagValues[index].value[0]).format("YYYY-MM-DD")} <SwapRightOutlined /> {moment(target.tagValues[index].value[1]).format("YYYY-MM-DD")} {target.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
                );
              }
              case "TAG": {
                return (
                  <Tag key={index}>{tag.name}</Tag>
                );
              }
              default:
                return null;
            }
          });

          if (values.filter(value => value !== null).length > 0)
            return (
              <div>
                {values} <Tag color="green" style={{ float: "left" }}>{translate("trigger.event.status.active", "Trigger is active")}</Tag>
              </div>
            );

          return <Tag color="red" style={{ float: "left" }}>{translate("trigger.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
        };

        const RenderText = ({ isDefault, text, translate }) => {
          if (isDefault){
            return (
              <div>
                {translate("trigger.event.text.isDefault", "Default")}
                <Tag color="green" style={{ float: "left" }}>{translate("trigger.event.status.active", "Trigger is active")}</Tag>
              </div>
            );
          }
          if (text) {
            return (
              <div>
                {text}
                <Tag color="green" style={{ float: "left" }}>{translate("trigger.event.status.active", "Trigger is active")}</Tag>
              </div>
            );
          }

          return <Tag color="red" style={{ float: "left" }}>{translate("trigger.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
        };

        if (["TIME", "TAG", "TEXT", "RAKUTEN_REVIEW", "RAKUTEN_PURCHASE"].indexOf(row?.event?.type) !== -1) {
          return (
            <div style={{
              display       : "flex",
              justifyContent: "space-between"
            }}>
              {(() => {
                switch (row?.event?.type) {
                  case "TIME": {
                    return RenderDate({ date: row?.event?.date, index: 0, translate });
                  }
                  case "RAKUTEN_REVIEW":
                  case "RAKUTEN_PURCHASE": {
                    return RenderTime({ days: row?.event?.days, time: row?.event?.time, index: 0, translate });
                  }
                  case "TEXT": {
                    return RenderText({ text: row?.event?.text, index: 0, isDefault: row?.event?.isDefault, translate });
                  }
                  default:
                    return RenderTags({ tags, target: row?.event.target, translate });
                }
              })()}
            </div>
          );
        }

        if (["FOLLOW", "RAKUTEN_REGISTER"].indexOf(row?.event?.type) !== -1) {
          return <Tag color="green" style={{ float: "left" }}>{translate("trigger.event.status.active", "Trigger is active")}</Tag>;
        }

        switch (row.processStatus) {
          case "START":
          case "STOPPED":
            return <Tag color="orange">{translate("trigger.action.list.done", "Done")}</Tag>;
          case "NEW":
            return <Tag color="blue">{translate("trigger.action.list.new", "New")}</Tag>;
          default:
            return <div>-</div>;
        }
      }
    }, {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (row) => {
        let actions = {};
        if (row.isRakuraku) {
          actions.detail = tableTitles.detail;
          if (isAdmin) {
            actions.remove = tableTitles.remove;
          }
          return <RowAction
            actions={actions}
            onClick={(key) => onAction(key, row)}
          />;
        }
        if (!row.event && row.hide === false && disabled) {
          actions.activate = tableTitles.activate;
          actions.edit = tableTitles.edit;
          actions.remove = tableTitles.remove;
        } else if (row.event && disabled) {
          actions.deactivate = tableTitles.deactivate;
        }

        if (disabled && (!row.event && row.processStatus !== "START") && (row.processStatus !== "STOPPED")) {
          actions.edit = tableTitles.edit;
          actions.remove = tableTitles.remove;
        }

        if (["FOLLOW", "RAKUTEN_REGISTER"].indexOf(row?.event?.type) === -1 && !row.event && disabled) {
          actions.hide = row.hide === true?tableTitles.show:tableTitles.hide;
        }

        if (disabled)
          actions.copy = tableTitles.copy;

        actions.detail = tableTitles.detail;

        return (
          <RowAction
            actions={actions}
            onClick={(key) => onAction(key, row)}
          />
        );
      },
    }];
  }
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;
