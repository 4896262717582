import React from "react";
import { Empty, message, Space, Input, Select, DatePicker } from "antd";
import colors from "../../colors";
import { coupon as couponApi } from "../../apis";
import { useTranslate } from "../../hooks";
import confirm from "antd/lib/modal/confirm";
import { MyTable } from "../../components";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import { ManualDesc, ManualLink } from "../../components/Manual";
const { Option } = Select;

export default ()=> {
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const [query, setQuery] = React.useState("");
  const [type, setType] = React.useState("couponName");
  const { rakutenDiscountTypes, lang } = useSelector(state => state.general);
  const [discountTypes, setDiscountTypes] = React.useState(

  );
  const [filters, setFilters] = React.useState({
    query: ""
  });

  const tableTitles = {
    no               : translate("system.number", "No."),
    couponName       : translate("coupon.reports.name", "Coupon name"),
    couponCode       : translate("coupon.reports.code", "Coupon ID"),
    amount           : translate("coupon.reports.discount.type", "Discount Plan"),
    startDate        : translate("coupon.reports.startdate", "Start date"),
    endDate          : translate("coupon.reports.enddate", "End date"),
    couponNumber     : translate("coupon.reports.limit", "Coupon number limit"),
    receivedCustomers: translate("coupon.reports.acquisition", "Received customers"),
    useCount         : translate("coupon.reports.user", "Use count"),
    usagePercent     : translate("coupon.reports.usage", "Usage percent"),
    useCountLseg     : translate("coupon.reports.usage.lseg", "Use count (in System)"),
  };



  React.useEffect(()=> {
    if (rakutenDiscountTypes.length > 0){
      setDiscountTypes(rakutenDiscountTypes.reduce((accumulator, iterator) => {
        return {
          ...accumulator,
          [iterator.code]: iterator
        };
      }, {}));
    }
  }, [rakutenDiscountTypes]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query, type: type });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.reports"></ManualLink>} title={translate("rakuten.coupon.report", "Rakuten coupon usage report")} />
      <ManualDesc translateKey ="manual.desc.reports" />
      <MessageFilter>
        <Space>
          <Input.Group compact>
            <Select onChange={(e)=>setType(e)} defaultValue={type}>
              <Option value="couponName">{translate("coupon.name", "Coupon name")}</Option>
              <Option value="couponCode">{translate("coupon.code", "Coupon code")}</Option>
              {/* <Option value="itemUrl">{translate("coupon.name", "Product name")}</Option> */}
              <Option value="startDate">{translate("coupon.startdate", "Start date")}</Option>
            </Select>
            {((()=>{
              if (type === "startDate"){
                return (
                  <DatePicker
                    locale={lang === "JP"?locale:null}
                    style={{ width: 250 }}
                    onChange={(e)=>{setQuery(e);}}
                    picker="month" />
                );
              }
              return (
                <Input
                  name="query"
                  placeholder={translate("system.search.button", "Search...")}
                  onChange={e => setQuery(e.target.value)}
                  style={{ width: 250 }}
                  prefix={<SearchOutlined />}
                />
              );

            }))()}
            {/* <Button onClick={onSearch}>{translate("system.search.button", "Search")}</Button> */}
          </Input.Group>
        </Space>
      </MessageFilter>
      <PageContent>
        <CouponTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          customPagination ={true}
          loadData={couponApi.couponReports}
          bordered
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell" >{tableTitles.no}</th>
                <th className="ant-table-cell" >{tableTitles.couponName}</th>
                <th className="ant-table-cell" >{tableTitles.couponCode}</th>
                <th className="ant-table-cell" >{tableTitles.amount}</th>
                <th className="ant-table-cell" >{tableTitles.startDate} </th>
                <th className="ant-table-cell" >{tableTitles.endDate} </th>
                <th className="ant-table-cell" >{tableTitles.couponNumber} </th>
                <th className="ant-table-cell" >{tableTitles.receivedCustomers} </th>
                <th className="ant-table-cell" >{tableTitles.useCount} </th>
                <th className="ant-table-cell" >{tableTitles.usagePercent} </th>
                <th className="ant-table-cell" >{tableTitles.useCountLseg} </th>
              </tr>
            </thead>
          )} tbody={(record, index) => (
            <tbody key={index} className="ant-table-tbody">
              <tr>
                <td>{record.key}</td>
                <td>{record.couponName}</td>
                <td>{record.couponCode}</td>
                <td>
                  <div style={{ display: "flex" }}>
                    {((()=>{
                      if (record.discountType === 1){
                        return (<div>{record.discountFactor}&nbsp; 円OFF</div>);
                      }
                      if (record.discountType === 2){
                        return (<div>{record.discountFactor}&nbsp; ％OFF</div>);
                      }
                      return "送料無料";

                    }))()}</div>
                </td>
                <td>
                  {`${moment(record?.couponStartDate).format("YYYY-MM-DD")}`}
                </td>
                <td>
                  { `${moment(record?.couponEndDate).format("YYYY-MM-DD")}`}
                </td>
                <td>
                  {`${record.issueCount > 0 ? record.issueCount : `${translate("coupon.report.unlimited", "Unlimited")}`}`}
                </td>
                <td>
                  {`${record.getCount > 0 ? record.getCount: "-"}`}
                </td>
                <td>
                  { `${record.availCount}`}
                </td>
                <td>
                  {`${record.usePercent?`${record.usePercent}%`:"-"}`}
                </td>
                <td>
                  {`${record?.l_segCouponUsage.length > 0?record?.l_segCouponUsage[0]:"-"}`}
                </td>
              </tr>
            </tbody>
          )} />
      </PageContent>
    </PageContainer>
  );
};

const CouponTable = styled(CustomTable)`
  .ant-table-cell {
    padding: 8px!important;
  }
`;


const MessageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;