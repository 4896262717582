import React, { useState } from "react";
import { Form, Input, FormItem, } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, message, Spin } from "antd";
import * as Yup from "yup";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import copy from "copy-to-clipboard";


const FormSchema = (translate) => Yup.object().shape({
  channelId         : Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
  channelSecret     : Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
  channelAccessToken: Yup.string().typeError(translate("system.message.required", "Please fill out this field!")).required(translate("system.message.required", "Please fill out this field!")),
});

export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = useState({
    channelId         : undefined,
    channelAccessToken: undefined,
    channelSecret     : undefined,
    webhookUrl        : "https://notify.line.ultra-cloud.com/hook/line",
    ...(action && action[0] === "update" ? action[1] : {}),
  });
  const onCopy = async () => {
    copy(data.webhookUrl);
    message.success(translate("system.message.success", "Success"));
  };

  return (
    <Spin spinning={false}>
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <FormItem label={translate("notifmessage.delegate.channelid", "Channel ID")} name="channelId" required>
              <Input name="channelId" placeholder={translate("notifmessage.delegate.channelid", "Channel ID")} />
            </FormItem>
            <FormItem label={translate("notifmessage.delegate.access_secret", "Channel secret")} name="channelSecret" required>
              <Input name="channelSecret" placeholder={translate("notifmessage.delegate.access_secret", "Channel secret")} />
            </FormItem>
            <FormItem label={translate("notifmessage.delegate.access_token", "Channel access token")} name="channelAccessToken" required>
              <Input name="channelAccessToken" placeholder={translate("notifmessage.delegate.access_token", "Channel access token")} />
            </FormItem>
            <FormItem label={translate("notifmessage.delegate..webhook", "Webhook URL")} name="webhookUrl">
              <Input name="webhookUrl" placeholder="Line webhook url" suffix={<Button size="small" type="default" onClick={onCopy}>{translate("line.copy", "Copy")}</Button>} disabled />
            </FormItem>
            <AntForm.Item>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.save", "Save")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};