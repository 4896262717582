import React from "react";
import { Form, FormItem, Checkbox, DatePicker, Select, Input } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { formItemLayout } from "../../../utils";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../hooks";
import { Form as AntForm, Tag } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";
import styled from "styled-components";
import * as Yup from "yup";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const TagSchema = (translate) => Yup.object({
  tag  : Yup.string().required(translate("system.message.required", "Required!")),
  value: Yup.mixed().required(translate("system.message.required", "Required!")),
});

const FormSchema = (translate, notRequired) => Yup.object({
  tags: Yup.array().when(()=> {
    if (notRequired === true)
      return Yup.array().of(Yup.string());
    return Yup.array().of(Yup.string().required()).required(translate("tags.form.required", "Please select a tag!"));
  }),
  tagValues: Yup.array().of(TagSchema(translate))
});

export const INIT_SEGMENTS = {
  tags     : undefined,
  tagValues: []
};

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values, resetForm, setValues } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    },
    async clearForm() {
      setValues({
        ...INIT_SEGMENTS,
      });
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const submitRef = React.useRef();
  const { editable, notRequired } = props;
  const { tags } = useSelector(state => state.general);
  const [data, setFormData] = React.useState({
    ...INIT_SEGMENTS,
    ...(editable && (editable[0] === "update" || editable[0] === "reply") ? editable[1] : {}),
    tags: (editable && (editable[0] === "update" && editable[1]&&editable[1].tags.length > 0) ? editable[1].tags : undefined),
  });



  const onChangeTag = ({ tags: ids, values }) => {
    console.log("--oncHangeTag_run-->", ids);

    let filters = {
      ...values,
      tags     : ids,
      tagValues: ids.map(id => {
        let value = values.tagValues.find(v => v.tag === id);
        let tag = tags.find(tag => tag._id === id);
        let val;

        switch (tag.type) {
          case "CHECKBOX":
            val = false;
            break;
          case "TAG":
            val = tag._id;
            break;
          default:
        }

        return {
          tag      : id,
          value    : value !== undefined ? value.value : val,
          exclusion: value !== undefined ? value.exclusion : false
        };
      })
    };

    setFormData(filters);
  };

  const renderTagField = ({ value, index, setFieldValue }) => {
    let tag = tags.find(tag => tag._id === value.tag);

    const tagName = (tag) => {
      switch (tag.auto) {
        case "ages":
          return translate("tag.ages", "age");
        case "subscriptionPeriods":
          return translate("tag.days", "day");
        default:
          return "";
      }
    };

    if (!value.tag) return;

    switch (tag.type) {
      case "SELECT": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`} required>
            <div className="group">
              <Select name={`tagValues[${index}].value`} placeholder={translate("system.select", "Select")} >
                {tag.values.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {(() => {
                      if (!tag.auto)
                        return item.name;
                      if (tag.auto === "ages")
                        return `${item.name} ${tagName(tag)}`;
                      if (tag.auto === "subscriptionPeriods")
                        return `${item.name} ${tagName(item)}`;

                      return translate(`default.tag.${item.value}`, item.name);
                    })()}
                  </Option>
                ))}
              </Select>
            </div>
          </FormItemGroup>
        );
      }
      case "NUMBER": {
        return (
          <FormItemGroup label={`${tag.name}`} name={`tagValues[${index}].value`} required>
            <div className="group">
              <div className="range-number">
                <Input type="number" name={`tagValues[${index}].value[0]`} placeholder="Minimum" />
                <div className="range-to"><SwapRightOutlined /></div>
                <Input type="number" name={`tagValues[${index}].value[1]`} placeholder="Maximum" />
              </div>
            </div>
          </FormItemGroup>
        );
      }
      case "CHECKBOX": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`}>
            <Checkbox name={`tagValues[${index}].value`} defaultChecked={false} />
          </FormItemGroup>
        );
      }
      case "DATE": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`} required>
            <div className="group">
              <RangePicker allowClear={false} name={`tagValues[${index}].value`} onChange={(e)=>setFieldValue(`tagValues[${index}].value`, [moment(new Date(e[0])).startOf("day"), moment(new Date(e[1])).endOf("day")])} />
            </div>
          </FormItemGroup>
        );
      }
      default:
    }
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    },
    async clear() {
      await submitRef.current.clearForm();
      setFormData({
        ...INIT_SEGMENTS,
      });
    }
  }));

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema(translate, notRequired)}
        onSubmit={() => {}}>
        {({ values, setFieldValue }) => {
          return (
            <Form {...formItemLayout} layout="horizontal">

              <AntForm.Item label={translate("system.search.button", "Custom tags")}>
                <FormItem name="tags">
                  <Select
                    name="tags"
                    placeholder={translate("message.tagsearch", "Search tags...")}
                    mode="multiple"
                    onChange={(value) => onChangeTag({ tags: value, values })}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {tags.filter((t) => !t.parent).map((tag, index) => (
                      <Option key={index} value={tag._id}>
                        {tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                {data.deleteds && data.deleteds.length > 0 && (
                  <div style={{ marginTop: 10 }}>
                    <span style={{ marginRight: 6 }}>
                      {translate("tag.deleted", "Deleted tags:")}
                    </span>
                    {data.deleteds.map((deleted) => (
                      <Tag color="red">{deleted.name}</Tag>
                    ))}
                  </div>
                )}
              </AntForm.Item>

              {values.tagValues && values.tagValues.map((value, index) => {
                return renderTagField({ value, index, setFieldValue });
              })}
              <SubmitForm ref={submitRef} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const FormItemGroup = styled(FormItem)`
  position: ralative;
  .range-number {
    display: flex;
    flex-direction: row;
    width: 100%;
    .range-to {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 74px;
    }
  }
  .ant-picker, ant-picker-range {
    width: 100%;
  }
  .group {
    display: flex;
    flex-direction: row;
    .exclusion {
      height: 32px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .has-exclusion {
    position: absolute;
    right: 0;
  }
`;
const Container = styled.div`
  .target {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    margin-right: 0!important;
  }
  .tags {
    display: inline-flex;
    margin-top: 10px;
    span.ant-radio + * {
      width: 100%;
      padding-right: 0!important;
    }
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }
  }
`;