import React from "react";
import Form from "./Form";
import { trigger as triggerApi } from "../../apis";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import arrayToTree from "array-to-tree";
import { useTranslate } from "../../hooks";

export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const [editable, setEditable] = React.useState([]);

  const reload = React.useCallback(
    async (signal) => {
      setEditable([]);
      let res = await triggerApi.get(params.id, { signal });

      let tree = arrayToTree(res.children, {
        parentProperty: "parent",
        customID      : "_id"
      });

      let form = res.children.reduce((accumulator, iterator) => {
        return {
          ...accumulator,
          [iterator._id]: {
            ...iterator,
            message: {
              ...iterator.message,
              segments: iterator?.message?.targets ?iterator.message.targets: [],
              children: iterator?.message?.children ?iterator.message.children.map(child => ({
                ...child,
                template         : child.template && child.template._id,
                templateValues   : child.template,
                cooperation      : child.cooperation && child.cooperation._id,
                cooperationValues: child.template,
                imagemap         : child.imagemap && child.imagemap._id,
                imagemapValues   : child.imagemap,
                carousel         : child.carousel && child.carousel._id,
                carouselValues   : child.carousel
              })) :[],
            }
          }
        };
      }, {
        [params.id]: {
          eventType : res.eventType,
          name      : res.name,
          isRakuraku: res.isRakuraku
        }
      });

      dispatch({
        type   : "process.index",
        payload: res.children.filter(child => child.type === "trigger").reduce((accumulator, iterator) => {
          return {
            ...accumulator,
            [iterator._id]: iterator.index
          };
        }, {})
      });

      dispatch({
        type   : "process.children",
        payload: tree
      });

      dispatch({
        type   : "process.childrenForm",
        payload: form
      });

      dispatch({
        type   : "general.isSubmit",
        payload: false
      });

      dispatch({
        type   : "process.selected",
        payload: {
          type: ""
        }
      });

      dispatch({
        type   : "process.isEditable",
        payload: params.type === "editable"
      });

      dispatch({
        type   : "process.data",
        payload: res
      });

      setTimeout(() => {
        setEditable(["update", res, form]);
      }, 100);
    },
    [params.id]
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  if (!editable[1]) return <div>{translate("system.loading", "Loading...")}</div>;

  return <Form editable={editable} />;
};
