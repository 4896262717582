import React, { memo } from "react";
import styled from "styled-components";
import Jpy from "../../assets/jpyc.png";
import { formatIntNumber, formatNumber } from "../../utils";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  PauseOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  PayCircleOutlined,
  CloseOutlined
} from "@ant-design/icons";
import numeral from "numeral";
import colors from "../../colors";


const renderArrow = (value) => {
  if (value > 0) {
    return <span className="text-blue">
      {formatNumber(value)}％
      <CaretUpOutlined />
    </span>;
  }
  if (value < 0) {
    return <span className="text-red">
      {formatNumber(value)}％
      <CaretDownOutlined />
    </span>;
  }
};

const BoxList = memo(({ data }) => {
  return (
    <div className="box-list">
      <BoxCard>
        <div className="box-green">
          <img width={"100%"} src={Jpy} className="jpy-logo" />
          <h1 className="title">LINE経由の売上</h1>
          <div className="white-small-card">
            <div className="card-title">
              {formatIntNumber(data?.kpi_gms?.value)}{" "}
              <span className="jpy-currency">円</span>
            </div>
            <div className="divider" />
            <div className="table-content">
              <table className="table">
                <tr>
                  <td className="td">
                    <span className="table-title">先月比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_gms?.mom)}
                  </td>
                </tr>
                <tr>
                  <td className="td">
                    <span className="table-title">前年比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_gms?.yoy)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </BoxCard>
      <div className="character">
        <PauseOutlined
          style={{
            fontSize : 45,
            color    : "#5E6777",
            transform: "rotate(90deg)",
          }}
        />
      </div>
      <BoxCard>
        <div className="box-gray right-border">
          <TeamOutlined style={{ fontSize: 55, color: "#5E6777" }} />
          <h1 className="title text-gray">訪問者数</h1>

          <div className="white-small-card">
            <div className="card-title text-gray">
              {data?.kpi_visitors?.value > 0
                ? formatIntNumber(data?.kpi_visitors?.value)
                : "-"}
              <span className="jpy-currency text-gray">人</span>
            </div>
            <div className="divider" />
            <div className="table-content">
              <table className="table">
                <tr>
                  <td className="td">
                    <span className="table-title">先月比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_visitors?.mom)}
                  </td>
                </tr>
                <tr>
                  <td className="td">
                    <span className="table-title">前年比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_visitors?.yoy)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </BoxCard>
      <div
        style={{
          display       : "flex",
          flexDirection : "column",
          justifyContent: "center",
        }}
      >
        <CloseOutlined style={{ fontSize: 35, color: "#5E6777" }} />
      </div>
      <BoxCard>
        <div className="box-gray right-border">
          <ShoppingCartOutlined style={{ fontSize: 55, color: "#5E6777" }} />
          <h1 className="title text-gray">転換率</h1>

          <div className="white-small-card">
            <div className="card-title">
              {data?.kpi_cvr?.value > 0 ? formatNumber(data?.kpi_cvr?.value) : "-"}{" "}
              <span className="jpy-currency">％</span>
            </div>
            <div className="divider" />

            <div className="table-content">
              <table className="table">
                <tr>
                  <td className="td">
                    <span className="table-title">先月比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_cvr?.mom)}
                  </td>
                </tr>
                <tr>
                  <td className="td">
                    <span className="table-title">前年比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_cvr?.yoy)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </BoxCard>
      <div
        style={{
          display       : "flex",
          flexDirection : "column",
          justifyContent: "center",
        }}
      >
        <CloseOutlined style={{ fontSize: 35, color: "#5E6777" }} />
      </div>
      <BoxCard>
        <div className="box-gray">
          <PayCircleOutlined style={{ fontSize: 55, color: "#5E6777" }} />
          <h1 className="title text-gray">客単価</h1>
          <div className="white-small-card">
            <div className="card-title text-gray">
              {data?.kpi_aov?.value > 0 ? formatIntNumber(data?.kpi_aov?.value) : "-"}{" "}
              <span className="jpy-currency text-gray">円</span>
            </div>
            <div className="divider" />
            <div className="table-content">
              <table className="table">
                <tr>
                  <td className="td">
                    <span className="table-title">先月比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_aov?.mom)}
                  </td>
                </tr>
                <tr>
                  <td className="td">
                    <span className="table-title">前年比</span>
                  </td>
                  <td>
                    {renderArrow(data?.kpi_aov?.yoy)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </BoxCard>
    </div>
  );
});

const BoxCard = styled.div`
  min-height: 255px;
  min-width: 255px;
  width: 255px;
  height: 255px;
  @media (max-width: 268px) {
    width: 100%;
    margin-bottom: 20px;
  }
  .table {
    width: 100%;
  }
  .td {
    width: 60px;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    color: #5e6777;
  }
  .text-gray {
    color: #5e6777 !important;
  }
  .text-red {
    color: #a73c38;
    font-weight: 600;
  }
  .text-blue {
    color: #166abb;
    font-weight: 600;
  }
  .box-green {
    height: 100%;
    width: 100%;
    background-color: ${colors.primaryDark};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
  }
  .jpy-currency {
    font-size: 12px;
  }
  .box-gray {
    background: #eeeeee;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
  }
  .jpy-logo {
    height: 55px;
    width: 55px;
  }
  .title {
    font-weight: bold;
    font-size: 16px;
    color: white;
  }
  .white-small-card {
    width: 100%;
    background-color: white;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .divider {
    width: 100%;
    border: solid 1.5px #eeeeee;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .table-title {
    color: #5e6777;
    font-size: 14px;
    font-weight: 600;
  }
  .table-content {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
  }
`;

export { BoxList };
