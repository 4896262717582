/* eslint-disable no-restricted-syntax */
import React from "react";
import { Form, FormItem, Checkbox, DatePicker, Select, Input, TreeSelect } from "formik-antd";
import { ErrorMessage, Formik, useFormikContext } from "formik";
import { formItemLayout, tailFormItemLayout } from "../../../utils";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../hooks";
import { Form as AntForm, Tag, Button } from "antd";
import { SwapRightOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import * as Yup from "yup";
import colors from "../../../colors";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { rakutencategoryApi } from "../../../apis";

const { Option } = Select;
const { RangePicker } = DatePicker;

const PurchaseSchema = (translate) => Yup.object({
  value    : Yup.array().of(Yup.number().required(" ")).required(translate("system.message.required", "Required!")),
  exclusion: Yup.boolean()
});

const TagSchema = (translate) => Yup.object({
  tag  : Yup.string().required(translate("system.message.required", "Required!")),
  type : Yup.string().optional().nullable(),
  value: Yup.mixed()
    .when("type", {
      is  : "NUMBER",
      then: Yup.array().min(2, translate("system.message.required", "Required!")).of(Yup.number().required(" "))
    })
    .required(translate("system.message.required", "Required!")),
});

const FormSchema = (translate) => Yup.object({
  isRakutenFilter: Yup.boolean(),
  isRakuten      : Yup.boolean().nullable().when("isRakutenFilter", {
    is  : true,
    then: Yup.boolean(),
  }),
  totalAmount: Yup.object().when("types", (types) => {
    if (types && types.indexOf("TOTAL_AMOUNT") !== -1)
      return Yup.object({
        value    : Yup.array().of(Yup.number().required(" ")),
        exclusion: Yup.boolean()
      });
  }),
  averageAmount: Yup.object().when("types", (types) => {
    if (types && types.indexOf("AVERAGE_AMOUNT") !== -1)
      return Yup.object({
        value    : Yup.array().of(Yup.number().required(" ")),
        exclusion: Yup.boolean()
      });
  }),
  totalPurchases: Yup.object().when("types", (types) => {
    if (types && types.indexOf("TOTAL_PURCHASES") !== -1)
      return Yup.object({
        value    : Yup.array().of(Yup.number().required(" ")),
        exclusion: Yup.boolean()
      });
  }),
  couponUsages: Yup.object().when("types", (types) => {
    if (types && types.indexOf("COUPON_USAGES") !== -1)
      return Yup.object({
        value    : Yup.array().of(Yup.number().required(" ")),
        exclusion: Yup.boolean()
      });
  }),
  purchases: Yup.array().when("types", (types) => {
    if (types && types.indexOf("PURCHASES") !== -1)
      return Yup.array().of(Yup.string().required()).required(" ");
  }),
  purchaseValues: Yup.array().of(PurchaseSchema(translate)),
  tags          : Yup.array().when("isRakuten", {
    is: (isRakuten)=>{
      if (isRakuten === undefined)
        return true;
      return false;
    },
    then: Yup.array().of(Yup.string().required(" "))
  }),
  tagValues: Yup.array().of(TagSchema(translate))
});

export const INIT_SEGMENTS = {
  types          : undefined,
  isRakuten      : undefined,
  isRakutenFilter: false,
  totalAmount    : {
    value    : [undefined, undefined],
    exclusion: false
  },
  averageAmount: {
    value    : [undefined, undefined],
    exclusion: false
  },
  totalPurchases: {
    value    : [undefined, undefined],
    exclusion: false
  },
  couponUsages: {
    value    : [undefined, undefined],
    exclusion: false
  },
  tags          : undefined,
  tagValues     : [],
  purchases     : undefined,
  purchaseValues: [],
};

const isRakutenFilter = (data) => {
  return {
    ...data,
    isRakutenFilter: !!((data.isRakuten === true || data.isRakuten === false))
  };
};

const removeDuplicate = (array) => {
  return array.reduce((acc, itr) => {
    return [...acc.filter(item => item.categoryId !== itr.categoryId), itr];
  }, []);
};
const categoryTreeTraverse = (data) => {
  const uniqueCategories = removeDuplicate(data);
  for (const category of uniqueCategories) {
    if (Array.isArray(category.children) && category.children.length > 0) {
      category.children = categoryTreeTraverse(category.children);
    }
  }
  return uniqueCategories;
};

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values, setValues } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    },
    async resetForm() {
      setValues({ ...INIT_SEGMENTS });
    }
  }));

  return null;
});

export default ((props, ref) => {
  const { translate } = useTranslate();
  const { editable, onSubmit, onClear } = props;
  const { tags, rakutenTargetTypes, lang } = useSelector(state => state.general);
  const formRef = React.useRef();

  const [data, setFormData] = React.useState({
    ...INIT_SEGMENTS,
    ...(editable && (editable[0] === "update" || editable[0] === "reply") ? isRakutenFilter(editable[1]) : {}),
  });
  const history = useHistory();
  const [categoryData, setCategoryData] = React.useState({
    categoryTree     : [],
    rakutenCategories: []
  });
  const [loading, setLoading] = React.useState(false);

  const fetchCategoryData = async () => {
    setLoading(true);
    try {
      const { categoryTree, rakutenCategories } = await rakutencategoryApi.tree();
      setCategoryData({ categoryTree: categoryTreeTraverse(categoryTree), rakutenCategories: removeDuplicate(rakutenCategories) });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching category data:", error);
      setLoading(false);
    } finally {
      console.log("categorytree", categoryData.categoryTree.map(item => item.key));
    }
  };

  React.useEffect(() => {
    fetchCategoryData();
  }, []);

  // React.useImperativeHandle(ref, () => ({
  //   async validate() {
  //     let isValid = await formRef.current.submitForm();

  //     return isValid;
  //   },

  //   async clear() {
  //     await formRef.current.resetForm();
  //   }
  // }));

  const onChangeTag = ({ tags: ids, values }) => {
    let filters = {
      ...values,
      tags     : ids,
      tagValues: ids.map(id => {
        let value = values.tagValues.find(v => v.tag === id);
        let tag = tags.find(tag => tag._id === id);
        let val;

        switch (tag.type) {
          case "CHECKBOX":
            val = false;
            break;
          case "TAG":
            val = tag._id;
            break;
          default:
        }

        return {
          tag      : id,
          type     : tag.type,
          value    : value !== undefined ? value.value : val,
          exclusion: value !== undefined ? value.exclusion : false
        };
      })
    };

    setFormData(filters);
  };

  const onChangePurchase = ({ purchases, values }) => {
    let filters = {
      ...values,
      purchases     : purchases.length === 0 ? undefined : purchases,
      purchaseValues: purchases.map(id => {
        let value = values.purchaseValues.find(v => v.category === id);
        let category = categoryData.rakutenCategories.find(category => category._id === id);

        return {
          ...value,
          category : id,
          name     : category ? category.name : "Category",
          exclusion: false,
          value    : [undefined, undefined]
        };
      })
    };

    setFormData(filters);
  };

  const onChangeType = ({ types, values }) => {
    let changes = {
      ...values
    };

    types.forEach((type) => {
      switch (type) {
        case "TOTAL_AMOUNT": {
          changes.totalAmount = values.totalAmount;
          break;
        }
        case "AVERAGE_AMOUNT": {
          changes.averageAmount = values.averageAmount;
          break;
        }
        case "TOTAL_PURCHASES": {
          changes.totalPurchases = values.totalPurchases;
          break;
        }
        case "COUPON_USAGES": {
          changes.couponUsages = values.couponUsages;
          break;
        }
        case "PURCHASE": {
          changes.purchases = values.purchase;
          changes.purchaseValues = values.purchaseValues;
          break;
        }
        default:
      }
    });

    let filters = {
      ...values,
      ...changes,
      types: types
    };

    setFormData(filters);
  };

  const formClear = () => {
    console.log("------->", formRef.current);
    if (formRef.current) formRef.current.resetForm();
    onClear();
  };

  const renderTypeField = ({ type, index, values }) => {
    switch (type) {
      case "TOTAL_AMOUNT": {
        return (
          <FormItemGroup key={index} label={translate("target.total.amount", "Total amount")} name="totalAmount.value" required>
            <div className="group">
              <div className="range-number">
                <Input min={0} type="number" name="totalAmount.value[0]" placeholder={translate("target.min.yen", "Minimum")} />
                <div className="range-to"><SwapRightOutlined /></div>
                <Input min={0} type="number" name="totalAmount.value[1]" placeholder={translate("target.max.yen", "Maximum")} />
              </div>
              <div className="exclusion">
                <Checkbox name="totalAmount.exclusion" />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
            <ErrorMessage component="div" name="totalAmount.value" >{msg => <div style={{ color: "red" }}>{translate("system.message.required", "Required!")}</div>}</ErrorMessage>
          </FormItemGroup>
        );
      }
      case "AVERAGE_AMOUNT": {
        return (
          <FormItemGroup key={index} label={translate("target.average.purchase", "Avarage purchase")} name="averageAmount.value" required>
            <div className="group">
              <div className="range-number">
                <Input min={0} type="number" name="averageAmount.value[0]" placeholder={translate("target.min.yen", "Minimum")} />
                <div className="range-to"><SwapRightOutlined /></div>
                <Input min={0} type="number" name="averageAmount.value[1]"placeholder={translate("target.max.yen", "Maximum")} />
              </div>
              <div className="exclusion">
                <Checkbox name="averageAmount.exclusion" />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
            <ErrorMessage component="div" name="averageAmount.value" >{msg => <div style={{ color: "red" }}>{translate("system.message.required", "Required!")}</div>}</ErrorMessage>
          </FormItemGroup>
        );
      }
      case "TOTAL_PURCHASES": {
        return (
          <FormItemGroup key={index} label={translate("target.totalPurchase", "Total purchases")} name="totalPurchases.value" required>
            <div className="group">
              <div className="range-number">
                <Input min={0} type="number" name="totalPurchases.value[0]" placeholder={translate("target.min.times", "Minimum")} />
                <div className="range-to"><SwapRightOutlined /></div>
                <Input min={0} type="number" name="totalPurchases.value[1]" placeholder={translate("target.max.times", "Maximum")} />
              </div>
              <div className="exclusion">
                <Checkbox name="totalPurchases.exclusion" />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
            <ErrorMessage component="div" name="totalPurchases.value" >{msg => <div style={{ color: "red" }}>{translate("system.message.required", "Required!")}</div>}</ErrorMessage>

          </FormItemGroup>
        );
      }
      case "COUPON_USAGES": {
        return (
          <FormItemGroup key={index} label={translate("target.coupon.usage", "Coupon usages")} name="couponUsages.value" required>
            <div className="group">
              <div className="range-number">
                <Input min={0} type="number" name="couponUsages.value[0]" placeholder={translate("target.min.times", "Minimum")} />
                <div className="range-to"><SwapRightOutlined /></div>
                <Input min={0} type="number" name="couponUsages.value[1]" placeholder={translate("target.max.times", "Maximum")}/>
              </div>
              <div className="exclusion">
                <Checkbox name="couponUsages.exclusion" />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
            <ErrorMessage component="div" name="couponUsages.value" >{msg => <div style={{ color: "red" }}>{translate("system.message.required", "Required!")}</div>}</ErrorMessage>

          </FormItemGroup>
        );
      }
      case "PURCHASES": {
        const tagRender = (props) => {
          const { label, value, closable, onClose } = props;
          const category = categoryData.rakutenCategories.find(i => i._id === value);
          const onPreventMouseDown = (event) => {
            event.preventDefault();
            event.stopPropagation();
          };
          return (
            <Tag
              onMouseDown={onPreventMouseDown}
              closable={closable}
              onClose={onClose}
              style={{
                marginRight : 3,
                marginBottom: 3,
                maxWidth    : 300,
                whiteSpace  : "normal",
                wordBreak   : "break-word",
              }}
            >
              {category?.parentNames.length > 0 ? category?.parentNames.join(" > ") + ">" + label : label }
            </Tag>
          );
        };

        return (
          <div key={index}>
            <AntForm.Item style={{ marginTop: 25 }}>
              <div>{translate("target.rakuten.categories", "Rakuten categories")}:</div>
              <FormItemGroup name="purchases">
                <div className="group">
                  <TreeSelect
                    name="purchases"
                    showSearch
                    multiple
                    treeData={categoryData.categoryTree}
                    style={{
                      width: "100%",
                    }}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow : "auto",
                    }}
                    tagRender={tagRender}
                    placeholder={translate("system.search.button", "Search...")}
                    onChange={(purchases) => onChangePurchase({ purchases, values })}
                    filterTreeNode={(input, node) =>
                      node.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  />
                  <Button
                    loading={loading}
                    className="reload-button"
                    onClick={async () => {
                      await fetchCategoryData();
                    }}>
                    <ReloadOutlined/>
                  </Button>
                </div>
                <div style={{ marginTop: 5 }} class='ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-24'>
                  <a onClick={() => {history.push("/rakuten/category");}}>{translate("target.form.desc.goto.category", "Go to the category page to sync category data")}</a>
                </div>
              </FormItemGroup>
            </AntForm.Item>
            {values.purchaseValues && values.purchaseValues.map((purchase, index) => (
              <div style={{ margin: 0 }}>
                <div style={{ wordWrap: "break-word", whiteSpace: "normal", paddingRight: 10 }} class='ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-8'>
                  {categoryData.rakutenCategories.find(r => r._id === purchase.category)?.parentNames.join(">")}
                </div>
                <FormItemGroup key={index} label={categoryData.rakutenCategories.find(r => r._id === purchase.category)?.name} name={`purchaseValues[${index}].value`} required>
                  <div className="group">
                    <div className="range-number">
                      <Input min={0} type="number" name={`purchaseValues[${index}].value[0]`} placeholder={translate("target.min.times", "Minimum")} />
                      <div className="range-to"><SwapRightOutlined /></div>
                      <Input min={0} type="number" name={`purchaseValues[${index}].value[1]`} placeholder={translate("target.max.times", "Maximum")} />
                    </div>
                    <div className="exclusion">
                      <Checkbox name={`purchaseValues[${index}].exclusion`} />
                    </div>
                  </div>
                  <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
                  <ErrorMessage component="div" name={`purchaseValues[${index}].value`} >{msg => <div style={{ color: "red" }}>{translate("system.message.required", "Required!")}</div>}</ErrorMessage>
                </FormItemGroup>
              </div>
            ))}
          </div>
        );
      }
      default:
    }
  };

  const renderTagField = ({ value, values, index, setFieldValue }) => {
    let tag = tags.find(tag => tag._id === value.tag);

    const tagName = (tag) => {
      switch (tag.auto) {
        case "ages":
          return translate("tag.ages", "age");
        case "subscriptionPeriods":
          return translate("tag.days", "day");
        default:
          return "";
      }
    };

    if (!value.tag) return;

    switch (tag.type) {
      case "SELECT": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`} required>
            <div className="group">
              <Select name={`tagValues[${index}].value`} placeholder={translate("system.select", "Select")}>
                {tag.values.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {(() => {
                      if (!tag.auto)
                        return item.name;
                      if (tag.auto === "ages")
                        return `${item.name} ${tagName(tag)}`;
                      if (tag.auto === "subscriptionPeriods")
                        return `${item.name} ${tagName(item)}`;

                      return translate(`default.tag.${item.value}`, item.name);
                    })()}
                  </Option>
                ))}
              </Select>
              <div className="exclusion">
                <Checkbox name={`tagValues[${index}].exclusion`} defaultChecked={false} />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
          </FormItemGroup>
        );
      }
      case "NUMBER": {
        return (
          <FormItemGroup label={`${tag.name}`} name={`tagValues[${index}].value`} required>
            <div className="group">
              <div className="range-number">
                <Input max={values?.tagValues[index]?.value?.length > 1 ? values?.tagValues[index]?.value[1] : undefined} type="number" name={`tagValues[${index}].value[0]`} placeholder="Minimum" />
                <div className="range-to"><SwapRightOutlined /></div>
                <Input type="number" name={`tagValues[${index}].value[1]`} placeholder="Maximum" />
              </div>
              <div className="exclusion">
                <Checkbox name={`tagValues[${index}].exclusion`} defaultChecked={false} />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
          </FormItemGroup>
        );
      }
      case "CHECKBOX": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`}>
            <Checkbox name={`tagValues[${index}].value`} defaultChecked={false} />
          </FormItemGroup>
        );
      }
      case "DATE": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`} required>
            <div className="group">
              <Input type="hidden" name={`tagValues[${index}].value`} />
              <RangePicker allowClear={false} value={values?.tagValues && values?.tagValues && values?.tagValues[index] && values?.tagValues[index]?.value && values?.tagValues[index]?.value?.length > 0 && [moment(values.tagValues[index].value[0]), moment(values.tagValues[index].value[1])]} onChange={value => setFieldValue(`tagValues[${index}].value`, [moment(new Date(value[0])).startOf("day"), moment(new Date(value[1])).endOf("day")])} />
              <div className="exclusion">
                <Checkbox name={`tagValues[${index}].exclusion`} defaultChecked={false} />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
          </FormItemGroup>
        );
      }
      case "TAG": {
        return (
          <FormItemGroup key={index} label={tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name} name={`tagValues[${index}].value`} required>
            <div className="group">
              <Input disabled value={tag.name} name={`tagValues[${index}].value`} />
              <div className="exclusion">
                <Checkbox name={`tagValues[${index}].exclusion`} defaultChecked={false} />
              </div>
            </div>
            <div className="has-exclusion">{translate("target.exclusion", "Exclusion")}</div>
          </FormItemGroup>
        );
      }
      default:
    }
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) => {
          return (
            <Form {...formItemLayout} layout="horizontal">
              <FormItemGroup name="isRakuten" required>
                <div className="group">
                  <Select name="isRakuten" disabled={!values.isRakutenFilter} placeholder={translate("target.rakuten.cooperation", "Rakuten cooperation")}>
                    <Option key="yes" value={true}>{translate("system.yes", "Yes")}</Option>
                    <Option key="no" value={false}>{translate("system.no", "No")}</Option>
                  </Select>
                  <div className="exclusion">
                    <Checkbox name="isRakutenFilter" onChange={(e)=>{
                      if (e.target.checked === false)
                        setFieldValue("isRakuten", undefined);
                      else
                        setFieldValue("isRakuten", false);
                    }} />
                  </div>
                </div>
              </FormItemGroup>
              <AntForm.Item >
                <FormItem name="types">
                  <Select
                    name="types"
                    placeholder={translate("target.rakuten.filter", "Rakuten filters")}
                    mode="multiple"
                    onChange={(value) => onChangeType({ types: value, values })}
                    disabled={!values.isRakuten}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {rakutenTargetTypes?.map((type, index) => (
                      <Option key={index} value={type.code}>
                        {type[lang.toLowerCase()]}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              </AntForm.Item>

              {values.types && values.types.map((type, index) => {
                return renderTypeField({ type, index, values });
              })}

              <AntForm.Item style={{ marginTop: 25 }}>
                <FormItem name="tags">
                  <Select
                    name="tags"
                    placeholder={translate("message.tagsearch", "Search tags...")}
                    mode="multiple"
                    onChange={(value) => onChangeTag({ tags: value, values })}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {tags.filter((t) => !t.parent).map((tag, index) => (
                      <Option key={index} value={tag._id}>
                        {tag.auto ? translate(`default.tag.${tag.auto}`, tag.name) : tag.name}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
                {data.deleteds && data.deleteds.length > 0 && (
                  <div style={{ marginTop: 10 }}>
                    <span style={{ marginRight: 6 }}>
                      {translate("tag.deleted", "Deleted tags:")}
                    </span>
                    {data.deleteds.map((deleted) => (
                      <Tag color="red">{deleted.name}</Tag>
                    ))}
                  </div>
                )}
              </AntForm.Item>

              {values.tagValues && values.tagValues.map((value, index) => {
                return renderTagField({ value, index, values, setFieldValue });
              })}

              <AntForm.Item {...tailFormItemLayout} >
                <div style={{ display: "flex", gap: 10 }} >
                  <Button type="primary" htmlType="submit"><SearchOutlined />{translate("system.search.button", "Search")}</Button>
                  <Button type="default" onClick={() => formClear()} >{translate("system.clear", "Clear")}</Button>
                </div>
              </AntForm.Item>
              <SubmitForm ref={formRef} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const FormItemGroup = styled(FormItem)`
  position: ralative;
  display: flex !important;
  margin-top: 5px;
  margin-bottom: 10px;
  flex-direction: column !important;
  .ant-form-item-label {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
  }
  .range-number {
    display: flex;
    flex-direction: row;
    width: 100%;
    .range-to {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      width: 74px;
    }
  }
  .ant-picker, ant-picker-range {
    width: 100%;
  }
  .group {
    display: flex;
    flex-direction: row;
    justify-content: end;
    .reload-button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    .exclusion {
      height: 32px;
      width: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }
  .has-exclusion {
    position: absolute;
    right: 0;
  }
`;
const Container = styled.div`
  .target {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-wrapper {
    margin-right: 0!important;
  }
  .tags {
    display: inline-flex;
    margin-top: 10px;
    span.ant-radio + * {
      width: 100%;
      padding-right: 0!important;
    }
    .ant-radio {
      height: 16px!important;
      margin-top: 6px;
    }
    .ant-form-item {
      margin-bottom: 0;
      input {
        width: 100%;
      }
    }
  }
`;

