import React from "react";
import { Checkbox, Form, FormItem, Input, Radio } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { Collapse, Row, Col, Space, message, Input as InputAntd } from "antd";
import { useTranslate } from "../../../hooks";
import CooperationPreview from "../../../components/CooperationPreview";
import styled from "styled-components";
import ImageUpload from "../../../components/ImageUpload";
import ColorSchema from "../../../components/ColorSchema";
import colors from "../../../colors";
import { cooperation as cooperationApi } from "../../../apis";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DefualtSuccess from "./DefualtSuccess";
import CouponSuccess from "./CouponSuccess";
import moment from "moment";
import MaskInput from "../../../components/MaskInput";

const { Panel } = Collapse;
const { TextArea } = Input;

const FormSchema = (translate, successType) => Yup.object().shape({
  // rakuten: Yup.object({
  //   serviceSecret: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  //   licenseKey   : Yup.string().required(translate("system.message.required", "Please fill out this field!"))
  // }),
  form: Yup.object({
    logo  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    header: Yup.object({
      textColor      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      backgroundColor: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    input: Yup.object({
      placeholder     : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      phonePlaceholder: Yup.string().required(translate("system.message.required", "Please fill out this field!")).optional().nullable(),
      dummyOrder      : Yup.string().matches(/^[0-9]{6}-[0-9]{8}-[0-9]{7}/, translate("system.message.order.format", "Please insert in correct format!")).optional().nullable(),
      dummyPhoneNumber: Yup.string().max(11, translate("system.message.required", "Can't insert more than 11 characters")).optional().nullable(),
    }),
    description: Yup.object({
      type: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      text: Yup.string().when("type", {
        is  : "text",
        then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
      }),
      html: Yup.string().when("type", {
        is  : "html",
        then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
      })
    }),
    submit: Yup.object({
      text           : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      textColor      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      backgroundColor: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
  }),
  coupon: Yup.object({
    code: Yup.string().when("success.type", {
      is: ()=>{
        if (successType === "coupon")
          return true;
        return false;
      },
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    taggedUrl: Yup.string().when("isTagged", {
      is  : true,
      then: Yup.string().required(translate("system.message.required", "Required!"))
    })
  }),
  success: Yup.object({
    logo: Yup.string().when("success.type", {
      is: ()=>{
        if (successType === "default")
          return true;
        return false;
      },
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    header: Yup.object({
      textColor      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      backgroundColor: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    description: Yup.object({
      type: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
      text: Yup.string().when("type", {
        is  : "text",
        then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
      }),
      html: Yup.string().when("type", {
        is  : "html",
        then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
      })
    })
  })
});

const SubmitForm = React.forwardRef((props, ref) => {
  const { validateForm, submitForm, values } = useFormikContext();

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();

      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { cooperation } = props;
  const { type } = useParams();

  const { translate, changeLanguage, lang } = useTranslate();
  const [collapse, setCollapse] = React.useState(["rakuten", "message", "success"]);
  const [reload, setReload] = React.useState(true);
  const submitRef = React.useRef();
  const [data, setData] = React.useState({
    // rakuten: {
    //   serviceSecret: undefined,
    //   licenseKey   : undefined
    // },
    form: {
      logo  : undefined,
      header: {
        textColor      : "#ffffff",
        backgroundColor: "#35445e"
      },
      description: {
        type: "text",
        text: undefined,
        html: undefined
      },
      input: {
        placeholder     : undefined,
        phonePlaceholder: undefined,
        dummyOrder      : undefined,
        dummyPhoneNumber: undefined,
        isRakuraku      : process.env.REACT_APP_WEBSITE_MODE !== "rakuline"
      },
      submit: {
        text           : undefined,
        textColor      : "#ffffff",
        backgroundColor: "#35445e"
      },
    },
    ...(cooperation ? {
      ...cooperation.content,
      form: {
        ...cooperation.content.form,
        input: {
          ...cooperation.content.form.input,
          isRakuraku: cooperation.content.form.input?.isRakuraku !== false
        }
      }
    } : {}),
    success: {
      logo: undefined,

      header: {
        textColor      : "#ffffff",
        backgroundColor: "#35445e"
      },
      description: {
        type: "text",
        text: undefined,
        html: undefined
      },
      ...(cooperation ? cooperation.content?.success : {}),
      type: (cooperation && cooperation.content?.success?.type?cooperation.content?.success?.type : "default"),
    },
    coupon: {
      code     : undefined,
      isTagged : false,
      tagType  : 0,
      taggedUrl: undefined,
      ...(cooperation ? cooperation.content?.coupon : {}),
    },
  });

  const onChangeCollapse = (collapse) => {
    setCollapse(collapse);
  };

  const onReload = (type) => async () => {

    let values = await submitRef.current.submitForm();

    if (!values) return;

    let link = await cooperationApi.preview({
      type,
      ...values
    });

    return link;
  };

  const format = (val, type) => {
    const value = val.replace(/[^0-9]/g, "");
    const section1 = value.substr(0, 6) || "";
    const section2 = value.substr(6, 8) || "";
    const section3 = value.substr(14) || "";

    let values = section1;
    if (section1 && section1.length === 6 && section2 !== "") values += "-";
    values += section2;
    if (section2 && section3 !== "" && section2.length === 8) values += "-";
    values += section3;
    return values;
  };

  const onChange = (e, setFieldValue) => {
    const values = e.target.value.split("-");

    setFieldValue("form.input.dummyOrder", format(values.join("").substr(0, 100)));

  };


  React.useImperativeHandle(ref, () => ({
    async validate() {
      let values = await submitRef.current.submitForm();

      if (!values) message.error(translate("system.error.required", "Insert necessary fields first!"));

      return values;
    }
  }));

  return (
    <Formik
      enableReinitialize
      initialValues={data}
      validationSchema={() => FormSchema(translate, data.success.type)}
      onSubmit={() => { }}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Form layout="vertical">
            <Container>
              <Collapse bordered={false} ghost={true} activeKey={collapse} onChange={onChangeCollapse}>

                {/* <Panel key="rakuten" header={translate("cooperationform.rakuten", "Rakuten connection")}>
                  <CooperationAlert>
                    {translate("system.alert.servicekey", "Service key and License key is renewed once in an month")}
                  </CooperationAlert>
                  <RakutenSection>

                    <FormItem label={translate("cooperationform.secret", "Service secret")} name="rakuten.serviceSecret" required>
                      <Input name="rakuten.serviceSecret" placeholder={translate("cooperationform.secret", "Service secret")} />
                    </FormItem>
                    <FormItem label={translate("cooperationform.key", "License key")} name="rakuten.licenseKey" required>
                      <Input name="rakuten.licenseKey" placeholder={translate("cooperationform.key", "License key")} />
                    </FormItem>
                  </RakutenSection>
                </Panel> */}
                <Panel key="message" header={translate("cooperationform.input.page", "Input page")}>
                  <Cooperation>
                    <CooperationPreview theme="light" onReload={onReload("rakuten_order")} />
                    <div className="content">
                      <CooperationSection title={translate("cooperationform.header", "Header")}>
                        <Row gutter={15}>
                          <Col >
                            <FormItem label={translate("cooperationform.textcolor", "Text color")} name="input.header.textColor">
                              <ColorSchema name="form.header.textColor" />
                            </FormItem>
                          </Col>
                          <Col>
                            <FormItem label={translate("cooperationform.backgroundcolor", "Background color")} name="input.header.backgroundColor">
                              <ColorSchema name="form.header.backgroundColor" />
                            </FormItem>
                          </Col>
                        </Row>
                      </CooperationSection>
                      <CooperationSection title={translate("cooperationform.picture", "Image")}>
                        <FormItem name="form.logo" required>
                          <ImageUpload name="form.logo" size="small" action="/api/general/upload" />
                        </FormItem>
                      </CooperationSection>
                      <CooperationSection title={translate("cooperationform.description", "Description")}>
                        <WriteMessage>
                          <MessageToolbar>
                            <Space className="left">
                              <Radio.Group name="form.description.type">
                                <Radio.Button value="text">{translate("cooperationform.text", "Text")}</Radio.Button>
                                <Radio.Button value="html">HTML</Radio.Button>
                              </Radio.Group>
                            </Space>
                          </MessageToolbar>
                          <MessageContent>
                            {(() => {
                              switch (values.form.description.type) {
                                case "text":
                                  return (
                                    <FormItem name="form.description.text" required>
                                      <TextArea name="form.description.text" rows={4} />
                                    </FormItem>
                                  );
                                case "html":
                                  return (
                                    <FormItem name="form.description.html" required>
                                      <TextArea name="form.description.html" rows={4} />
                                    </FormItem>
                                  );
                                default:
                              }
                            })()}
                          </MessageContent>
                        </WriteMessage>
                      </CooperationSection>
                      <CooperationSection title={translate("cooperationform.input", "Input")}>
                        <Row gutter={15}>
                          <Col flex={24}>
                            <FormItem name="form.input.placeholder" label={translate("cooperationform.input.placeholder", "Order placeholder")} required>
                              <Input name="form.input.placeholder" />
                            </FormItem>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col flex={24}>
                            <FormItem name="form.input.phonePlaceholder" label={translate("cooperationform.input.phonePlaceholder", "Phone placeholder")} required>
                              <Input name="form.input.phonePlaceholder" />
                            </FormItem>
                          </Col>
                        </Row>
                      </CooperationSection>
                      <CooperationSection title={translate("cooperationform.button", "Button")}>
                        <Row gutter={15}>
                          <Col>
                            <FormItem name="form.submit.text" label={translate("cooperationform.text", "Button text")} required>
                              <Input name="form.submit.text" />
                            </FormItem>
                          </Col>
                          <Col>
                            <FormItem label={translate("cooperationform.textcolor", "Text color")} name="form.submit.textColor">
                              <ColorSchema name="form.submit.textColor" />
                            </FormItem>
                          </Col>
                          <Col>
                            <FormItem label={translate("cooperationform.backgroundcolor", "Background color")} name="form.submit.backgroundColor">
                              <ColorSchema name="form.submit.backgroundColor" />
                            </FormItem>
                          </Col>
                        </Row>
                      </CooperationSection>
                      <CooperationSection title={translate("cooperationform.input.dummy", "Dummy order")}>
                        <Row gutter={15}>
                          <Col flex={24}>
                            <FormItem name="form.input.dummyOrder" label={<div style={{ color: "gray" }}>{translate("cooperationform.input.dummy.label", "You can insert dummy order number for testing purposes here")}</div>}>
                              {/* <MaskInput mask="000000-00000000-0000000" name="form.input.dummyOrder" placeholder="000000-00000000-0000000" /> */}
                              <Input name="form.input.dummyOrder" placeholder="000000-00000000-0000000" onChange={(e)=>onChange(e, setFieldValue)} />
                            </FormItem>
                          </Col>
                        </Row>
                      </CooperationSection>
                      <CooperationSection title={translate("cooperationform.input.dummy.phonenumber", "Dummy phone")}>
                        <Row gutter={15}>
                          <Col flex={24}>
                            <FormItem name="form.input.dummyPhoneNumber" label={<div style={{ color: "gray" }}>{translate("cooperationform.input.dummy.phone.label", "You can insert dummy phone number for testing purposes here")}</div>}>
                              <Input maxLength={11} name="form.input.dummyPhoneNumber" placeholder="09012345678"/>
                            </FormItem>
                          </Col>
                        </Row>
                        <Row gutter={15}>
                          <Col flex={24}>
                            <FormItem name="form.input.isDummyPhone">
                              <Checkbox name="form.input.isDummyPhone">
                                <div style={{ color: "gray" }}>{translate("cooperationform.input.dummy.isPhone.label", "Check to activate phone number registration in cooperation form")}</div>
                              </Checkbox>
                            </FormItem>
                          </Col>
                        </Row>
                        {process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <Row gutter={15}>
                          <Col flex={24}>
                            <FormItem name="form.input.isRakuraku">
                              <Checkbox name="form.input.isRakuraku">
                                <div style={{ color: "gray" }}>{translate("cooperationform.input.dummy.rakuraku.label", "Would you like to show share info to third party on Rakuten order page ?")}</div>
                              </Checkbox>
                            </FormItem>
                          </Col>
                        </Row>}
                      </CooperationSection>
                    </div>
                  </Cooperation>
                </Panel>
                <Panel key="success" header={translate("cooperationform.success.page", "Success page")}>
                  <div style={{ padding: "0px 16px" }}>
                    <FormItem label={translate("cooperationform.layouttype", "Success page select type .")} name="success.type">
                      <Radio.Group name="success.type" buttonStyle="solid" onChange={(e)=>{
                        setFieldValue("success.type", e.target.value);
                        setData({ ...values, coupon: {
                          code     : undefined,
                          isTagged : false,
                          tagType  : 0,
                          taggedUrl: undefined },
                        success: { ...values.success, type: e.target.value } });
                      }}>
                        <Radio.Button value="default">{translate("cooperation.success.default", "Default")}</Radio.Button>
                        <Radio.Button value="coupon">{translate("cooperation.success.coupon", "Coupon")}</Radio.Button>
                      </Radio.Group>
                    </FormItem>
                  </div>
                  {((()=>{
                    if (values.success.type === "default") {
                      return (<DefualtSuccess setFieldValue={setFieldValue} onReload={onReload} values={values} />);
                    }
                    return (
                      <>
                        <CouponSuccess setFieldValue={setFieldValue} onReload={onReload} values={values} />
                      </>);
                  }))()}
                </Panel>
              </Collapse>
            </Container>

            <SubmitForm ref={submitRef} />
          </Form>
        );
      }}
    </Formik>
  );
});

const CooperationAlert =styled.div`
  padding-left : 15px;
  padding-bottom : 10px;
  color : #7C708F;
`;
const RakutenSection = (props) => {
  return (
    <RakutenContent>
      {props.children}
    </RakutenContent>
  );
};
export const CooperationSection = (props) => {
  return (
    <CooperationContent>
      <div className="label">{props.title}</div>
      <div className="content">{props.children}</div>
    </CooperationContent>
  );
};
const RakutenContent = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
  background: #fcfcfc;
  margin: 0 16px;
`;
export const Cooperation = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  .content {
    padding-left: 40px;
  }
`;
const CooperationContent = styled.div`
  display: flex;
  flex-direction: row;
  .label {
    font-size: 15px;
    color: #333;
    width: 120px;
    font-weight: 500;
  }
  .content {
    padding: 0;
  }
`;
const Container = styled.div`
 .ant-collapse-header {
    border-bottom: 1px solid #d9d9d9;
    font-size: 16px;
    padding-left: 20px!important;
    .anticon {
      left: 0!important;
    }
  }
  .ant-collapse-content-box {
    padding: 20px 0!important;
  }
`;
export const WriteMessage = styled.div`
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 20px;
  .ant-radio-button-wrapper {
    &:hover {
      color: ${colors.primary};
    }
  }
  .ant-radio-button-wrapper-checked {
    &:hover {
      color: ${colors.primary}!important;
    }
    color: ${colors.primaryDark};
    border-color: ${colors.primaryDark}!important;
    &:before {
      background-color: ${colors.primaryDark}!important;
    }
  }
`;
export const MessageToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);
`;

export const MessageContent = styled.div`
  padding: 15px;
  .max-length {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
    color: #adb5bd;
    .length {
      font-weight: 500;
    }
  }
`;
