import React from "react";
import { Form, Input, FormItem, Checkbox, Select, } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { Collapse, Tooltip, Button, Modal, Alert, Tag } from "antd";
import * as Yup from "yup";
import { useTranslate } from "../../../../hooks";
import styled from "styled-components";
import { URL_REGEX } from "../../image/components/Form";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { ecm as ecmApi } from "../../../../apis";
import colors from "../../../../colors";
import CustomTable from "../../../../components/CustomTable";
import EmojiTextField from "../../../../components/Message/components/EmojiTextField";

const externalLinkFormSchema = (translate) => Yup.object({
  linkUri: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).matches(URL_REGEX, translate("webhook.url.invalid", "Format of the URL is wrong!")).when("type", {
    is  : "uri",
    then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
  }),
  label    : Yup.string().required(translate("system.message.required", "Required!")),
  isTagged : Yup.boolean().nullable(),
  taggedUrl: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).when("isTagged", {
    is  : true,
    then: Yup.string().max(1000, translate("system.message.max1000", "Max 1000 character!")).required(translate("system.message.required", "Required!"))
  }).nullable()
});

const FormSchema = (translate) => Yup.object({
  altText     : Yup.string().required(translate("system.message.required", "Required!")).max(300, translate("system.message.imagemap.thirty", "Title must be under 30 characters !")),
  externalLink: externalLinkFormSchema(translate).required()
});

const { Panel } = Collapse;
const { Option } = Select;

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0)
        return false;

      return values;
    }
  }));

  return null;
});


export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const { editable } = props;
  const submitRef = React.useRef();
  const { account } = useSelector(state => state.general);

  const [data] = React.useState({
    altText     : undefined,
    externalLink: {
      linkUri        : undefined,
      label          : undefined,
      externalBrowser: false,
      tagType        : 0,
      taggedUrl      : undefined,
      isTagged       : false,
    },
    ...(editable && editable[0] === "update" ? editable[1] : {}),
    ...(editable && editable[0] === "update" ? editable[1].video : {}),
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {

      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  return (
    <RichFormWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema(translate)}
        onSubmit={() => {}}>
        {({ values, setValues, setFieldValue, setFieldTouched }) => {
          return <Form layout="vertical">
            <FormItem label={translate("imagemap.title", "Title")} name="altText" required>
              <EmojiTextField setFieldValue={setFieldValue} text={values.altText} name="altText" placeholder={translate("imagemap.title", "Title")} />
            </FormItem>

            <Collapse activeKey="external">
              <Panel key={"external"} header={translate("imagemap.external.label", "Label")}>

                <FormItem label={translate("imagemap.button.label", "Button label")} name="externalLink.label" required>
                  <Input name="externalLink.label" placeholder="..." />
                </FormItem>

                <FormItem label={translate("imagemap.linkuri", "Link")} name="externalLink.linkUri" required>
                  <Input name="externalLink.linkUri" placeholder="https://... , tel:0000000000, mailto:example@example.com" />
                </FormItem>

                <FormItem name={"externalLink.externalBrowser"} required>
                  <Checkbox name={"externalLink.externalBrowser"}>{translate("imagemap.external.browser", "Open in external browser")}</Checkbox>
                  {(values?.externalLink.linkUri?.length > 900 && values?.externalLink?.externalBrowser) &&
                   <Alert style={{ marginTop: 5 }} type="warning" message={
                     <div>
                       {translate("actions.weblink.length.alert", "Link length maybe a too long to be processed by Line!")}
                     </div>
                   }
                   />}
                </FormItem>
                <FormItem name={"externalLink.isTagged"} required>
                  <Checkbox onChange={() => {
                    setFieldValue("externalLink.tagType", 0);
                    setFieldValue("externalLink.taggedUrl", undefined);
                  }} disabled={!values.externalLink.linkUri || (
                    (process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && !account?.ecmid) ||
                    (process.env.REACT_APP_WEBSITE_MODE === "rakuline" && !account?.rakurakuToken))} name={"externalLink.isTagged"}>{
                      <div>
                        { translate("actionform.istagged", "Convert to Rakuten tagged URL")}
                        <Tooltip placement="top" overlayInnerStyle={{ width: 500 }} overlayStyle={{ fontSize: 12, }} title={translate("action.form.tooltip8", "Please enter the URL used for the LINE official account. By using URLs with measurement tags, the number of visits and sales via URLs will be disclosed on a daily and monthly basis.")} hover><QuestionCircleOutlined />
                        </Tooltip>
                      </div>
                    }{<a href="/rakuten/connective" target={"_blank"}>{translate("actionform.ecm.required", "「ECM Connection required」")}</a>}</Checkbox>
                </FormItem>
                <TaggedUrl values={values} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} tagType={"externalLink.tagType"} taggedUrl={"externalLink.taggedUrl"}/>
                <SubmitForm ref={submitRef} />
              </Panel>
            </Collapse>
          </Form>;
        }}

      </Formik>
    </RichFormWrapper>
  );
});

const TaggedUrl = ({ values, index, setFieldValue, tagType, taggedUrl, setFieldTouched }) => {
  const countDownRef = React.useRef();
  const [timer, setTimer] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState([]);
  const { rakutentagTypes, taggedUrlDomains } = useSelector(state => state.general);
  const myTableRef = React.useRef();
  const { translate } = useTranslate();
  const onCancel = () => {
    setAction([null]);
  };

  const checkDomain = (url) => {
    try {
      const parsedUrl = new URL(url);
      const domain = parsedUrl.hostname;
      console.log("----------->domain:", domain, taggedUrlDomains.some(d => domain === d));
      return taggedUrlDomains.some(d => domain === d);
    } catch (err) {
      return false;
    }
  };

  const onGetTaggedUrl = async () => {
    let res;
    if ((values.externalLink?.linkUri && values.externalLink?.linkUri!== "")){
      if (!checkDomain(values.externalLink?.linkUri)){
        setOpen(true);
        return;
      }
    }
    countDownRef.current.start();
    try {
      res = await ecmApi.taggedUrl({
        type      : values.externalLink.tagType,
        couponCode: values.externalLink?.couponCode,
        url       : values.externalLink?.linkUri,
      });
      setFieldValue(taggedUrl, res);
      setFieldTouched(taggedUrl, true);
    } catch (err) {
      console.log("==--get_tagurl---->", err);
      let error = {};
      if (err.message === "ECM_405") {
        error = {
          code   : "405",
          message: translate("action.form.failed.403", "Can't use this api with you Plan")
        };
      }
      else if (err.message === "ECM_HTTP_ERROR") {
        error = {
          code   : err.status_code,
          message: err.message
        };
      } else if (err.message === "ECM_RMS_LOGINERROR") {
        error = {
          code   : err.status_code,
          message: translate("action.form.failed.rmslogin", "RMS login error")
        };
      } else {
        error = {
          code   : err.message.code || err.code,
          message: err.message.message || err.message
        };
      }
      setAction(["error", error]);
    }
  };
  const ontagTypeChange = async () => {
    setFieldValue(taggedUrl, undefined);
  };

  if (values.externalLink.isTagged === true) {
    return (
      <>
        <FormItem label={
          <div>
            {translate("action.form.tagtype", "Tag type")}
            <Tooltip placement="top" overlayInnerStyle={{ width: 700 }} overlayStyle={{ fontSize: 12, }} title={<div>
              <div>{translate("action.form.tooltip1", "Message type refers to each function of LINE Official Account.")}</div>
              <div>{translate("action.form.tooltip2", "By issuing a URL with a measurement tag for each message type, you can check the effect of attracting customers and sales by function on the report.")}</div>
              <div>{translate("action.form.tooltip3", "Usage:")}</div>
              <div>{translate("action.form.tooltip4", "・Normal message: Select this if the URL is used for LINE message delivery.")}</div>
              <div>{translate("action.form.tooltip5", "・Greeting message: Select this if the URL will be used for the greeting message automatically sent when the user adds the LINE official account as a friend.")}</div>
              <div>{translate("action.form.tooltip6", "・Rich menu: Select this if the URL is used for the rich menu that is fixed at the bottom of the LINE chat screen.")}</div>
              <div>{translate("action.form.tooltip7", "・Other: Select this if the URL is used for other functions such as timeline or chat.")}</div>
            </div>} hover><QuestionCircleOutlined />
            </Tooltip>
          </div>
        } name={tagType} required>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "5px" }} >
            <Select name={tagType} onChange={() => ontagTypeChange()} placeholder={translate("system.select", "Select")}>
              {
                rakutentagTypes.map((item, index) => {
                  return (<Option key={index} value={item.code}>{translate(`actionform.tagtype.${item.code}`, item.name)}</Option>);
                })
              }
            </Select>
            <Button disabled={timer > 0 || values.externalLink?.tagType === undefined} type="primary" onClick={onGetTaggedUrl}>
              {timer === 0 ? translate("actionform.geturl", "Get url"):(<div style={{ color: colors.primary }}>{timer}</div>)}
            </Button>
            <CountDown callback={(e) => setTimer(e)} ref={countDownRef} />
          </div>
        </FormItem>
        <FormItem label={translate("actionform.taggedurl", "Tagged link")} name={taggedUrl} required>
          <Input name={taggedUrl} style={{ cursor: "pointer", background: "#fff", color: "#000" }} disabled={true} placeholder="https://..." />
        </FormItem>
        {
          action && action[0] === "error" &&
          <Modal
            maskClosable={false}
            title={translate("action.form.failed.alert1", "Failed to get Rakuten tagged URL.")}
            visible={action && action[0] === "error"}
            destroyOnClose
            width={650}
            onCancel={onCancel}
            footer={false}>
            <Alert
              description={
                action[1]?.code === "405"
                  ? (<div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>)
                  : (<div>
                    <div>{"Code : " }{action[1]?.code}, {"Message :"}{action[1]?.message}</div>
                    <div> {translate("action.form.failed.alert2", "From EC Masters Club or EC Masters Tools check")}</div>
                    <div> {translate("action.form.failed.alert3", "・R-Login ID")}</div>
                    <div> {translate("action.form.failed.alert4", "・R-Loginパスワード")}</div>
                    <div> {translate("action.form.failed.alert5", "・Rakuten user ID")}</div>
                    <div> {translate("action.form.failed.alert6", "・Rakute user password")}</div>
                    <div> {translate("action.form.failed.alert7", "are up to date.")}</div>
                    <br/>
                    <div> {translate("action.form.failed.alert8", "Also, make sure that the R-Login user authority is the [Normal] Rakuten member ID.")}</div>
                    <div> {translate("action.form.failed.alert9", "After confirming, please update the EC Masters Club or EC Masters Tools settings.")}</div>
                    <div> {translate("action.form.failed.alert10", "(Please perform update even if there is no change as a result of checking)")}</div>
                    <br/>
                  </div>)
              }
              type="warning"
              showIcon={false}
            />
          </Modal>
        }
        <Modal
          width={800}
          title={
            <div>
              {translate("taggedurl.error.modal.title", "Tagged url get error")}<Tag color="red">{translate("taggedurl.error.modal.tag", "Failed")}</Tag>
            </div>
          }
          visible={open}
          onCancel={() => setOpen(false)}
          destroyOnClose
          footer={false}>
          <Container>
            <div className="description">
              {translate("taggedurl.error.modal.desc", "楽天のドメイン以外のURLで計測タグ付きURLを発行することはできません。以下、計測可能なURL一覧です。こちらご確認のうえ発行をおこなってください。")}
            </div>
            <CheckTable
              ref={myTableRef}
              customPagination={false}
              dataSource ={taggedUrlDomains}
              bordered
              thead={() => (
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell" rowSpan={2}>{translate("system.number", "No")}</th>
                    <th className="ant-table-cell" rowSpan={2}>{translate("taggedurl.error.table.name", "Domain")}</th>
                    <th className="ant-table-cell" rowSpan={2}>{translate("taggedurl.error.table.description", "Description")}</th>
                  </tr>
                </thead>
              )}
              tbody={(row, index) => (
                <tbody key={2} className="ant-table-tbody">
                  <tr>
                    <td className="ant-table-cell">{index + 1}</td>
                    <td className="ant-table-cell ">{row}</td>
                    <td className="ant-table-cell ">{translate(`${row}.desc`, `${row}.desc`)}</td>
                  </tr>
                </tbody>
              )} />
          </Container>
        </Modal>
      </>
    );
  }
  return <div/>;
};

const CountDown = React.forwardRef((props, ref) => {
  const [timer, setTimer] = React.useState(0);
  const { callback } = props;

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prev => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }
        if (prev !== 0) {
          return prev - 1;
        }
        return prev;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  const startCount = async () => {
    setTimer(10);
  };

  React.useEffect(() => {
    if (timer >= 0) {
      callback(timer);
    }
  }, [timer]);

  React.useImperativeHandle(ref, () => ({
    async start(){
      startCount();
    }
  }));

  return (
    <div></div>
  );
});


const RichFormWrapper = styled.div`
  .ant-collapse-header {
    padding: 6px 16px 6px 40px!important; 
    .ant-collapse-arrow {
      top: 11px!important;
    }
  }
`;
const Container = styled.div`
  margin-bottom : 20px;
  .description { 
    margin-bottom: 15px;
  }
  .btn-group {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }
  .ant-pagination { 
    display: none;
  }
`;

const CheckTable = styled(CustomTable)`
  .ant-table-content { 
    table {
      width : auto !important;
    }
  }
  .ant-table-cell {
    padding: 8px!important;
  }
`;