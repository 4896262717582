import React, { useMemo } from "react";
import { Menu, Badge, message, Tooltip } from "antd";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { NodeIndexOutlined, PoweroffOutlined, UserOutlined, CodeSandboxOutlined, FileOutlined, AppstoreAddOutlined, ClusterOutlined, DashboardOutlined, TranslationOutlined, FileSearchOutlined, FormOutlined, MenuOutlined, MessageOutlined, OrderedListOutlined, PicLeftOutlined, ProfileOutlined, SwapOutlined, TagsOutlined, UserAddOutlined, UsergroupAddOutlined, SendOutlined, SettingOutlined, FileTextOutlined, UserSwitchOutlined, FileDoneOutlined, FolderOpenOutlined, ApiOutlined, DatabaseOutlined, FileImageOutlined, VideoCameraOutlined, GiftOutlined, CopyOutlined, NodeExpandOutlined, ForkOutlined, MailOutlined, SwitcherOutlined, BlockOutlined, DownloadOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import LogoImage from "../../assets/logo_green.png";
import RakuLineLogoImage from "../../assets/logo_rakuline_green.png";
import colors from "../../colors";
import layouts from "../../layouts";
import styled from "styled-components";
import { customer as customerApi, auth, settingApi } from "../../apis";
import { usePermission, useTranslate } from "../../hooks";
import Notfication from "./components/Notfication";
import usePlan from "../../hooks/usePlan";

const { SubMenu } = Menu;

export default () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { customer, account, user, accountSetting } = useSelector(state => state.general);
  const { notifications } = useSelector(state => state.socket);
  const [height, setHeight] = React.useState(0);
  const [lsegConsumptions, setLsegConsumptions] = React.useState({
    consumption: 0,
    quota      : 0
  });
  const [alert, setAlert] = React.useState([]);
  const [systemSetting, setSystemSetting] = React.useState();
  const { checkPermission } = usePlan();
  let [selecteds, setSelecteds] = React.useState([...history.location.pathname.split("/")]);
  let [openkeys, setOpenKeys] = React.useState([history.location.pathname.split("/")[1]]);
  const [apiTokenPemission, setApitokenPermission] = React.useState(checkPermission("API"));
  const [rakurakuPermission, setRakurakuPermission] = React.useState(checkPermission("RAKURAKU"));
  const [rmsreportPermission, setrmsreportPermission] = React.useState(checkPermission("RMS_REPORT"));
  const { checkRole } = usePermission();
  const params = useParams();
  const isRakuLine = process.env.REACT_APP_WEBSITE_MODE === "rakuline";

  const onAccountLogout = async () => {
    history.push("/staff");
    dispatch({ type: "main.load", payload: true });
    await customerApi.logout();
    await auth.me()(dispatch);
    notfy();
    message.success(translate("system.message.success", "Success"));
    dispatch({ type: "main.load", payload: false });
    dispatch({ type: "socket.on.conversation", payload: {} });
  };

  const handleResize = (e) => {
    setHeight(window.innerHeight);
  };

  const notfy = () => {
    if (user.balance) {
      let alertObjects = [];
      let alerts = user.balance.alert;
      if (alert) {
        Object.keys(alerts).forEach(code => {
          if (user.balance.alert[code] !== null && user.balance.alert[code] !== false) {
            alertObjects.push(code);
          }
        });
      }
      setAlert(alertObjects);
    }
  };

  React.useEffect(() => {
    setHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    notfy();
    setApitokenPermission(checkPermission("API"));
    setRakurakuPermission(checkPermission("RAKURAKU"));
    setrmsreportPermission(checkPermission("RMS_REPORT"));
  }, [user]);

  const getSystemSetting = async () => {
    try {
      const result = await settingApi.get("START_NOTIF_MESSAGE");
      setSystemSetting(result);
    } catch (error) {
      message.error("Error fetching account settings.");
    }
  };

  React.useEffect(() => {
    getSystemSetting();
  }, []);

  React.useEffect(() => {
    setLsegConsumptions({
      consumption: customer?.plan?.messageLimit - user?.balance?.message || 0,
      quota      : customer?.plan?.messageLimit || 0
    });
  }, [customer]);

  React.useEffect(() => {
    let selecteds = [...history.location.pathname.split("/")].filter((selected) => selected !== "");
    if (selecteds.length <= 0) selecteds = ["dashboard"];
    setSelecteds(selecteds);
    setOpenKeys([history.location.pathname.split("/")[1]]);
  }, [params]);

  if (Object.keys(user).length === 0) {
    return (<></>);
  }

  const badgeColor = (account) => {
    let nowCount = 0;
    if (account && account?.lineConsumption) nowCount = account?.lineConsumption;

    if (account && account?.lineQuota > (nowCount + (account?.targetReach * 2))){
      return "green";
    }
    if (account && account?.lineQuota > (nowCount + account?.targetReach)){
      return "orange";
    }
    if (account && account?.lineQuota < (nowCount + account?.targetReach)){
      return "red";
    }
    return "red";
  };

  const lsegBadgeColor = () => {
    if (account && lsegConsumptions.quota > (lsegConsumptions.consumption + (account?.targetReach * 2))){
      return "green";
    }

    if (account && lsegConsumptions.quota > (lsegConsumptions.consumption + account?.targetReach)){
      return "orange";
    }
    if (account && lsegConsumptions.quota < (lsegConsumptions.consumption + account?.targetReach)){
      return "red";
    }

    return "red";
  };


  return (
    <NavBar>
      <div>
        <div className="logo">
          <div className="logo-container">
            <img src={isRakuLine ? RakuLineLogoImage : LogoImage}/>
            {account &&
            <div>
              <div style={{ fontSize: "10px", textAlign: "right", fontWeight: "600", color: "gray" }}> {translate("navbar.sentcount.title", "This month quota")}</div>
              <div style={{ fontSize: "10px", textAlign: "right", fontWeight: "600", color: "gray" }}> {translate("navbar.sentcount", "Sent count")}/{translate("navbar.quota", "Quota")}</div>
              <Tooltip placement="bottom" title={<div>
                {badgeColor(account) === "green" && <div>{translate("navbar.targetreach.tooltip1", "navbar.targetreach.tooltip1")}</div>}
                {badgeColor(account) === "orange" && <div>{translate("navbar.targetreach.tooltip2", "navbar.targetreach.tooltip2")}</div>}
                {badgeColor(account) === "red" && <div> {translate("navbar.targetreach.tooltip3", "navbar.targetreach.tooltip3")}</div>}
              </div>}>
                <div style={ { display: "flex", justifyContent: "flex-end" }}>
                  <Badge dot={true} color={badgeColor(account)}/>
                  <div style={{ display: "flex", fontSize: "10px", alignItems: "center", fontWeight: "600", color: "gray" }}> {account?.lineConsumption || 0}/{account?.lineQuota || 0}</div>
                </div>
              </Tooltip>
              {user?.balance.message >= 0 && <Tooltip placement="bottom" title={<div>
                {lsegBadgeColor() === "green" && <div>{translate("navbar.lseg.tooltip1", "navbar.lseg.tooltip1")}</div>}
                {lsegBadgeColor() === "orange" && <div>{translate("navbar.lseg.tooltip2", "navbar.lseg.tooltip2")}</div>}
                {lsegBadgeColor() === "red" && <div> {translate("navbar.lseg.tooltip3", "navbar.lseg.tooltip3")}</div>}
              </div>}>
                <div style={ { display: "flex", justifyContent: "flex-end" }}>
                  <Badge dot={true} color={lsegBadgeColor()}/>
                  <div style={{ display: "flex", fontSize: "10px", alignItems: "center", fontWeight: "600", color: "gray" }}> {customer?.plan.messageLimit - user?.balance.message || 0}/{customer?.plan.messageLimit || 0}</div>
                </div>
              </Tooltip>}
            </div>
            }
          </div>
          <div style={{ marginTop: "10px", fontSize: "10px", textAlign: "right", fontWeight: "600", color: "gray" }}>
            {account &&
            <div>
              <div tyle={{ fontSize: "10px", textAlign: "right", fontWeight: "600", color: "gray" }}>{translate("navbar.followerCount", "Follower count")}:{account?.followerLineWithOutBlock}</div>
              <div style={{ fontSize: "10px", textAlign: "right", fontWeight: "600", color: "gray" }}> {translate("navbar.targetReach", "Target reach")} : {account?.targetReach}</div>
            </div>
            }
          </div>
        </div>

        {checkRole(["AGENCY", "ADMIN"]) && customer && (
          <div className="customer">

            <a className="button-wrapper-power">
              <span><Badge status="processing" /> {customer.companyName}</span> <PoweroffOutlined onClick={onAccountLogout} />
            </a>

            {account && <a style={{ marginTop: "10px" }} className="button-wrapper-power">
              <span><Badge status="processing" />{account.displayName}</span>
            </a>}
          </div>
        )}

        {checkRole(["ACCOUNT"]) && customer && (
          <div className="customer">
            {account && <a style={{ marginTop: "10px" }} className="button-wrapper-power">
              <span><Badge status="processing" />{account.displayName}</span>
            </a>}

          </div>
        )}
        <div className="nav-gustom-wrapper" style={{ height: height - 255 }}>
          <MainMenu
            selectedKeys={selecteds}
            defaultValue={selecteds}
            defaultOpenKeys={openkeys}
            mode="inline"
            theme="light">
            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && (
              <Menu.Item key="dashboard" className="nav-gustom-text" icon={<DashboardOutlined />} >
                {translate("dashboard.title", "Dashboard")} <NavLink to="/" />
              </Menu.Item>
            )}
            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "SUPPORT"]) && customer && (
              <Menu.Item className="nav-gustom-text" key="chat" icon={<MessageOutlined />}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  {translate("privatechat", "Private chat")}
                  <Badge count={notifications.filter(n => n.account === account._id).length}></Badge>
                </div>
                <NavLink to="/chat" />
              </Menu.Item>
            )}
            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"]) && customer && (
              <Menu.Item key="follower" icon={<UsergroupAddOutlined />}>
                {translate("followers", "User management")}    <NavLink to="/follower/list" />
              </Menu.Item>
            )}

            {checkRole(["AGENCY"]) && !customer && (
              <Menu.Item className="nav-gustom-text" key="customer" icon={<UserAddOutlined />}>
                {translate("adminmenu.useraccount", "User accounts")}  <NavLink to="/customer" />
              </Menu.Item>
            )}

            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN"])&& (
              <Menu.Item key="staff" icon={<UserOutlined />}>
                {translate("staff", "Staff")}    <NavLink to="/staff" />
              </Menu.Item>
            )}

            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"]) && customer && (
              <SubMenu className="nav-gustom-text" key="message" icon={<SendOutlined />} title={translate("message", "Message management")}>
                <Menu.Item key="send" icon={<MessageOutlined />}>
                  {translate("message.sendmessage", "Send message")}<NavLink to="/message/send" />
                </Menu.Item>
                <Menu.Item key="sent" icon={<PicLeftOutlined />}>
                  {translate("sent", "Sent message")}<NavLink to="/message/sent" />
                </Menu.Item>
                { checkPermission("REGULAR") &&
                <Menu.Item key="regular" icon={<ClockCircleOutlined />}>
                  {translate("navbar.regular", "Regular")} <NavLink to="/message/regular" />
                </Menu.Item>}
                <Menu.Item key="template" icon={<FolderOpenOutlined />}>
                  {translate("template", "Message template")} <NavLink to="/message/template" />
                </Menu.Item>
                <Menu.Item key="target_form" icon={<UserAddOutlined />}>
                  {translate("navbar.target.form", "Target form")} <NavLink to="/message/target_form" />
                </Menu.Item>
                <SubMenu className="nav-gustom-text" key="imagemap" icon={<FileImageOutlined />} title={translate("imagemap", "Imagemap")}>
                  <Menu.Item key="image" icon={<FileImageOutlined />}>
                    {translate("imagemap.type.image", "Imagemap: Image")} <NavLink to="/imagemap/image" />
                  </Menu.Item>
                  <Menu.Item key="video" icon={<VideoCameraOutlined />}>
                    {translate("imagemap.type.video", "Imagemap: Video")} <NavLink to="/imagemap/video" />
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="carousel" icon={<SwitcherOutlined />}>
                  {translate("navbar.carousel", "Card type message")} <NavLink to="/carousel" />
                </Menu.Item>

              </SubMenu>
            )}

            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"]) && customer && (
              <SubMenu className="nav-gustom-text" key="products" icon={<CodeSandboxOutlined />} title={translate("products", "Products")}>
                <Menu.Item key="richmenu" icon={<MenuOutlined />}>
                  {translate("products.richmenu", "Rich menus")}   <NavLink to="/products/richmenu" />
                </Menu.Item>
                <Menu.Item key="scenario" icon={<NodeIndexOutlined />}>
                  {translate("scenario.list.title", "Scenario broadcast")} <NavLink to="/products/scenario" />
                </Menu.Item>
                <Menu.Item key="trigger" icon={<NodeExpandOutlined />}>
                  {translate("trigger.action.title", "Trigger action")} <NavLink to="/products/trigger" />
                </Menu.Item>
                <Menu.Item key="tags" icon={<TagsOutlined />}>
                  {translate("tag.manage", "Tag management")} <NavLink to="/products/tags" />
                </Menu.Item>
                <Menu.Item key="webhook" icon={<ApiOutlined />}>
                  {translate("webhook.navbar.list", "Webhook")} <NavLink to="/webhook" />
                </Menu.Item>

                <Menu.Item key="liff" icon={<BlockOutlined />}>
                  {translate("liff.navbar", "Liff application")} <NavLink to="/liff/application" />
                </Menu.Item>
                {checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN"]) && apiTokenPemission && (
                  <Menu.Item key="token" icon={<UserOutlined />}>
                    {translate("navbar.token", "Token")}    <NavLink to="/token" />
                  </Menu.Item>
                )}
              </SubMenu>
            )}

            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "DELIVERY_MESSAGE"]) && customer && (
              <SubMenu className="nav-gustom-text" key="rakuten" icon={<AppstoreAddOutlined />} title={translate("rakuten", "Rakuten")}>
                {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "DELIVERY_MESSAGE"]) && (
                  <Menu.Item key="connective" icon={<ClusterOutlined />}>
                    {translate("rakuten.connection", "Connection")} <NavLink to="/rakuten/connective" />
                  </Menu.Item>
                )}
                {((() => {
                  if ((account?.rakutenServiceSecret && account?.rakutenLicenseKey) || account?.rakutenServiceKeyHidden) {
                    return (<>
                      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]) &&
                      <Menu.Item key="cooperation" icon={<FormOutlined />}>
                        {translate("cooperationform.title", "Cooperation Form")} <NavLink to="/rakuten/cooperation" />
                      </Menu.Item>}

                      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]) &&
                      <Menu.Item key="followers" icon={<FormOutlined />}>
                        {translate("rakuten.followers.list", "Rakuten Followers")} <NavLink to="/products/rakuten/followers" />
                      </Menu.Item>}

                      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]) &&
                      <Menu.Item key="coupon" icon={<GiftOutlined />}>
                        {translate("rakuten.coupon", "Rakuten Coupon")} <NavLink to="/rakuten/coupon" />
                      </Menu.Item>}

                      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]) &&
                      <Menu.Item key="reports" icon={<FileOutlined />}>
                        {translate("rakuten.coupon.reports", "Coupon reports")} <NavLink to="/rakuten/reports" />
                      </Menu.Item>}

                      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]) &&
                       <Menu.Item key="category" icon={<SettingOutlined />}>
                         {translate("rakuten.category", "Rakuten Category")} <NavLink to="/rakuten/category" />
                       </Menu.Item>}

                      {/* {(!isRakuLine && (((systemSetting?.payload && checkRole(["ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "DELIVERY_MESSAGE"])) || checkRole(["ADMIN"])))) && <Menu.Item key="deliverymessage" icon={<MessageOutlined />}>
                        {translate("navbar.deliverymessage", "Delivery message")}<NavLink to="/deliverymessage" />
                      </Menu.Item>
                      } */}
                      {((((systemSetting?.payload && checkRole(["ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "DELIVERY_MESSAGE"]) && (accountSetting?.notifMessageStatus === "APPROVED" || accountSetting?.notifMessageStatus === "STOPPED")) || checkRole(["ADMIN"])))) && <Menu.Item key="deliverymessage" icon={<MessageOutlined />}>
                        {translate("navbar.deliverymessage", "Delivery message")}<NavLink to="/deliverymessage" />
                      </Menu.Item>
                      }
                      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"]) && (!isRakuLine && account?.ecmaccesskey && rmsreportPermission) && (
                        <Menu.Item key="rms_report" icon={<FileSearchOutlined />}>
                          {translate("rms.sns.report", "RMS Report")} <NavLink to="/rms_report" />
                        </Menu.Item>
                      )}
                    </>);
                  }
                  return (<></>);
                }))()}
              </SubMenu>
            )}
            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"]) && customer && rakurakuPermission &&(
              <SubMenu className="nav-gustom-text" key="thirparty" icon={<AppstoreAddOutlined />} title={translate("thirdparty", "Third party")}>
                <Menu.Item key="rakupon" icon={<ClusterOutlined />}>
                  {translate("rakuraku.connection", "Rakurakupon")} <NavLink to="/rakupon/con" />
                </Menu.Item>
              </SubMenu>)}

            {checkRole(["ADMIN"]) && !customer && (
              <>
                {/* <Menu.Item className="nav-gustom-text" key="list" icon={<UserSwitchOutlined />}>
                  {translate("agency.accounts", "Agency accounts")}  <NavLink to="/user/list" />
                </Menu.Item> */}
                <Menu.Item className="nav-gustom-text" key="customer" icon={<UserAddOutlined />}>
                  {translate("adminmenu.useraccount", "User accounts")}  <NavLink to="/customer" />
                </Menu.Item>
                <Menu.Item className="nav-gustom-text" key="plan" icon={<OrderedListOutlined />}>
                  {translate("plan.setting", "Plan settings")}   <NavLink to="/admin/plan" />
                </Menu.Item>
                <Menu.Item key="account_list_admin" icon={<DatabaseOutlined />}>
                  {translate("debug.account.list", "Account List")} <NavLink to="/admin/account_list_admin" />
                </Menu.Item>
                <Menu.Item className="nav-gustom-text" key="notifmessage_delegate" icon={<UserAddOutlined />}>
                  {translate("adminmenu.notifmessage_delegate", "Deliver message delegate")}  <NavLink to="/notifmessage_delegate" />
                </Menu.Item>
                <SubMenu className="nav-gustom-text" key="settings" title={translate("setting.title", "Setting")} icon={<SettingOutlined />}>
                  <Menu.Item key="business" icon={<SwapOutlined />}>
                    {translate("businesstype", "Business type")}<NavLink to="/settings/reference/business" />
                  </Menu.Item>
                  <Menu.Item className="nav-gustom-text" key="add_announcements" icon={<FileTextOutlined />}>
                    {translate("navbar.news", "News")} <NavLink to="/admin/news" />
                  </Menu.Item>
                  <Menu.Item key="localization" icon={<TranslationOutlined />}>
                    {translate("setting.localization", "Localization")} <NavLink to="/settings/localization" />
                  </Menu.Item>
                  {/* !isRakuLine &&<Menu.Item key="tax" icon={<CopyOutlined />}>
                    {translate("navbar.tax", "Tax")} <NavLink to="/settings/tax" />
                  </Menu.Item>*/}
                  {!isRakuLine && <Menu.Item key="terms" icon={<CopyOutlined />}>
                    {translate("navbar.termsOfUse", "Terms of Use")} <NavLink to="/settings/document/terms" />
                  </Menu.Item>}
                  {!isRakuLine && <Menu.Item key="agreement" icon={<CopyOutlined />}>
                    {translate("navbar.privacyPolicy", "Privacy policy")} <NavLink to="/settings/document/agreement" />
                  </Menu.Item>}
                </SubMenu>

              </>
            )}

            {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "DELIVERY_MESSAGE"]) && (
              <SubMenu className="nav-gustom-text" key="operation" icon={<FileDoneOutlined />} title={translate("report", "Reports")}>
                {/*
                  !isRakuLine && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN"]) && (
                    <Menu.Item key="payment" icon={<ProfileOutlined />}>
                      {translate("payment.history", "Payment history")} <NavLink to="/operation/payment" />
                    </Menu.Item>
                  )
                */}
                {
                  (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"]) &&customer)&&(
                    <>
                      <Menu.Item key="broadcast_report" icon={<MailOutlined />}>
                        {translate("operation.broadcast", "Broadcast report")} <NavLink to="/operation/broadcast_report" />
                      </Menu.Item>
                    </>)
                }
                {
                  (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"]) &&customer)&&(
                    <>
                      <Menu.Item key="multicast_report" icon={<MailOutlined />}>
                        {translate("multicast.report", "Multicast report")} <NavLink to="/operation/multicast_report" />
                      </Menu.Item>
                    </>)
                }
                {
                  (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"]) &&customer)&&(
                    <Menu.Item key="scenario_report" icon={<FileSearchOutlined />}>
                      {translate("scenario.report", "Scenario report")} <NavLink to="/operation/scenario_report" />
                    </Menu.Item>)
                }
                {
                  (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"]) &&customer)&& (
                    <Menu.Item key="trigger_report" icon={<FileSearchOutlined />}>
                      {translate("trigger.report", "Trigger report")} <NavLink to="/operation/trigger_report" />
                    </Menu.Item>)
                }

                {/* {(!isRakuLine && (((systemSetting?.payload && checkRole(["ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "DELIVERY_MESSAGE"])) || checkRole(["ADMIN"])))) && customer &&
                 <Menu.Item key="deliverymessage_report" icon={<MessageOutlined />}>
                   {translate("navbar.deliverymessage_report", "Delivery message report")}<NavLink to="/deliverymessage_report" />
                 </Menu.Item>
                } */}
                {((((systemSetting?.payload && checkRole(["ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "DELIVERY_MESSAGE"]) && (accountSetting?.notifMessageStatus === "APPROVED" || accountSetting?.notifMessageStatus === "STOPPED")) || checkRole(["ADMIN"])))) && customer &&
                  <Menu.Item key="deliverymessage_report" icon={<MessageOutlined />}>
                    {translate("navbar.deliverymessage_report", "Delivery message report")}<NavLink to="/deliverymessage_report" />
                  </Menu.Item>
                }
                {(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"])) &&
                 <Menu.Item key="operation_report" icon={<ProfileOutlined />}>
                   {translate("operation.report", "Operation Reports")} <NavLink to="/operation/operation_report" />
                 </Menu.Item>
                }

                {(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"])) &&
                <Menu.Item key="log" icon={<FileSearchOutlined />}>
                  {translate("operation.log", "Operation Log")} <NavLink to="/operation/log" />
                </Menu.Item>}

                {(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"])) &&
                <Menu.Item key="webhook_report" icon={<ForkOutlined />}>
                  {translate("webhook.navbar.log", "Webhook Log")} <NavLink to="/operation/webhook_report" />
                </Menu.Item>
                }
                {
                  checkPermission("FILE_EXPORT") && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE"])&& customer && (
                    <Menu.Item key="file" icon={<DownloadOutlined />}>
                      {translate("file.list.title", "Exported files")} <NavLink to="/file" />
                    </Menu.Item>
                  )
                }
                {
                  checkRole(["ADMIN"]) && checkRole(["ADMIN"]).permission && !customer && (
                    <Menu.Item key="admin_file" icon={<DownloadOutlined />}>
                      {translate("file.list.title", "Exported files")} <NavLink to="/admin_file" />
                    </Menu.Item>
                  )
                }
              </SubMenu>
            )}

            {checkRole(["ADMIN"]) && !customer && (
              <Menu.Item key="debug" icon={<DatabaseOutlined />}>
                {translate("navbar.debug", "Debug")} <NavLink to="/admin/debug" />
              </Menu.Item>
            )}

            {checkRole(["ADMIN"]) && !customer && (
              <Menu.Item className="nav-gustom-text" key="crawler" icon={<SwapOutlined />}>
                {translate("adminmenu.crawlers", "Crawlers")}  <NavLink to="/crawler" />
              </Menu.Item>
            )}

            {checkRole(["ADMIN"]) && !customer && (
              <Menu.Item key="system_setting" icon={<SettingOutlined />}>
                {translate("debug.system.setting.list", "System setting")} <NavLink to="/system/setting" />
              </Menu.Item>
            )}
          </MainMenu>
        </div>
      </div>

      {((process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && (user.balance && user.balance?.alert && user?.balance?.alert && alert.length > 0) || account?.alert?.licenseKey)) && (
        <ContainerNotification>
          <Notfication type="fixed" user={user} account={account}/>
        </ContainerNotification>
      )}
    </NavBar>
  );
};
const ContainerNotification =styled.div`
  position: fixed;
  bottom: 0;
  left: 370px;
  z-index: 1000;
`;
const NavBar = styled.div`
  background: #fff;
  /* border:solid 1px; */
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .logo-container { 
    display: flex;
    color: #ff8400;
    justify-content: space-between;
    width: 100%;
  }
  .nav-gustom-wrapper {
    overflow-y : scroll;
    overflow-x : hidden;
    /* border :solid 1px; */
    /* height: 785px; */
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* padding-bottom: 30px; */
    padding-top: 10px;
    
  }
  .hr{
    border: none;
    border-bottom: solid 1px;
    border-color: #e6e6e6;
  }
  .limited-card-wrapper {
      background-color :#FAFAFA;
      border: solid 1px white;
      margin-top: 10px;
      .limited-card-item {
        margin: 17px 3px;
        border-bottom: solid 1px #e8e8e8;
        
        font-size :12px;
        /* border :solid 1px; */
        padding:2px;
        .card-footer {
          display: flex;
          font-weight: 600;
          color: gray;
          justify-content: flex-end;
          font-size: 13px;
        }
      }
    }
  .nav-gustom-wrapper::-webkit-scrollbar {
    width: 5px;
  }
  .nav-gustom-wrapper::-webkit-scrollbar-thumb {
    background: ${colors.primaryDark};
    border-radius: 10px;
  }
  .localization-image-icon {
    width: 13px;
    margin-right: 10px;
  }
  .logo {
    width: 100%;
    padding: 20px 0px;
    img {
      width: ${layouts.navbarLogoSize};
      object-fit: contain;
    }
  }
  .customer {
    .ant-badge-status-processing {
      background: ${colors.primaryDark};
    }
    .timezone {
      margin-top: 5px;
      background: #fafafa;
      padding: 2px 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width :22px;
      }
    }
    a {
      padding: 10px;
      background: #f7f7f7;
      font-size: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 2px;
      border: 1px solid #e8e8e8;
      color: #333;
      font-weight: 400;
      .anticon {
        &:hover {
          color: red;
        }
      }
    }
    a:focus {
      padding: 10px;
      background: #f7f7f7;
      font-size: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 2px;
      border: 1px solid #e8e8e8;
      color: #333;
      font-weight: 400;
       
      .anticon {
        
        font-size : 18px;
        color: #ff8400;
        &:hover { }
      }
    }
  }
`;

const MainMenu = styled(Menu)`
  border-right: none;
  z-index: 10;
  padding: 15px 0;
  background: transparent;
  
  .nav-gustom-text {
    .ant-menu-item {
      font-size: 13px !important;
    }
  }
  .ant-menu-submenu > .ant-menu  {
    background: transparent;
    border-radius: 8px;
  }
  .ant-menu-item {
    border-radius: 4px;
    font-size: 14px;
    /* color: #fff; */
    &::after {
      border-right: none!important;
      color :  ${colors.primaryDark};
    }
  }
  .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
    color: ${colors.primaryDark};
    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
      background:  ${colors.primaryDark};
    }
  }
  .ant-menu-submenu-selected {
    color: ${colors.primaryDark};
  }
  &:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${colors.primaryDark};
    color: #fff;
  }
`;