import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { auth } from "../../apis";
import { Button, Card, Alert, Switch, Checkbox } from "antd";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import UserIcon from "../../assets/user.png";
import WriteIcon from "../../assets/write.png";
import BuildIcon from "../../assets/build.png";
import Logo from "../../assets/logo_white.png";
import RakuLineLogoImage from "../../assets/logo_rakuline_white.png";
import DisplayImageLeft from "../../assets/display_image_left.png";
import DisplayImageRight from "../../assets/display_image_right.png";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import { CheckCircleOutlined, WarningOutlined, BulbOutlined } from "@ant-design/icons";

const FormSchema =(translate) => Yup.object().shape({
  email      : Yup.string().required(translate("system.message.required", "Please fill out this field!")).email(translate("login.register.invalidmail", "Invalid e-mail")),
  name       : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  companyName: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
});

export default () => {
  const { translate, changeLanguage, lang } = useTranslate();
  const [message, setMessage] = React.useState(null);
  const [alertBgColor, setAlertBgColor] = React.useState(colors.primaryDark);
  const [singupButton, setSignupButton] = React.useState({ term: false, privacy: false });
  const onSubmit = async (data) => {
    try {
      await auth.signup(data);
      setAlertBgColor("#58D77C");
      setMessage(<div style={{ color: "white", fontWeight: "600" }}><CheckCircleOutlined style={{ marginRight: "6px" }}/> {translate("login.register.checkmail", "Please check your email for a message with your verify link.")}</div>);
      setSignupButton({ ...singupButton, term: false, privacy: false });

    } catch (error) {
      setAlertBgColor("#E35755");
      setMessage(notfication(error.message, "error"));
    }
  };

  const notfication = (data, type) =>{
    if (data === "ALREADY_REGISTERED") {
      if (type === "error")
        return <div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("signup.warning.registered", "This e-mail address is already registered!")}</div>;
    }
    else {
      return <div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("system.error", data)}</div>;
    }
  };
  const checkTerm = (e) => {
    setSignupButton({ ...singupButton, term: e.target.checked });
  };
  const checkPrivacy = (e) => {
    setSignupButton({ ...singupButton, privacy: e.target.checked });
  };

  return (
    <AuthWrapper>
      {/* <img className="display-image-left" style={{ position: "fixed", left: "0", bottom: "0" }} src={DisplayImageLeft} />
      <img className="display-image-right" style={{ position: "fixed", right: "0", bottom: "0" }} src={DisplayImageRight} /> */}
      <Card className="login">
        <div className="logo-wrapper">
          <img className="logo-image" src={process.env.REACT_APP_WEBSITE_MODE == "rakuline" ? RakuLineLogoImage : Logo} />
        </div>
        <div className="logo">
          <p className="form-title-text">
            {translate(
              "login.register.text.signup",
              "Sign up your new account"
            )}
          </p>
        </div>
        {message && (
          <Alert
            style={{
              marginBottom: 20,
              background  : alertBgColor,
              border      : "none",
              borderRadius: "10px",
            }}
            message={<div style={{ color: "white" }}>{message}</div>}
          />
        )}
        <Formik
          initialValues={{
            email      : undefined,
            name       : undefined,
            companyName: undefined,
          }}
          validationSchema={() => FormSchema(translate)}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form layout="vertical">
              <FormItem placeholder="Email" name="email" required>
                <Input
                  prefix={<img className="icon" src={UserIcon} />}
                  className="form-input"
                  name="email"
                  placeholder={translate("login.mail", "Email")}
                />
              </FormItem>
              <FormItem name="companyName" placeholder="Company name" required>
                <Input
                  prefix={<img className="icon" src={BuildIcon} />}
                  className="form-input"
                  name="companyName"
                  placeholder={translate("useraccount.company", "Company name")}
                />
              </FormItem>
              <FormItem name="name" placeholder="Name" required>
                <Input
                  prefix={<img className="icon" src={WriteIcon} />}
                  className="form-input"
                  name="name"
                  placeholder={translate("login.username", "Name")}
                />
              </FormItem>
              <FormItem name="condition">
                <Checkbox onChange={checkTerm}>
                  <span style={{ color: "white" }}>
                    <a href="/terms" target="_blank">
                      {translate("system.terms", "I agree to Terms of Use")}
                    </a>{" "}
                  </span>
                </Checkbox>{" "}
                <br />
                <Checkbox onChange={checkPrivacy}>
                  <span style={{ color: "white" }}>
                    <a href="/privacy" target="_blank">
                      {translate("system.policy", "I agree to Privacy policy")}
                    </a>
                  </span>
                </Checkbox>
              </FormItem>

              <Button
                disabled={
                  !(singupButton.term === true && singupButton.privacy === true)
                }
                className="form-button"
                htmlType="submit"
                type="primary"
                loading={isSubmitting}
                block
              >
                <span>{translate("button.register", "Register")}</span>
              </Button>

              <p className="form-footer-text" style={{ margin: 0 }}>
                {translate(
                  "login.register.text.hasaccount",
                  "Already has an acount ?"
                )}{" "}
                <Link className="form-text" to="/login">
                  {translate("login.register.hyperlink", "Log in")}
                </Link>
              </p>
              <div style={{ textAlign: "center", marginTop: 10 }}>
                <a style={{ color: "white" }} href={translate("system.manual_link", "#")} target="_blank">
                  <BulbOutlined /> {translate("system.manual", "Manual")}
                </a>
              </div>
            </Form>
          )}
        </Formik>

        <Switch
          style={{ marginTop: "5px" }}
          checkedChildren="JP"
          unCheckedChildren="EN"
          onChange={() => changeLanguage(lang === "EN" ? "JP" : "EN")}
          defaultChecked={lang === "EN"}
        />
      </Card>
    </AuthWrapper>
  );
};


const AuthWrapper = styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;
  a:hover {
    color :${colors.primaryDark};
    border-color: ${colors.primaryDark};
    
  }
  a {
    color :white;
    border-bottom: 1px solid #fafafa;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color :${colors.primary};
    border-color :${colors.primary};
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: white;
  }
  .ant-checkbox-wrapper:hover {
    border-color: white;
  }
  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
  }
  .display-image-left {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 278px;
  }
  .display-image-right {
    width : 278px;
  }
  .login {
    background          : ${colors.primary};
    border              : 1px solid #e8e8e8;
    border-radius       : 10px;
    width               : 400px;
    margin              : auto;
    padding-bottom      : 10px;
    .form-input .icon {
      width             : 14px
    }
    .form-input {
      height            : 45px;
      border-radius     : 10px;
    }
    .logo {
      text-align        : center;
      margin            : 10px 0 30px 0;

      img {
        height          : 50px;
        margin          : auto;
      }
    }

    .form-text{
      color             : white;
      font-weight       : 400;
      font-size         : 14px; 
      transition        : all 0.3s ease-in;
      &:hover {
      color                : #007b3b;
      text-decoration-line : underline;
      }
      margin-left: 8px;
    }
    .logo-wrapper {
      display           : flex;
      justify-content   : center;
      margin-top        : -91px;
    }
    .logo-image{
      width             : 110px;
      margin-bottom     : 17px;
      margin-top        : 87px;
    }
    .form-button {
      height            : 40px;
      border-radius     : 10px;
      background-color  : ${colors.primaryDark};
      border            : none;
      font-size         : 21px;
      font-weight       : bold;
      transition        : all 0.3s ease-in;
      &:hover {
        background-color: #007b3b;
      }
    }
    .form-title-text {
      font-family       : unset;
      font-size         : 18px;
      color             : white;
    }
    .form-footer-text{
      font-size         : 12px;
      padding-top       : 7px;
      font-weight       : 500;
      text-align: center;
      color: white;
    }
    .form-title-one-text {
      color             : white;
      font-size         : 27px;
      font-weight       : bold;
    }
  }
`;
