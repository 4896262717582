import React from "react";
import ScenarioList from "./List";
import { tags as tagsApi, scenario as scenarioApi } from "../../apis";
import { message, Empty, Button, Tag } from "antd";
import { RowAction } from "../../components";
import { ExclamationCircleOutlined, SwapRightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useFetch, useTranslate } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/CustomTable";
import moment from "moment";
import styled from "styled-components";
import colors from "../../colors";
import DateForm from "./components/DateForm";
import TagForm from "./components/TagForm";
import TimeForm from "./components/TimeForm";
import TextForm from "./components/TextForm";

export default React.forwardRef((props, ref) => {
  const { action: parent, disabled, scenarioTableRef } = props;
  const myTableRef = React.useRef();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { mapEvents } = useSelector(state => state.process);
  const { processEvents, tags, lang } = useSelector(state => state.general);
  const [action, setAction] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useFetch(tagsApi.select(dispatch))();

  const onAction = async (key, { index, event }) => {
    switch (key) {
      case "set": {
        setAction(["scenario", { ...event, index }]);
        break;
      }
      case "remove": {
        setAction(["confirm", event]);
        break;
      }
      default:
    }
  };

  const onCancelEvent = async (type) => {
    setLoading(true);
    try {
      if (action && action[0] === "confirm" && action[1]) {
        if (type === "stop")
          await scenarioApi.stopEvent(action[1]._id);
        else if (type === "force")
          await scenarioApi.forceEvent(action[1]._id);
        else
          return;

        message.success(translate("system.message.success", "Successful"));
        scenarioTableRef.current.reload();
        loadEvents();
        onCancel();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }

  };

  const loadEvents = async () => {
    let events = await scenarioApi.events();

    dispatch({
      type   : "process.events",
      payload: events
    });
  };

  const onSelect = async (item) => {
    try {
      await scenarioApi.setEvent(item._id, { index: action[1].index, type: item.eventType, target: { tags: [], tagValues: [] }, active: true });
      message.success(translate("system.message.success", "Success!"));
      scenarioTableRef.current.reload();
    } catch (error) {
      message.error(error.message);
    }

    onCancel();
    loadEvents();
  };

  const onSubmit = async (data) => {
    switch (action && action[0]) {
      case "TIME": {
        await scenarioApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "TIME", date: data.date });
        break;
      }
      case "RAKUTEN_REVIEW":
      case "RAKUTEN_PURCHASE": {
        await scenarioApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: action && action[0], days: data.days, time: data.time });
        break;
      }
      case "TAG": {
        await scenarioApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "TAG", target: data.target });
        break;
      }
      case "TEXT": {
        await scenarioApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "TEXT", text: data.text });
        break;
      }
      default:
        await scenarioApi.setEvent(action[1].processId, { index: action[1].index, active: true, type: "RAKUTEN_REGISTER" });
    }


    message.success(translate("system.message.success", "Success!"));

    onCancel();
    loadEvents();
  };

  const onCancel = () => {
    setAction(null);
  };

  React.useImperativeHandle(ref, () => ({
    async reload() {
      loadEvents();
    }
  }));

  React.useEffect(() => {
    loadEvents();
  }, []);

  React.useEffect(() => {
    setAction(parent);
  }, [parent]);

  if (processEvents.length > 0)
    return (
      <>
        <h3>{translate("scenario.event.title", "Scenario start settings")}</h3>
        <EventTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          dataSource={processEvents}
          bordered
          pagination={false}
          thead={() => (
            <thead className="ant-table-thead">
              <tr>
                <th key="No" className="ant-table-cell">{translate("system.number", "No.")}</th>
                <th key="Start event" className="ant-table-cell">{translate("scenario.event.startevent", "Start event")}</th>
                <th key="Priority" className="ant-table-cell">{translate("scenario.event.priority", "Priority")}</th>
                <th key="Scenario set" className="ant-table-cell">{translate("scenario.event.status", "Status")}</th>
                <th key="Detail" className="ant-table-cell">{translate("scenario.event.scenarioset", "Sceanrio set")}</th>
              </tr>
            </thead>
          )} tbody={(row, index) => {
            let events = mapEvents[row.code];

            const renderPriority = (index) => {
              let isData = false;

              if (events && ["TIME", "TAG", "TEXT", "RAKUTEN_REVIEW", "RAKUTEN_PURCHASE"].indexOf(events[index]?.type) !== -1) {
                return (
                  <div style={{
                    display       : "flex",
                    justifyContent: "space-between"
                  }}>
                    {(() => {
                      switch (events[index]?.type) {
                        case "TIME": {
                          isData =!!events[index]?.date;
                          return RenderDate({ date: events[index]?.date, index, events, translate });
                        }
                        case "RAKUTEN_REVIEW":
                        case "RAKUTEN_PURCHASE": {
                          isData =!!events[index]?.time;
                          return RenderTime({ days: events[index]?.days, time: events[index]?.time, index, events, translate });
                        }
                        case "TEXT": {
                          isData =!!events[index]?.text;
                          return RenderText({ text: events[index]?.text, translate });
                        }
                        default:
                          isData =!!events[index]?.target.tags.length > 0;
                          return RenderTags({ tags, target: events[index].target, translate });
                      }
                    })()}

                    {disabled && (<Button disabled={isData} size="small" onClick={() => {
                      setAction([events[index].type, { ...(events[index]), index, _id: events[index]?._id, processId: events[index]?.process?._id, events }]);
                    }}>{translate("scenario.event.status.set", "Set")}</Button>)}
                  </div>
                );
              }

              if (events && ["FOLLOW", "RAKUTEN_REGISTER"].indexOf(events[index]?.type) !== -1) {
                return <Tag color="green" style={{ float: "left" }}>{translate("scenario.event.status.active", "Sceneario is active")}</Tag>;
              }

              return "-";
            };

            const renderRowAction = (index, event) => {
              return (
                <RowAction size="small" actions={events && events[index] ? ({
                  remove: translate("scenario.event.action.remove", "Remove")
                }) : {
                  set: translate("scenario.event.status.set", "Set"),
                }} onClick={(key) => {
                  onAction(key, { index, event });
                }} />
              );
            };

            return (
              <tbody key={index} classNamRenderTexte="ant-table-tbody">
                <tr key={index}>
                  <td key="index" className="ant-table-cell" rowSpan={["TIME", "TAG", "TEXT"].indexOf(row.code) !== -1 ? 5 : 1}>{index + 1}</td>
                  <td key="number" className="ant-table-cell" rowSpan={["TIME", "TAG", "TEXT"].indexOf(row.code) !== -1 ? 5 : 1}>{processEvents[index][lang.toLowerCase()]}</td>
                  <td key="Priority number" className="ant-table-cell text-right">
                    {((() => {
                      if (row.code === "TIME" || row.code === "TAG" || row.code === "TEXT") {
                        return translate("scenario.event.priority", "Priority 1");
                      }
                      return "-";
                    }))()}
                  </td>

                  <td key="Priority event" className="ant-table-cell text-right" rowSpan={1}>
                    {renderPriority(0)}
                  </td>

                  <td key="Scenario name" className="ant-table-cell text-right">
                    <div style={{
                      display       : "flex",
                      justifyContent: "space-between"
                    }}>
                      {events && events[0]?.process?.name || "-"}
                      {disabled && renderRowAction(0, { ...row, ...(events && events[0]), _id: events && events[0]?._id, processId: events && events[0]?.process?._id })}
                    </div>
                  </td>
                </tr>

                {(row.code === "TIME" || row.code === "TAG" || row.code === "TEXT") && [1, 2, 3, 4].map((index) => {
                  return (
                    <tr style={{ border: "solid 1px" }} key={index}>
                      <td className="ant-table-cell text-right">
                        <div>{translate("scenario.event.priority", "Priority")} {index + 1}</div>
                      </td>
                      <td className="ant-table-cell text-right">
                        {renderPriority(index)}
                      </td>
                      <td key="Scenario name and action" className="ant-table-cell text-right" style={{
                        display       : "flex",
                        justifyContent: "space-between"
                      }}>
                        {events && events[index]?.process?.name || "-"}
                        {disabled && renderRowAction(index, { ...row, ...(events && events[index]), _id: events && events[index]?._id, processId: events && events[index]?.process?._id })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            );
          }} />

        {action && action[0] && action[0] !== "confirm" && action[1] && (
          <Modal
            title={`${translate("scenario.event.activate.modal.title", "Activate (Priority")} ${action[1].index + 1})`}
            maskClosable={false}
            footer={null}
            onCancel={onCancel}
            visible={action && action[0] && action[0] !== "confirm"}>
            {(() => {
              switch (action[0]) {
                case "TIME":
                  return <DateForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
                case "RAKUTEN_REVIEW":
                case "RAKUTEN_PURCHASE":
                  return <TimeForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
                case "TEXT":
                  return <TextForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
                default:
                  return <TagForm action={action} onSubmit={onSubmit} onCancel={onCancel} />;
              }
            })()}
          </Modal>
        )}

        {action && action[0] === "confirm" && (
          <Modal
            title={null}
            footer={null}
            maskClosable={false}
            closable={false}
            width={416}
            onCancel={onCancel}
            visible={action && action[0] === "confirm"}>
            <EventConfirm>
              <div className="alert"><ExclamationCircleOutlined /> {translate("system.confirmation.question", "Are you sure?")}</div>
              <div className="footer">
                <Button type="default" style={{ borderColor: colors.primaryDark, color: "black" }} onClick={onCancel}>{translate("system.cancel", "Cancel")}</Button>
                <Button type="danger" loading={loading} onClick={() => onCancelEvent("stop")}>{translate("scenario.event.stop", "Stop")}</Button>
                <Button type="danger" loading={loading} onClick={() => onCancelEvent("force")}>{translate("scenario.event.force.stop", "Force stop")}</Button>
              </div>
            </EventConfirm>
          </Modal>
        )}

        {action && action[0] === "scenario" && (
          <Modal
            title={translate("scenario.list.title.modal", "Scenario list")}
            maskClosable={false}
            footer={null}
            onCancel={onCancel}
            visible={(action && action[0] === "scenario")}>
            <ScenarioList action={action} onSelect={onSelect} />
          </Modal>
        )}
      </>
    );

  return <div>{translate("system.loading", "Loading...")}</div>;
});

const RenderDate = ({ date, translate }) => {
  if (date) {
    return (
      <div>
        {moment(date).format("YYYY-MM-DD HH:mm")}
        <Tag color="green" style={{ float: "left" }}>{translate("scenario.event.status.active", "Sceneario is active")}</Tag>
      </div>
    );
  }

  return <Tag color="red" style={{ float: "left" }}>{translate("scenario.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
};

const RenderText = ({ text, translate }) => {
  if (text) {
    return (
      <div>
        {text}
        <Tag color="green" style={{ float: "left" }}>{translate("scenario.event.status.active", "Sceneario is active")}</Tag>
      </div>
    );
  }

  return <Tag color="red" style={{ float: "left" }}>{translate("scenario.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
};

const RenderTime = ({ days, time, translate }) => {
  if (time) {
    return (
      <div>
        {days} {translate("scenario.event.days", "Days")} {moment(time).format("HH:mm")}
        <Tag color="green" style={{ float: "left" }}>{translate("scenario.event.status.active", "Sceneario is active")}</Tag>
      </div>
    );
  }

  return <Tag color="red" style={{ float: "left" }}>{translate("scenario.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
};

const RenderTags = ({ tags = [], target = {}, translate })=> {
  let values = (target.tags || []).map((id, index) => {
    let tag = tags.find(tag => tag._id === id);
    switch (tag?.type) {
      case "SELECT": {
        let value = tag.values.find(tag => tag._id === target.tagValues[index].value);
        return (
          <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {translate(`default.tag.${value.value}`, value.name)} {target.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
        );
      }
      case "NUMBER": {
        return (
          <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {target.tagValues[index].value[0]} <SwapRightOutlined /> {target.tagValues[index].value[1]} {target.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
        );
      }
      case "CHECKBOX": {
        return (
          <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {target.tagValues[index].value ? `${translate("system.yes", "Yes")}` : `${translate("system.no", "No")}`}</Tag>
        );
      }
      case "DATE": {
        return (
          <Tag key={index}>{translate(`default.tag.${tag.auto}`, tag.name)}: {moment(target.tagValues[index].value[0]).format("YYYY-MM-DD")} <SwapRightOutlined /> {moment(target.tagValues[index].value[1]).format("YYYY-MM-DD")} {target.tagValues[index].exclusion && <span className="exclusion">{translate("tags.exclusion.text", "(exclusion)")}</span>}</Tag>
        );
      }
      case "TAG": {
        return (
          <Tag key={index}>{tag.name}</Tag>
        );
      }
      default:
        return null;
    }
  });

  if (values.filter(value => value !== null).length > 0)
    return (
      <div>
        {values} <Tag color="green" style={{ float: "left" }}>{translate("scenario.event.status.active", "Sceneario is active")}</Tag>
      </div>
    );

  return <Tag color="red" style={{ float: "left" }}>{translate("scenario.event.status.disactive", "Set start event value to start delivery!")}</Tag>;
};

const EventTable = styled(CustomTable)`
  margin-bottom: 20px;

  .ant-table-cell {
    padding: 4px!important;
    border-bottom: 1px solid #f0f0f0;
  }
`;
const EventConfirm = styled.div`
  .alert {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    svg {
      display: flex;
      font-size: 22px;
      color: #faad14;
      margin-right: 10px;
    }
  }
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      margin-left: 10px;
    }
  }
`;