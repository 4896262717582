import React from "react";
import { Empty, Modal, Space, Select } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker, Table, message } from "antd";
import { accountSettingApi, debug as debugApi } from "../../apis";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import AccountSettingForm from "./components/AccountSettingForm";

const { confirm } = Modal;
const { Option } = Select;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [systemAction, setSystemAction] = React.useState();
  const [filters, setFilters] = React.useState({
    query             : "",
    notifMessageStatus: undefined
  });

  const onSubmit = async (data)=> {
    try {
      await accountSettingApi.update(action[1]._id, data);
      message.success(translate("system.success", "Successfull"));
      myTableRef.current.reload();
    } catch (err) {
      message.error(translate("system.failed", "Error occured!"));
    } finally {
      setAction([]);
    }
  };

  const onSystemActionSubmit = async ()=> {
    try {
      switch (systemAction) {
        case "rakutensync":{
          setLoading(true);
          try {
            await debugApi.followerRakutenSync({ accountId: action[1]._id });
            message.success(translate("system.message.success", "Success"));
          } catch (err){
            message.error(err.message);
          }
          finally {
            myTableRef.current.reload();
            setLoading(false);
          }
          break;
        }
        case "sync" : {
          setLoading(true);
          try {
            await debugApi.followerSync(action[1]);
            message.success(translate("system.message.success", "Success"));
          } catch (err){
            message.error(err.message);
          }
          finally {
            myTableRef.current.reload();
            setLoading(false);
          }
          break;
        }
        default : break;
      }
    } catch (err) {
      console.log(err);
      message.error(translate("system.failed", "Error occured!"));
    } finally {
      setAction([]);
    }
  };

  const columns = useHeader({
    tableTitles: {
      no                  : translate("system.number", "No."),
      name                : translate("debug.account.name", "	Name"),
      customer            : translate("useraccount.company", "Customer"),
      notifMessageStatus  : translate("debug.account.deliverymessage", "Delivery message status"),
      notifMessageDelegate: translate("debug.account.deliverymessage.delegate.title", "Delegate"),
      action              : translate("system.action", "Action"),
      systemaction        : translate("debug.follower.systemaction", "System action"),
      settings            : translate("debug.accountlist.settings", "Settings")
    },
    translate,
    history,
    onAction: async (key, item) => {
      switch (key) {
        case "settings": {
          setAction(["settings", item]);
          break;
        }
        case "systemaction": {
          setAction(["systemaction", item]);
          break;
        }
        default:
      }
    },
  });

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader title={translate("debug.account.list", "Account management")} />
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
        </Space>
        <Space>
          <Button type="default" onClick={() => setFilters({ ...filters })}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>
      <PageContent>
        <MyTable
          maskClosable={false}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          columns={columns}
          loadData={debugApi.accountList}
        />
      </PageContent>
      <Modal
        maskClosable={false}
        visible={action[0] === "settings"}
        className="cooperation-modal"
        title={translate("debug.account.list.settings.title", "Account settings")}
        onCancel={() => setAction([])}
        width={800}
        footer={null}>
        <AccountSettingForm action={action} onSubmit={onSubmit} />
      </Modal>
      <Modal
        maskClosable={false}
        visible={action[0] === "systemaction"}
        className="cooperation-modal"
        title={translate("debug.account.list.systemaction.title", "Actions")}
        onCancel={() => setAction([])}
        footer={null}>
        <Select style={{ width: "100%", marginBottom: "25px" }}onChange={(e) => {
          setSystemAction(e);
        }}
        placeholder={translate("system.select", "Select")}
        >
          <Option value={"sync"}>{translate("debug.follower.sync", "Sync")}</Option>;
          <Option value={"rakutensync"}>{translate("debug.follower.rakutensync", "Rakuten sync")}</Option>;
        </Select>
        <Button type="primary" loading={loading} onClick={onSystemActionSubmit} block>{translate("system.save", "Save")}</Button>
      </Modal>

    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.name,
      render: (item) => <div>{item.displayName }</div>
    },
    {
      title : tableTitles.customer,
      render: (item) => {
        return (<div >{item?.customer?.companyName || "-"}</div>);
      }
    },
    {
      title : tableTitles.notifMessageStatus,
      render: (item) => {
        return (<div >{translate(`system.constant.deliverymessage.notifStatuses.${item?.settings?.notifMessageStatus}`, item?.settings?.notifMessageStatus) || "-"}</div>);
      }
    },
    {
      title : tableTitles.notifMessageDelegate,
      render: (item) => {
        if (item.settings?.notifMessageDelegate){
          return <div>{translate("debug.account.deliverymessage.delegate.true", "Delegated")}</div>;
        }
        return (<div >{translate("debug.account.deliverymessage.delegate.false", "Not delegated")}</div>);
      }
    },
    {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (record) => {
        return <RowAction actions={{
          systemaction: tableTitles.systemaction,
          settings    : tableTitles.settings,
        }} onClick={(key) => onAction(key, record)} />;
      }
    }
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .item1 {
    width: 70%;
  }
  .item2 {
    width: 30%;
  }
  .button-primary:hover {
    background : ${colors.primary} ;
    border-color : white;
    color        : white;
  }
  .button-primary {
    background    : ${colors.primaryDark} ;
    border-color  : white;
    color         : white;
  }
`;