import React from "react";
import Form from "./components/CustomerForm";
import ToggleForm from "./components/ToggleForm";
import PasswordForm from "./components/PasswordForm";
import { Spin, message, Modal, Alert as AntAlert } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { customer as customerService, auth as authService, user as userService, grant as grantService, general as generalService, account as accountApi } from "../../apis";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import colors from "../../colors";
import { useDispatch, useSelector } from "react-redux";
import VerifyPasswordForm from "./VerifyPasswordForm";
import CustomerToggleForm from "./components/grant/CustomerToggleForm";
import EcmAccessKeyForm from "./components/EcmAccessKeyForm";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default () => {
  const dispatch =useDispatch();
  const { translate } = useTranslate();
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [action, setAction] = React.useState(["update", null]);
  const [password, setPassword] = React.useState(null);
  const [ecmaccesskey, setEcmaccesskey] = React.useState(false);
  const { user, customer } = useSelector(state => state.general);
  const [removeModal, setRemoveModal] = React.useState(false);
  const [blockVsible, setBlockVsible] = React.useState(false);

  const reload = React.useCallback(
    async (signal) => {
      let res = await customerService.get(params.id, { signal });
      setAction(["update", res]);
      console.log("res ====>", res);
      setLoading(false);
    },
    [params.id]
  );

  const onEcmDisconnect =async (data)=> {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      content   : translate("ecm.disconnect.delete", "When disconnected from ECM you can't login from ECM, if it's okay with you click Yes?"),
      async onOk() {
        setLoading(true);
        try {
          await accountApi.ecmDisconnect(data);
          message.success(translate("system.message.success", "Success!"));
          await authService.me()(dispatch);
          reload();
        } catch (err){
          message.error(err.message);
        }
      } });
  };

  const logout = async () => {
    setLoading(true);
    await authService.logout();

    dispatch({
      type: "authService.logout"
    });
  };

  const onAccountLogout = async () => {
    dispatch({ type: "main.load", payload: true });
    await customerService.logout();
    await authService.me()(dispatch);

    message.success(translate("system.message.success", "Success"));
    history.push("/customer");
    dispatch({ type: "main.load", payload: false });
  };

  const submitVerifyPassword = async (data) => {
    try {
      let res;
      if (user.role === "AGENCY" || user.role === "ADMIN") {
        res = await grantService.removeCustomer({ id: action[1]._id.toString(), password: data.password });
        message.success(translate("system.alert.delete", "Account has been deleted"));
        onAccountLogout();
      } else {
        res = await customerService.remove({ password: data.password });
        message.success(translate("system.alert.delete", "Account has been deleted"));
        logout();
      }

    } catch (error) {
      message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
    }
  };

  const unblock = async () => {
    setBlockVsible(true);
    await userService.unblock(action[1] && action[1].user._id);
    message.success(translate("system.message.success", "Successful"));
    reload();
    setBlockVsible(false);
  };

  const paymentUnblock = async () => {
    setBlockVsible(true);
    await customerService.paymentUnblock(action[1] && action[1]._id);
    message.success(translate("system.message.success", "Successful"));
    reload();
    setBlockVsible(false);
  };

  const onSubmit = async (data) => {
    await customerService.update(data);

    message.success(translate("system.message.success", "Successful"));
    await authService.me()(dispatch);
    reload();
  };

  const onSubmitPassword = async (data) => {
    if (user.role === "AGENCY" || user.role === "ADMIN") {
      try {
        await grantService.changePassword({ newPassword: data.newPassword, password: data.password, id: action[1].user._id });
        message.success(translate("system.message.success", "Password has been changed successfully."));
        setPassword();
      } catch (error) {
        if (error.message === "INVALID_PASSWORD") {
          message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
        }
        else {
          message.error(translate("system.error", error.error));
        }
      }
    }
    else {
      try {
        await userService.changePassword({ newPassword: data.newPassword, password: data.password });
        message.success(translate("system.message.success", "Password has been changed successfully."));
        setPassword();
      } catch (error) {
        if (error.message === "INVALID_PASSWORD") {
          message.error(translate("system.invalid.password", "The password is incorrect. Retype your password. Letters in passwords must be typed using the correct case"));
        }
        else {
          message.error(translate("system.error", error.error));
        }
      }
    }
  };

  const onChangeEcmaccesskey =async (data)=> {
    try {

      await customerService.ecmaccesskey({ ...data, id: params.id });
      await generalService.init()(dispatch);

      await authService.me()(dispatch);
      reload();
      setLoading(true);
      message.success(translate("system.message.success", "Success"));
      setEcmaccesskey(false);
    } catch (err){
      if (err.message.message === "INVALID_ACCESSKEY") {
        message.error(translate("ecm.accesskey.invalid", "Accesskey is invalid"));
      } else {
        message.error(translate("ecm.accesskey.invalid", "Accesskey is invalid!"));
      }
    }
  };

  const onCancel = () => {
    setPassword(null);
  };

  const onChangePasword = () => {
    setPassword(["create"]);
  };

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("useredit", "Edit Account")} />
      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>
            {user.role === "ADMIN" && !customer?.ecmaccesskey &&
            <Alert
              message={translate("useredit.payment.block", "Payment block")}
              showIcon
              description={translate("useredit.payment.block.text", "Change customer state to Payment blocked")}
              type="warning"
              action={
                <Button onClick={()=> {
                  onSubmit({ ...action[1], paymentBlock: !(action[1]?.paymentBlock) });
                }} size="small" danger>{action[1]?.paymentBlock === false?`${translate("useredit.payment.block.true", "Block")}`:`${translate("useredit.payment.block.false", "Unblock")}`}</Button>
              }
            />}
            <div>
              {
                action[1] && action[1].user.passwordBlocked === true && (user.role === "ADMIN" || user.role === "AGENCY") &&
                <Alert
                  message={translate("login.password.blocked", "Account has been blocked due to too many failed login attempts")}
                  type="warning"
                  action={
                    <Button size="small" type="ghost" onClick={unblock} loading={blockVsible}>
                      {translate("useredit.unblock", "Unblock")}
                    </Button>
                  }
                  showIcon />
              }
            </div>
            <div>
              {
                action[1] && action[1].blocked === true && (user.role === "ADMIN" || user.role === "AGENCY") &&
                <Alert
                  message={translate("login.password.blocked", "Account has been blocked due to too many failed login attempts")}
                  type="warning"
                  action={
                    <Button size="small" type="ghost" onClick={paymentUnblock} loading={blockVsible}>
                      {translate("useredit.unblock", "Unblock")}
                    </Button>
                  }
                  showIcon />
              }
            </div>
            {loading ? <SpinWrapper><Spin className="spin-class" size="Large" /></SpinWrapper> : (<>
              <div style={{ marginBottom: 20 }}>
                {!customer?.ecmaccesskey && <Button type="default" onClick={onChangePasword} block>{translate("useredit.password", "Change Password")}</Button>}
              </div>
              <Form setEcmaccesskey={setEcmaccesskey} onEcmDisconnect={onEcmDisconnect} onSubmit={onSubmit} action={action} />
              {user.role === "AGENCY" && !customer?.ecmaccesskey && process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <CustomerToggleForm customer={{ user: action && action[1]._id }}/>}
              {user.role !== "ADMIN" && !customer?.ecmaccesskey && user.role !== "AGENCY" && process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <ToggleForm onSubmit={() => setRemoveModal(true)} />}
              {user.role === "ADMIN" && !customer?.ecmaccesskey && <Button style={{ width: "100%" }} onClick={() => setRemoveModal(true)} danger>{translate("system.delete", "Delete account")}</Button>}
            </>)}

          </FormWrapper>
        </div>
      </PageContent>

      <Modal
        maskClosable={false}
        title={translate("system.delete", "Delete")}
        visible={removeModal}
        onOk={null}
        onCancel={() => setRemoveModal(false)}
        footer ={null}>
        <VerifyPasswordForm onSubmit={submitVerifyPassword} visible={setRemoveModal}/>
      </Modal>

      <Modal
        maskClosable={false}
        title={translate("useredit.password", "Change password")}
        visible={!!password}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <PasswordForm onCancel={onCancel} onSubmit={onSubmitPassword} action={password} />
      </Modal>

      <Modal
        maskClosable={false}
        title={translate("ecm.accesskey.change", "ECM Accesskey change ")}
        visible={ecmaccesskey}
        onCancel={()=>setEcmaccesskey(false)}
        destroyOnClose
        footer={false}>
        <EcmAccessKeyForm onCancel={setEcmaccesskey} onSubmit={onChangeEcmaccesskey} />
      </Modal>

    </PageContainer>
  );
};

const Alert = styled(AntAlert)`
  margin-bottom :20px;
  Button {
    margin-bottom : 0px !important;
  }
  .ant-alert .ant-alert-warning {
    width :100% !important;
  }
`;

const FormWrapper = styled.div`
  width :600px;
`;

const SpinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
  .spin-class {
    .ant-spin-dot-item {
      background-color :${colors.primaryDark}
    }
  }
  
`;
