import React from "react";
import { follower as followerApi, rakuten, tags as tagsApi } from "../../apis";
import { Space, Empty, message, DatePicker, Checkbox, Button, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { CloseCircleOutlined, ReconciliationFilled, SearchOutlined, TagOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Modal } from "antd";
import styled from "styled-components";
import { useFetch, usePermission, useTranslate } from "../../hooks";
import Avatar from "antd/lib/avatar/avatar";
import moment from "moment";
import TaggedForm from "../Follower/components/TaggedForm";
import { useDispatch } from "react-redux";
import colors from "../../colors";
import AccountImage from "../../assets/account.png";
const { confirm } = Modal;

const { RangePicker } = DatePicker;

export default (props) => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef();
  const [query, setQuery] = React.useState("");
  const [action, setAction] = React.useState();
  const { checkRole } = usePermission();
  const [filters, setFilters] = React.useState({
    query      : "",
    cooperation: props.location?.state?.id,
    startDate  : undefined,
    endDate    : undefined
  });
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [selected, setSelected] = React.useState({});
  const [followers, setFollowers] = React.useState([]);
  const [disabled, setDisabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const dispatch = useDispatch();

  useFetch(tagsApi.select(dispatch))();
  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const changeRange = (e) => {
    setFilters({ ...filters, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const onCancel = (reload) => {
    if (reload === true) {
      setSelected({});
      myTableRef.current.reload();
    }
    setAction(null);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (action && action[0] === "tag")
        await followerApi.tag(data);
      else
        await followerApi.untag(data);

      message.success(translate("system.message.success", "Success"));

      onCancel(true);
      data.setFormData({});

      setFollowers([]);
      setLoading(false);
    } catch (error) {
      if (error.message && error.message.followers)
        message.error(translate("system.choose.user", "No user has been selected"));
      if (error.message && error.message === "FOLLOWER_NOTFOUND") {
        message.error(translate("system.error", "Follower is not found"));
      }
      setLoading(false);
    }
  };

  const name = (tag) => {
    switch (tag.auto) {
      case "ages":
        return translate("tag.ages", "age");
      case "subscriptionPeriods":
        return translate("tag.days", "day");
      default:
        return "";
    }
  };

  const columns = useHeader({
    disabled,
    selected,
    history,
    translate,
    name,
    onUntag: async ({ follower, tag, value }) => {
      await followerApi.untag({ followers: [follower._id], tags: [{ tag: tag, value }] });
      message.success(translate("system.message.success", "Success"));
      myTableRef.current.reload();
    },
    tableTitles: {
      no                : translate("system.number", "No."),
      avatar            : translate("rakuten.user", "User name"),
      tag               : translate("followers.tag", "Tag"),
      tagged            : translate("followers.connect.tag", "Connect tag"),
      untag             : translate("followers.remove.tag", "Remove tag"),
      isRakutenDate     : translate("rakuten.follower.rakuten.register.date", "Rakuten register date"),
      shopReviewCount   : translate("rakuten.follower.review.count.shop", "Shop Reviews"),
      avgShopScore      : translate("rakuten.follower.review.avg.shop", "Avg Score"),
      productReviewCount: translate("rakuten.follower.review.count.product", "Product reviews"),
      avgProductScore   : translate("rakuten.follower.review.avg.product", "Avg product score"),
      totalAmount       : translate("rakuten.follower.total.amount", "Total Amount"),
      averageAmount     : translate("rakuten.follower.average.amount", "Average Amount"),
      totalPurchases    : translate("rakuten.follower.total.purchase", "Total Purchase"),
      couponUsages      : translate("rakuten.follower.coupon.usage", "Coupon Usage"),
      orderno           : translate("rakuten.ordernumber", "Order No."),
      orderdate         : translate("rakuten.date", "Order date"),
      price             : translate("rakuten.price", "Order amount"),
      orders            : translate("rakuten.order.title", "Orders"),
      reviews           : translate("rakuten.review", "Reviews"),
      calculate         : translate("rakuten.re.calculate", "Re-calculate"),
    },
    onAction: async (key, item) => {
      switch (key) {
        case "reviews" : {
          history.push(`/products/rakuten/${item._id}/reviews`);
          break;
        }
        case "orders": {
          history.push({
            pathname: `/products/rakuten/${item._id}/orders`,
            state   : { id: item._id },
          });
          break;
        }
        case "calculate": {
          await rakuten.calculate(item._id);
          message.success(translate("system.message.success", "Successful"));
          myTableRef.current.reload();
          break;
        }
        case "tag": {
          let selections = {
            [item._id]: true
          };
          setSelected(selections);
          setFollowers(myTableRef.current.getItems().filter(item => selections[item._id]));
          setAction(["tag"]);
          break;
        }
        default:
      }
    },
    onSelect: (record, checked, type) => {
      if (type === "all") {
        if (checked === true) {
          setSelected({
            ...(checked === true ? myTableRef.current.getItems().reduce((accumulator, iterator) => ({ ...accumulator, [iterator._id]: true }), {}) : {}),
            all: checked
          });
        } else {
          setSelected({
            ...(checked === false ? myTableRef.current.getItems().reduce((accumulator, iterator) => ({ ...accumulator, [iterator._id]: false }), {}) : {}),
            all: checked
          });
        }

        setFollowers(myTableRef.current.getItems());
      } else {
        let selections = {
          ...selected,
          [record._id]: checked
        };
        setSelected(selections);
        setFollowers(myTableRef.current.getItems().filter(item => selections[item._id]));
      }
    },
  });
  const onTag = () => {
    setAction(["tag"]);
  };

  const onUntag = () => {
    setAction(["untag"]);
  };
  return (
    <PageContainer>
      <PageHeader title={translate("rakuten.follower.title", "Rakuten user")} extra={[ <Button key={0} disabled={followers.length === 0} onClick={onTag} type="primary"><TagOutlined /> {translate("followers.connect.tag", "Connect tag")}</Button>,
        <Button key={1} disabled={followers.length === 0} onClick={onUntag} type="danger"><TagOutlined /> {translate("followers.remove.tag", "Remove tag")}</Button>, ]} />
      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
        </Space>
        <div className="range-field">
          <span>{translate("system.search.range.title", "Purchase date:")}</span>
          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
        </div>
      </PageFilter>
      <PageFilter>
        <UserCount>
          <div className="card-wrapper">
            <div className="card-icon" style={{ background: colors.primaryDark, color: "white" }}>
              <img className="card-image" style={{ width: "20px" }} src={AccountImage} />
            </div>
            <div className="card-body">
              <div>
                <span style={{ fontSize: "16px" }}>{translate("rakuten.followers.total", "Result")}</span>
                <span style={{ fontSize: "16px", marginLeft: "8px" }}>{count}人</span>
              </div>
            </div>
          </div>
        </UserCount>
      </PageFilter>
      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={rakuten.followers}
          onResult={(e) => setCount(e.count)}
        />
        <Modal
          maskClosable={false}
          title={action && action[0] === "tag" ? translate("followers.connect.tag", "Connect Tag") : translate("followers.remove.tag", "Remove Tag")}
          visible={action && action[0]}
          onCancel={onCancel}
          bodyStyle={{ padding: 0 }}
          footer={null}>
          {action && action[0] && <TaggedForm submitLoad={loading} onSubmit={onSubmit} onCancel={onCancel} selected={selected} followers={followers} />}
        </Modal>

      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, onSelect, onUntag, selected = {}, disabled, translate, name }) => {
  return [{
    title    : tableTitles.no,
    dataIndex: "key",
    width    : "20px"
  }, {
    width: 10,
    title: () => {
      return (
        <div>
          <Checkbox checked={selected.all} onChange={(e) => onSelect(null, e.target.checked, "all")} disabled={!disabled} />
        </div>
      );
    },
    render: (record) => {
      return (
        <div>
          <Checkbox checked={selected[record._id]} disabled={!disabled} onChange={(e) => onSelect(record, e.target.checked)} />
        </div>
      );
    }
  }, {
    title : tableTitles.avatar,
    render: (record) => {
      return (
        <div>
          <Avatar src={<img src={record.avatar} />} /><span style={{ marginLeft: "20px" }}>{record.name}</span>
        </div>
      );
    },
  },
  {
    title : tableTitles.tag,
    render: (record) => {
      return (
        <div>
          {record.tags.map((tag, index) => {
            switch (tag.type) {
              case "NUMBER": {
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag._id, value: tag.number })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="cyan">
                    {tag.name}:{tag.number}
                  </Tag>
                );
              }
              case "CHECKBOX": {
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag._id, value: tag.checked })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="orange">
                    {tag.name}:{tag.checked ? "YES" : "NO"}
                  </Tag>
                );
              }
              case "DATE": {
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag._id, value: tag.date })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="lime">
                    {tag.auto === undefined ? tag.name : translate("default.tag.registeredDate", tag.name)}:{moment(tag.date).format("YYYY-MM-DD")}
                  </Tag>
                );
              }
              default:
                return (
                  <Tag
                    icon={<span onClick={() => onUntag({ follower: record, tag: tag.parent || tag._id, value: tag._id })} style={{ cursor: "pointer", marginRight: "10px" }}><CloseCircleOutlined /></span>}
                    key={index}
                    style={{ marginBottom: 4 }}
                    color="green">
                    {(() => {
                      if (tag.auto && tag.auto === "ages")
                        return `${tag.name}${name(tag)}`;
                      if (tag.auto && tag.auto === "subscriptionPeriods")
                        return `${tag.name}${name(tag)}`;
                      if (tag.auto)
                        return translate(`default.tag.${tag.value}`, tag.name);
                      return tag.name;
                    })()}
                  </Tag>
                );
            }
          })}
          {record.isTestTarget && <Tag color="orange">{translate("follower.istesttarget", "Test")}</Tag>}
        </div>
      );
    }
  },
  {
    title : tableTitles.isRakutenDate,
    render: (record) => {
      if (record.isRakutenDate){
        return (
          <div>
            {record?.isRakutenDate}
          </div>
        );
      }
      return (<div>-</div>);
    },
  }, {
    title    : tableTitles.shopReviewCount,
    dataIndex: "shopReviewCount"
  }, {
    title    : tableTitles.avgShopScore,
    dataIndex: "avgShopScore"
  }, {
    title    : tableTitles.productReviewCount,
    dataIndex: "productReviewCount"
  }, {
    title    : tableTitles.avgProductScore,
    dataIndex: "avgProductScore"
  }, {
    title    : tableTitles.totalAmount,
    dataIndex: "totalAmount"
  }, {
    title    : tableTitles.totalPurchases,
    dataIndex: "totalPurchases"
  }, {
    title    : tableTitles.averageAmount,
    dataIndex: "averageAmount"
  }, {
    title    : tableTitles.couponUsages,
    dataIndex: "couponUsages"
  }, {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (follower) => {
      return <RowAction actions={{
        orders   : tableTitles.orders,
        reviews  : tableTitles.reviews,
        calculate: tableTitles.calculate,
        tag      : tableTitles.tagged,
      }} onClick={(key) => onAction(key, follower)} />;
    }
  }];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
  .range-field {
    align-items: center;
  }
`;

const UserCount = styled.div`
  margin-top: 10px;
  .card-wrapper {
    background-color: ${colors.primaryDark}; /* Set the background color to green */
    border: solid 1px rgb(247 247 249);
    width: 250px;
    height: 33px;
    display: flex;
    border-radius: 5px;
  }
  
  .card-icon {
    border-right: solid 1px;
    width: 40px;
    background-color: ${colors.primaryDark};
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 35px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  
  .card-image {
    fill: white; 
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
    color: white;
  }
`;
