/* eslint-disable no-lone-blocks */
import React from "react";
import styled from "styled-components";
import { Checkbox, FormItem, Input } from "formik-antd";
import { ACTION_INIT } from "./Form";
import { Select } from "antd";
import { useSelector } from "react-redux";
import EmojiTextField from "../../../components/Message/components/EmojiTextField";

export default (props)=> {
  const { keyIndex, name, values, setFieldValue, imageMapActions, translate, renderField, setValues, setFieldTouched } = props;
  const { Option } = Select;
  const { account } = useSelector(state => state.general);

  const formTailLayout = {
    labelCol: { span: 24 },
    // wrapperRow: { span: 24 },
  };


  return (
    <>
      <ActionFieldStyle>
        <div className="container">
          <Checkbox disabled={true} name={`${name}.actions[${0}].isCheck`}/>
          <FormItem label={translate("carousel.action.1", "Action 1")} name={`${name}.actions[${0}].type` }>
            <Select value={values.columns[keyIndex].actions[0].type} name={`${name}.actions[${0}].type`} placeholder={translate("carousel.action.select", "Select action")} onChange={(e)=> {
              setFieldValue(`${name}.actions[${0}]`, {
                ...(values.columns[keyIndex]&&values.columns[keyIndex].actions[0]?values.columns[keyIndex].actions[0]:{}),
                type               : e,
                data               : undefined,
                linkUri            : undefined,
                text               : undefined,
                cooperation        : undefined,
                additionalParameter: undefined,
                externalBrowser    : false,
                isTagged           : false,
                taggedUrl          : undefined,
                tagType            : 0,
                couponCode         : undefined,
                lineBasicId        : e === "share" ? account?.lineBasicId: undefined
              });
            }}>
              {imageMapActions&&imageMapActions.map((type, index2) => {
                if (type.code !=="no_action"){
                  return <Option key={index2} value={type.code}>{translate(`richmenu.action.${type.code}`, type.name)}</Option>;
                }
                return "";
              })}
            </Select>
          </FormItem>
        </div>

        <FormItem name={`${name}.actions[${0}].label`} label={translate("carousel.action.label", "Action label")}>
          {/* <Input maxLength={20} disabled={false} className="action-label" name={`${name}.actions[${0}].label`} placeholder={translate("carousel.action.label", "Action label")}/> */}
          <EmojiTextField text={values.columns[keyIndex].actions[0].label} setFieldValue={setFieldValue} maxLength={20} disabled={false} className="action-label" name={`${name}.actions[${0}].label`} placeholder={translate("carousel.action.label", "Action label")} />
        </FormItem>
        {renderField(values.columns[keyIndex].actions[0], keyIndex, 0, values.columns[keyIndex], setFieldValue, setFieldTouched)}
        {
          values.columns[keyIndex].actions[1] && values.type === "PRODUCT"? (
            <>
              <div className="container">
                <Checkbox name={"isCheck"} onChange={e=> {
                  values.columns.forEach((item, index) =>{
                    setFieldValue(`columns[${index}].actions[${1}].isCheck`, e.target.checked);
                  });
                  if (e.target.checked === false){
                    values.columns.forEach((item, index) =>{
                      setFieldValue(`columns[${index}].actions[${1}]`, { ...ACTION_INIT, key: e.target.checked });
                    });
                  }

                }}/>
                <FormItem {...formTailLayout} label={`${translate("carousel.action.2", "Action 2")} \xa0\xa0 ${translate("carousel.action.explain", "※Action 2 is valid or invalid for all cards.")}`} name={`${name}.actions[${1}].type`}>
                  <Select value={values.columns[keyIndex].actions[1].type} disabled={!values.columns[keyIndex]?.actions[1]?.isCheck} name={`${name}.actions[${1}].type`} placeholder={translate("carousel.action.select", "Select action")} onChange={(e)=> {
                    setFieldValue(`${name}.actions[${1}]`, {
                      ...(values.columns[keyIndex]&&values.columns[keyIndex].actions[1]?values.columns[keyIndex].actions[1]:{}),
                      type               : e,
                      data               : undefined,
                      linkUri            : undefined,
                      text               : undefined,
                      cooperation        : undefined,
                      isTagged           : false,
                      taggedUrl          : undefined,
                      additionalParameter: undefined,
                      externalBrowser    : false,
                      tagType            : 0,
                      couponCode         : undefined,
                      lineBasicId        : e === "share" ? account?.lineBasicId: undefined
                    });
                  }
                  }>
                    {imageMapActions&&imageMapActions.map((type, index2) => {
                      if (type.code !=="no_action"){
                        return <Option key={index2} value={type.code}>{translate(`richmenu.action.${type.code}`, type.name)}</Option>;
                      }
                      return "";
                    })}
                  </Select>
                </FormItem>
              </div>
              <FormItem name={`${name}.actions[${1}].label`} label={`${translate("carousel.action.label", "Action label")}`} >
                <EmojiTextField text={values.columns[keyIndex].actions[1].label} setFieldValue={setFieldValue} maxLength={20} disabled={false} name={`${name}.actions[${1}].label`} placeholder={translate("carousel.action.label", "Action label")} />
              </FormItem>
              {renderField(values.columns[keyIndex].actions[1], keyIndex, 1, values.columns[keyIndex], setFieldValue, setFieldTouched)}
            </>
          ) : ""
        }
      </ActionFieldStyle>
    </>
  );
};


const ActionFieldStyle = styled.div`
  .ant-col-sm-16 {
      display:block;
      max-width: 100%;
    }
  .ant-select-selection-item {
    padding-left: 20px !important;
  }
  .container {
    position: relative;
  }
  .ant-select-selection-placeholder {
    padding-left:18px !important;
  }
  .ant-select {
    width :463px !important;
  }
  .ant-checkbox-wrapper {
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 9px;
  }
  .ant-select-selector {
    width:467px !important;
  }
`;

