import React from "react";
import * as Yup from "yup";
import { Form as AntForm, Button } from "antd";
import { Formik, useFormikContext } from "formik";
import { useTranslate } from "../../../hooks";
import { formItemLayout } from "../../../utils";
import { Form, FormItem, Input, Select } from "formik-antd";
import { useSelector } from "react-redux";
import { tailFormItemLayout } from "../../../utils";
import styled from "styled-components";
import colors from "../../../colors";

const FormSchema = (translate) => Yup.object({
  name     : Yup.string().required(translate("system.message.required", "This field is required!")),
  eventType: Yup.string().required(translate("system.message.required", "This field is required!"))
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        return false;
      }
      return values;
    }
  }));

  return null;
});

const { Option } = Select;

export default React.forwardRef((props, ref) => {
  const { editable, onCancel, onSubmit, disabled } = props;
  const submitRef = React.useRef();
  const { translate, lang } = useTranslate();
  const { processEvents } = useSelector(state => state.general);
  const [data] = React.useState({
    name     : "",
    eventType: "",
    ...(editable && editable[0] === "update" ? editable[1] : {})
  });

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let valid = await submitRef.current.submitForm();

      if (valid) {
        return {
          ...valid,
          targets: data.targets
        };
      }

      return false;
    }
  }));

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit || (() => {})}>
        {({ isSubmitting }) => {
          if (editable && editable[1] !== "update")
            return (
              <Form layout="inline" className="update">
                <FormItem name="name" required>
                  <Input name="name" placeholder={translate("trigger.create.name", "Action name")} disabled={disabled} />
                </FormItem>
                <FormItem name="eventType" required>
                  <Select name="eventType" placeholder={translate("trigger.create.eventType", "Event type")} style={{ width: "250px" }} disabled={disabled}>
                    {processEvents.map((item, index) => (
                      <Option key={index} value={item.code}>{item[`${lang?.toLowerCase()}`]}</Option>
                    ))}
                  </Select>
                </FormItem>

                <SubmitForm ref={submitRef} translate={translate} />
              </Form>
            );

          return (
            <Form {...formItemLayout}>
              <FormItem label={translate("scenario.settigns.name", "Name")} name="name" required>
                <Input name="name" placeholder={translate("trigger.create.name", "name")} />
              </FormItem>
              <FormItem label={translate("trigger.create.eventType", "Event type")} name="eventType" required>
                <Select name="eventType" placeholder={translate("trigger.create.eventType", "Event type")} style={{ width: "100%" }}>
                  {processEvents.map((item, index) => (
                    <Option key={index} value={item.code}>{item[`${lang?.toLowerCase()}`]}</Option>
                  ))}
                </Select>
              </FormItem>
              <AntForm.Item {...tailFormItemLayout} style={{ marginTop: 20 }}>
                <Button htmlType="submit" type="primary" loading={isSubmitting} style={{ marginRight: 10 }}>{translate("system.save", "Save")}</Button>
                <Button type="default" onClick={onCancel} style={{ color: colors.primary }} >{translate("system.cancel", "Cancel")}</Button>
              </AntForm.Item>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});

const Container = styled.div`
  padding : 10px;
  width: 100%;
  .update  > .ant-form-item-with-help {
    margin-bottom: 0!important;
    .ant-form-item-explain {
      display: none!important;
    }
  }
  .ant-select-selector {
    background: #fff!important;
    color: #333!important;
  }
  .ant-input-disabled {
    background: #fff!important;
    color: #333!important;
  }
`;