import React, { useState } from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import styled from "styled-components";
import { auth, plan } from "../../apis";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { Divider, message, Form as AntForm, Button, Input as InputAnt } from "antd";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import generator from "generate-password";
import { ReloadOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Option } = Select;

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const FormSchema = (translate) => Yup.object().shape({
  companyName: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  name       : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  email      : Yup.string().email(translate("webhook.mail.invalid", "Mail addres is invalid!")).required(translate("system.message.required", "Please fill out this field!")),
  pass       : Yup.string()
    .required(translate("system.message.required", "This field is required!"))
    .matches(lowercaseRegex, translate("system.password.lowercase", "Your password must have at least one lowercase"))
    .matches(uppercaseRegex, translate("system.password.uppercase", "Your password must have at least one uppercase"))
    .matches(numericRegex, translate("system.password.number", "Your password must have at least one number digit"))
    .min(8, translate("system.password.character", "Your password must have at least 8 characters")),
  address      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  phoneNumber  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  businessType : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  staffName    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  staffBranch  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  staffPosition: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  firstLogin   : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  plan         : Yup.string().required(translate("system.message.required", "Please fill out this field!"))
});

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { businessTypes } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [filters, setFilters] = React.useState({
    filter: {
      orderName: "startDate",
      orderBy  : "desc",
      query    : "",
      startDate: "",
      endDate  : "",
    },
    offset: {
      page : 1,
      limit: 30,
    }
  });
  const [planList, setPlanList] = React.useState([]);

  const planInit = async (filter) => {
    let list = await plan.list(filter);
    setPlanList(list.rows);
  };

  const [data] = useState({
    companyName  : undefined,
    email        : undefined,
    name         : undefined,
    pass         : undefined,
    address      : undefined,
    phoneNumber  : undefined,
    businessType : undefined,
    staffName    : undefined,
    staffBranch  : undefined,
    staffPosition: undefined,
    plan         : undefined,
    ecmid        : "",
    ecmaccesskey : "",
    firstLogin   : new Date()
  });

  const onSubmit = async (data) => {
    try {
      await auth.register(data);
      message.success(translate("register.succeed.alert", "Registered succesfully and notification mail has been sent"));
      history.goBack();
    } catch (error) {
      if (error.message === "ALREADY_REGISTERED")
        message.error(translate("signup.warning.registered", "This e-mail address is already registered!"));
    }
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.filter.query !== query){
        setFilters({ ...filters, filter: { query: query } });
        planInit({ ...filters, filter: { query: query } });
      }

    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  React.useEffect(()=> {
    planInit(filters);
  }, []);

  const generatePass = ({ setFieldValue }) => {
    let password = generator.generate({
      length                  : 8,
      numbers                 : true,
      strict                  : true,
      excludeSimilarCharacters: true
    });
    setFieldValue("pass", password);
    return password;
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("navbar.permission", "New user register")} />
      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>

            <Formik
              initialValues={data}
              validationSchema={() => FormSchema(translate)}
              onSubmit={onSubmit}>
              {({ isSubmitting, values, setFieldValue }) => (
                <Form layout="vertical">
                  <Divider>{translate("useredit.company", "Company Information")}</Divider>
                  <FormItem label={translate("useredit.companyname", "Company name")} name="companyName" required>
                    <Input name="companyName" placeholder={translate("useredit.companyname", "Company name")} />
                  </FormItem>
                  <FormItem label={translate("useredit.name", "Name")} name="name" required>
                    <Input name="name" placeholder={translate("useredit.name", "Name")} />
                  </FormItem>
                  <FormItem label={translate("useredit.address", "Address")} name="address" required>
                    <TextArea name="address" maxLength={500} showCount placeholder={translate("useredit.address", "Address")} />
                  </FormItem>
                  <FormItem label={translate("useredit.phone", "Phone")} name="phoneNumber" required>
                    <Input name="phoneNumber" placeholder={translate("useredit.phone", "Phone")} />
                  </FormItem>
                  <FormItem label={translate("forgot.email", "E-mail")} name="email" required>
                    <Input name="email" type="email" label={translate("forgot.email", "E-mail")} placeholder={translate("forgot.email", "E-mail")}/>
                  </FormItem>
                  <FormItem label={translate("useredit.business", "Business Type")} name="businessType" required>
                    <Select name="businessType" placeholder={translate("useredit.business", "Business Type")}>
                      {businessTypes.map(type => {
                        return <Option key={type._id} value={type.code}>{type.name}</Option>;
                      })}
                    </Select>
                  </FormItem>
                  {process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <FormItem label={translate("useredit.ecm.id", "ECM ID")} name="ecmid">
                    <Input name="ecmid" placeholder={translate("useredit.ecm.id", "ECM ID")}/>
                  </FormItem>}
                  {process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <FormItem label={translate("useredit.ecm.accesskey", "ECM Accesskey")} name="ecmaccesskey">
                    <Input name="ecmaccesskey" placeholder={translate("useredit.ecm.accesskey", "ECM Accesskey")}/>
                  </FormItem>}
                  <Divider>{translate("useredit.contact", "Contact Information")}</Divider>
                  <FormItem label={translate("useredit.name", "Name")} name="staffName" required>
                    <Input name="staffName" placeholder={translate("useredit.name", "Name")} />
                  </FormItem>
                  <FormItem label={translate("useredit.branch", "Name of Branch office")} name="staffBranch" required>
                    <Input name="staffBranch" placeholder={translate("useredit.branch", "Name of Branch office")} />
                  </FormItem>
                  <FormItem label={translate("useredit.position", "Position held")} name="staffPosition" required>
                    <Input name="staffPosition" placeholder={translate("useredit.position_placeholder", "Position held")}/>
                  </FormItem>
                  <FormItem label={translate("useredit.password.input", "Password")} name="pass" required>
                    <Input type="text" autocomplete="off" name="pass" placeholder={translate("useredit.password.input", "Password")} prefix={<div onClick={() =>generatePass({ setFieldValue })} style={{ cursor: "pointer" }} ><ReloadOutlined /></div>} />
                  </FormItem>
                  <FormItem label={translate("useredit.password.plan", "Plan")} name="plan" required>
                    <Select
                      onBlur={()=> planInit({ ...filters, filter: { query: "" } })}
                      filterOption={false}
                      name="plan"
                      onSearch={(value)=> {
                        setQuery(value);
                      }}
                      showSearch
                      style={{ width: "100%" }}
                      placeholder={translate("useredit.password.plan", "Plan")}
                      optionLabelProp="label"
                      allowClear
                    >
                      {planList.map(item => {
                        return (
                          <Option key={item._id} value={item._id} label={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </FormItem>

                  <AntForm.Item>
                    <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.save", "Save")}</Button>
                  </AntForm.Item>
                </Form>
              )}
            </Formik>

          </FormWrapper>
        </div>
      </PageContent>
    </PageContainer>
  );

};

const FormWrapper = styled.div`
  width: 600px;
`;