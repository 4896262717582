import React, { useRef } from "react";
import { FormItem, Input } from "formik-antd";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import { Button, message } from "antd";
import { Picker } from "emoji-mart";

export default ({ name, text="", placeholder, setFieldValue, disabled, maxLength=500 }) => {
  const { translate } = useTranslate();
  const [emojiVisible, setEmojiVisible] = React.useState("none");
  const inputRef = useRef(null);
  const cursorPosition = useRef(0);

  const emojiWindow = () => {
    if (emojiVisible === "none") {
      setEmojiVisible("");
    } else {
      setEmojiVisible("none");
    }
  };

  const setCursorPosition = (position) => {
    cursorPosition.current = position;
  };

  return (
    <EmojiTextFieldWrapper>
      <div className="input">
        <Input
          value={text}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          onChange={(e) => {
            setFieldValue(e.target.value);
          }}
          onClick={(e) => {
            console.log("target:", e.target.selectionStart);
            setCursorPosition(e.target.selectionStart);
          }}
          onKeyUp={(e) => setCursorPosition(e.target.selectionStart)}
          ref={(input) => {
            inputRef.current = input;
          }}
        />
        <Button disabled={disabled} onClick={emojiWindow}>
          {translate("message.emoji", "Emoji")}
        </Button>
      </div>
      <EmojiPickerWrapper style={{ display: emojiVisible }}>
        <EmojiPicker
          style={{ width: "100%" }}
          showPreview={false}
          onSelect={(emoji) => {
            if (text.length >= maxLength){
              message.error(`${translate("emoji.text.field.error", "Max length is exceeded")} ${maxLength}`);
              return;
            }

            const newText = text.slice(0, cursorPosition.current) + (emoji === "☹️" ? "🙁" : emoji.native) + text.slice(cursorPosition.current);

            console.log(newText);
            setFieldValue(name, newText);

            const newPosition = cursorPosition.current + emoji.native.length;
            setCursorPosition(newPosition);

            setTimeout(() => {
              if (inputRef.current) {
                inputRef.current.setSelectionRange(newPosition, newPosition);
                inputRef.current.focus();
              }
            }, 0);
          }}
        />
      </EmojiPickerWrapper>
    </EmojiTextFieldWrapper>
  );
};

const EmojiPickerWrapper = styled.div`
  aside.emoji-picker-react {
    width: 100% !important;
    height: 300px !important;
  }
`;

const EmojiTextFieldWrapper = styled.div`
    display : flex;
    flex-direction: column;
    gap:5px;
    .ant-form-item {
      margin-bottom: 0;
    }
    .input {
    display : flex;
    justify-content: left;
    gap:5px;
    }

    `;

const EmojiPicker = styled(Picker)`
section {
  .emoji-mart-bar {
    display :none;
  }
  .emoji-mart-category-list {
    button[aria-label="☹️, white_frowning_face"] {
      display: none!important;
    }
  }
}
`;