import React from "react";
import { Form, Input, FormItem, Select, Checkbox } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Button } from "antd";
import * as Yup from "yup";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../hooks";
import colors from "../../../colors";
import { useHistory } from "react-router-dom";
import EcLogo from "../../../assets/ec-logo.png";

const { Option } = Select;
const { TextArea } = Input;

const FormSchema = (translate) => Yup.object().shape({
  companyName  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  address      : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  phoneNumber  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  businessType : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  staffBranch  : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  staffPosition: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  staffName    : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
});

export default ({ action, onSubmit, setEcmaccesskey, onEcmDisconnect }) => {
  const { translate } = useTranslate();
  const { businessTypes, user, customer } = useSelector(state => state.general);
  const [disabled, setDisabled] = React.useState(false);
  const history = useHistory();
  const [data] = React.useState({
    companyName  : undefined,
    address      : undefined,
    phoneNumber  : undefined,
    businessType : undefined,
    staffName    : undefined,
    staffBranch  : undefined,
    staffPosition: undefined,
    email        : undefined,
    active       : undefined,
    paymentBlock : undefined,
    ecmaccesskey : undefined,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  // !customer.ecmaccesskey &&

  const changeEmail = () => {
    history.push({ pathname: "/customer/email/change", state: { email: data.email } });
  };

  React.useEffect(()=> {
    console.log(!!customer?.ecmaccesskey);
    setDisabled(!!customer?.ecmaccesskey);
  }, [customer?.ecmaccesskey]);

  return (
    <Formik
      initialValues={data}
      validationSchema={()=> FormSchema(translate)}
      validate={(data) => {
        if (data.password && data.password && data.password !== data.confirmPassword)
          return {
            confirmPassword: translate("system.password.nomatch", "Password doesn't match!")
          };
      }}
      onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <Divider>{translate("useredit.company", "Company Information")}</Divider>
          { !!customer?.ecmaccesskey === true&& (
            <div>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "15px", color: "gray" }}>
                {translate("useredit.ecm.alert", "To change the settings, please change the settings from EC Masters Club.")}
                <a href={"https://club.ec-masters.net/"} target="_blank">
                  <img width={"100%"} src={EcLogo} style={{ width: "111px", marginRight: "16px" }}/>
                </a>
              </div>
            </div>)
          }
          {process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && (
            <FormItem label={translate("ecm.accesskey", "ECM accesskey")} name="email" required>
              <Input
                disabled
                name="ecmaccesskey"
                type="text"
                placeholder={translate("ecm.accesskey", "ECM accesskey")}
                suffix={
                  <div style= {{ display: "flex", gap: "15px" }}>
                    <Button size="small" onClick={()=>setEcmaccesskey(true)}>
                      {customer?.ecmaccesskey ? translate("system.edit", "Edit") : translate("ecm.customer.insert.accesskey", "Connect")}
                    </Button>
                    <Button disabled={!customer?.ecmaccesskey} size="small" onClick={() => onEcmDisconnect({ type: "CUSTOMER", id: customer._id })}>
                      {translate("user.edit.ecm.disconnect", "Delete")}
                    </Button>
                  </div>
                }
              />
            </FormItem>)
          }

          <FormItem label={translate("useredit.companyname", "Company name")} name="companyName" required>
            <Input disabled={disabled} name="companyName" placeholder={translate("useredit.companyname", "Company name")} />
          </FormItem>
          <FormItem label={translate("useredit.address", "Address")} name="address" required>
            <TextArea disabled={disabled} name="address" placeholder={translate("useredit.address", "Address")} />
          </FormItem>
          <FormItem label={translate("useredit.phone", "Phone")} name="phoneNumber" required>
            <Input disabled={disabled} name="phoneNumber" placeholder={translate("useredit.phone", "Phone")} />
          </FormItem>
          <FormItem label={translate("useredit.business", "Business Type")} name="businessType" required>
            <Select disabled={disabled} name="businessType" placeholder={translate("useredit.business", "Business Type")}>
              {businessTypes.map(type => {
                return <Option key={type._id} value={type.code}>{type.name}</Option>;
              })}
            </Select>
          </FormItem>
          <Divider>{translate("useredit.contact", "Contact Information")}</Divider>
          <FormItem label={translate("useredit.name", "Name")} name="staffName" required>
            <Input disabled={disabled} name="staffName" placeholder={translate("useredit.name", "Name")} />
          </FormItem>
          <FormItem label={translate("useredit.branch", "Name of Branch office")} name="staffBranch" required>
            <Input disabled={disabled} name="staffBranch" placeholder={translate("useredit.branch", "Name of Branch office")} />
          </FormItem>
          <FormItem label={translate("useredit.position", "Position held")} name="staffPosition" required>
            <Input disabled={disabled} name="staffPosition" placeholder={translate("useredit.position_placeholder", "Position held")}/>
          </FormItem>
          <Divider>{translate("useredit.login.info", "Login information")}</Divider>
          <FormItem label={translate("useredit.login.id", "Login ID")} name="email" required>
            <Input
              disabled
              name="email"
              type="email"
              placeholder={translate("useredit.login.id", "Login ID")}
              suffix={
                <Button size="small" onClick={changeEmail}>
                  {translate("system.edit", "Edit")}
                </Button>
              }
            />
          </FormItem>
          {data.active === true ?
            <div style={{ color: "green", marginBottom: "10px" }}>{translate("useredit.active", "This account is active")}</div> :
            <div style={{ color: "red", marginBottom: "10px" }}>{translate("useredit.disactive", "This account is inactive")}</div>
          }
          {user.role === "ADMIN" &&
            <FormItem name="active" label="" required>
              <SwitchStyle>
                <Checkbox disabled={disabled} name="active">{translate("accounts.status", "Enable/Disable this account")}</Checkbox>
              </SwitchStyle>
            </FormItem>
          }

          <AntForm.Item>
            <Button disabled={disabled} htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.save", "Save")}</Button>
          </AntForm.Item>
        </Form>
      )}

    </Formik>
  );
};

const Divider = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
  background: #e7e7e7;
  padding: 4px 8px;
  border-radius: 2px;
  color: ${colors.primaryDark};
`;

const SwitchStyle = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color : ${colors.primaryDark};
  }
  .ant-checkbox-inner::hover {
    border-color : yellow;
  }
`;