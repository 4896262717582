import React from "react";
import { Form, FormItem, Input, } from "formik-antd";
import { Formik } from "formik";
import { message } from "antd";
import * as Yup from "yup";
import { Button } from "../../../components/Design";
import { usePermission, useTranslate } from "../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { account as accountApi, auth } from "../../../apis";
import styled from "styled-components";

const FormSchema =(translate) => Yup.object({
  shopName: Yup.string().required(translate("system.message.required", "This field is required!")),
});

export default () => {
  const { translate } = useTranslate();
  const { account } = useSelector(state => state.general);
  const [load, setLoad] = React.useState(false);
  const dispatch = useDispatch();
  const submitRef = React.useRef();
  const { checkRole } = usePermission();

  const [data, setData] = React.useState({
    shopName: account?.shopName,
    account : account?._id
  });

  React.useEffect(()=> {
    setLoad(true);
    setData({
      shopName: account?.shopName,
      account : account?._id
    });
    setLoad(false);
  }, [account]);

  const onSubmit = async (values) => {
    setLoad(true);
    try {
      await accountApi.shopname(values);
      await auth.me()(dispatch);
      message.success(translate("system.message.success", "Success!"));
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoad(false);
    }
  };

  const onRakutenSet = async () => {
    setLoad(true);
    try {
      await accountApi.shopname({
        ...data,
        useRakuten: true
      });
      await auth.me()(dispatch);
      message.success(translate("system.message.success", "Success!"));
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoad(false);
    }
  };

  return (
    <RakutenContent>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {() => {
          return (
            <Form ref={submitRef} layout="vertical" autocomplete="off">
              <div className="header">
                <div>{translate("delivery.message.shopname.title", "Shop name")}</div>
                {checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"])?.permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"]) && <div className="header" style={{ gap: 10 }}>
                  <Button loading={load} key="new" onClick={onRakutenSet} type="secondary">{translate("account.shopname.userakuten", "Rakuten name")}</Button>
                  <Button htmlType="submit" loading={load} key="new" type="primary">{translate("system.save", "Save")}</Button>
                </div>}
              </div>
              <FormItem style={{ display: "flex", justifyContent: "start", gap: 5 }} name="shopName" required>
                <Input name="shopName" placeholder={translate("delivery.message.shopname.placeholder", "Shop name")} />
              </FormItem>
            </Form>
          );
        }}
      </Formik>
    </RakutenContent>
  );
};

const RakutenContent = styled.div`
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
  background: #fcfcfc;
  margin: 0 16px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
  }
`;