import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { Alert, message } from "antd";
import Form from "./Form";
import { useTranslate } from "../../hooks";
import { AccountTemplate } from "./Edit";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const { user, accounts } = useSelector(state => state.general);

  // if (user.balance&&user.balance.account <= 0) {
  //   return (
  //     <Redirect to="/"/>
  //   );
  // }


  const onBack = () => {
    // if (accounts.length === 0) {
    //   message.error(translate("line.alert", "You have to connect at least one Line official account"));
    // }
    // else {
    history.push("/");
    // }
  };

  // React.useEffect(() => {
  //   if (accounts.length === 0) {
  //     message.warning(translate("line.alert", "You have to connect at least one Line official account"));
  //   }
  // }, []);

  return (
    <PageContainer>
      <PageHeader title={translate("line.connect", "Connect Line official account")} onBack={() => onBack()} />

      <PageContent>
        <AccountTemplate/>
        <Form />
      </PageContent>
    </PageContainer>
  );
};
