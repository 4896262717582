import styled from "styled-components";
import { Button } from "antd";
import colors from "../../colors";

export default styled(Button)`
  &.ant-btn-primary {
    background    : ${colors.primaryDark} ;
    border-color  : white;
  }
  &.ant-btn-primary:focus {
    color         : white;
    background    : ${colors.primaryDark};
    opacity: 90%;
  }
  &.ant-btn-primary:hover {
    background : ${colors.primaryDark} ;
    border-color : white;
    opacity: 90%;
  }
  &.ant-btn-default {
    border-color  : ${colors.primaryDark} ;
  }
  &.ant-btn-default:focus {
    color         : ${colors.primaryDark};
  }
  &.ant-btn-default:hover{
    color : ${colors.primaryDark};
    border-color :${colors.primary}
  }
  &.ant-btn-dangerous:enabled {
    color: #fff;
    background: ${colors.danger};
  }
  &.ant-btn-dangerous:focus {
    color: #fff;
    background: ${colors.dangerDark};
  }
  &.ant-btn-dangerous:hover {
    color: ${colors.danger};
    background: #fff;
    border-color: ${colors.danger};
  }
`;