/* eslint-disable no-fallthrough */
import React from "react";
import { Empty, Space, Spin, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, DatePicker, Table, message } from "antd";
import { customer, auth, agency as agencyApi, general } from "../../apis";
import styled from "styled-components";
import colors from "../../colors";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import moment from "moment";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useDispatch, useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import InviteForm from "./components/InviteForm";
import confirm from "antd/lib/modal/confirm";

const { RangePicker } = DatePicker;

export default () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const myTableRef = React.useRef();
  const { user } = useSelector(state => state.general);
  const [query, setQuery] = React.useState("");
  const [accountName, setAccountName] = React.useState("");
  const [inviteModal, setInviteModal] = React.useState(false);
  const [fileExportVisible, setFileExportVisible] = React.useState(false);
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });
  const [filters, setFilters] = React.useState({
    query      : "",
    accountName: "",
    startDate  : null,
    endDate    : null,
  });

  const changeRange = (e) => {
    setFilters({ ...filters, ...filters.filter, startDate: e && moment(new Date(e[0])).startOf("day").toDate(), endDate: e && moment(new Date(e[1])).endOf("day").toDate() });
    setRangePicker({ startDate: e && e[0], endDate: e && e[1] });
  };

  const location = {
    pathname: "/",
    state   : { fromDashboard: true }
  };

  const columns = useHeader({
    tableTitles: {
      no            : translate("system.number", "No."),
      name          : translate("useraccount.company", "Company name"),
      accounts      : translate("useraccount.accounts", "Accounts"),
      representative: translate("useraccount.representative", "	Representative"),
      responsible   : translate("useraccount.responsible", "Responsible"),
      date          : translate("useraccount.registereddate", "Register date"),
      plan          : translate("useraccount.plan", "Plan"),
      action        : translate("system.action", "Action"),
      edit          : translate("system.edit", "Edit"),
      login         : translate("system.login", "Login"),
      blocked       : translate("useredit.block", "Blocked"),
      ecmadd        : translate("ecm.add.action", "ECM connect"),
      addrakuline   : translate("rakuraku.add.action", "Rakuraku connect"),
    },
    history,
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/customer/${item._id}`);
          break;
        }
        case "addecm": {
          history.push(`/customer/ecm/${item._id}`);
          break;
        }
        case "addrakuline": {
          history.push(`/customer/rakuline/${item._id}`);
          break;
        }
        case "login": {
          dispatch({ type: "main.load", payload: true });
          try {
            await customer.change(item._id);
            Promise.all([general.init()(dispatch), auth.me()(dispatch)]);
            message.success(translate("system.message.success", "Success"));

            dispatch({ type: "main.load", payload: false });
            dispatch({ type: "balance.notfication", payload: true });
            history.replace(location);
            window.location.reload();

            break;

          } catch (error) {
            message.error(translate("system.alert.disabled", "Account has been disabled"));
          }
        }
        default:
      }
    },
  });

  const addCustomer = () => {
    history.push("/admin/customer/add");
  };

  const registerCustomer = () => {
    history.push("/admin/customer/register");
  };

  const inviteSubmit =async (data)=> {
    await agencyApi.invite(data);
    message.success(translate("asdasd", "Sent to email"));
    setInviteModal(false);
  };
  const exportDone = () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("file.export.request.accepted", "File export request has been accepted?"),
      okText    : translate("file.list.title", "Go to exported files"),
      cancelText: translate("file.export.ok", "Ok"),
      async onOk() {
        history.push("/admin_file");
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };
  const fileExport = async () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("file.export.request", "Would like to start exporting data to excel file?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        try {
          await customer.exportFile({ filter: filters });
          exportDone();
        } catch (err) {
          if (err.message === "FILE_PROCESSING"){
            message.error(translate("file.export.error.processing", "File is in process. Please wait until current export process is over!"));
          }
        }
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.accountName !== accountName)
        setFilters({ ...filters, accountName });
    }, 300);

    return () => clearTimeout(timer);
  }, [accountName]);

  return (
    <PageContainer>
      <PageHeader title={translate("adminmenu.representativelogin", "Account management")} extra={[
        <span>
          {user.role === "ADMIN" && !user.customer &&
          (<Button loading={fileExportVisible} onClick={fileExport} type="primary">{translate("payment.file.export", "File export")}</Button>)
          }
        </span>,
        <span>
          {user.role === "ADMIN" && !user.customer &&
          (<Button loading={fileExportVisible} onClick={registerCustomer} type="primary">{translate("system.add.user", "Register")}</Button>)
          }
        </span>,
        user.role === "AGENCY"&& (<Button key="createButton" type="primary" onClick={()=>setInviteModal(true)} ><PlusOutlined /> {translate("system.add", "Invite customer")} </Button>),
        <Button key="inviteButton" type="primary" onClick={addCustomer} ><PlusOutlined /> {translate("system.add", "New user")} </Button>
      ]} />
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
        </Space>
        <Space>
          <Input.Group compact>
            <Input
              name="accountName"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setAccountName(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
        </Space>
        <Space>
          <RangePicker allowClear={false} value={[rangePicker.startDate, rangePicker.endDate]} onChange={(e) => changeRange(e)} />
          <Button type="default" onClick={() => setFilters({ ...filters, startDate: null, endDate: null })}>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          maskClosable={false}
          filters={filters}
          ref={myTableRef}
          rowKey={(record) => record._id}
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          columns={columns}
          loadData={customer.list}
        />
      </PageContent>
      <Modal
        onCancel={()=>setInviteModal(false)}
        title={translate("agency.invite.customer", "Invite customer")}
        visible={inviteModal}
        footer={null}>
        <InviteForm onSubmit={inviteSubmit}/>
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles }) => {
  return [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    },
    {
      title : tableTitles.name,
      render: (item) => <div>{item.active ? item.companyName : `*${item.companyName}` }</div>
    },
    {
      title : tableTitles.accounts,
      render: (item) => {
        return <div>
          {item.accounts.map((account, index) => <Tag key={index} style={{ marginBottom: 4 }}>{account.displayName}</Tag>)}
        </div>;
      }
    },
    {
      title : tableTitles.representative,
      render: (item) => {
        if (item.staffName === undefined) { return (<div>-</div>); }
        return (<div >{item.staffName}</div>);
      }
    },
    {
      title : tableTitles.responsible,
      render: (item) => {
        if (item.agencyUser === undefined) { return (<div>-</div>); }
        return (<div >{item.agencyUser}</div>);
      }
    },
    {
      title : tableTitles.date,
      render: (item) => { return (<div>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>); }
    },
    {
      title : tableTitles.plan,
      render: (item) => {
        if (item.plan === undefined) { return (<div>-</div>); }
        return (<div >{item.plan && item.plan.name}</div>);
      }
    },
    // {
    //   title: tableTitles.blocked,
    //   render: (item) => {
    //     return (<div>{item.user.passwordBlocked === false ?
    //       <div>-</div>
    //       : <span><Tag color="red">{tableTitles.blocked}</Tag></span>}</div>);
    //   }
    // },
    {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (record) => {
        const actions = {
          edit : tableTitles.edit,
          login: tableTitles.login,
        };
        if (process.env.REACT_APP_WEBSITE_MODE === "lseg"){
          actions.addecm = tableTitles.ecmadd;
        }
        // if (process.env.REACT_APP_WEBSITE_MODE === "rakuline"){
          actions.addrakuline = tableTitles.addrakuline;
        // }
        return <RowAction actions={actions} onClick={(key) => onAction(key, record)} />;
      }
    }
  ];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .item1 {
    width: 70%;
  }
  .item2 {
    width: 30%;
  }
  .button-primary:hover {
    background : ${colors.primary} ;
    border-color : white;
    color        : white;
  }
  .button-primary {
    background    : ${colors.primaryDark} ;
    border-color  : white;
    color         : white;
  }
`;
