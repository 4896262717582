
import React from "react";
import { Empty, message, Modal, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { plan } from "../../apis";
import { MyTable, RowAction } from "../../components";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./components/PlanForm";
import { Button } from "../../components/Design";
import { useTranslate } from "../../hooks";
import colors from "../../colors";

const { confirm } = Modal;

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const myTableRef = React.useRef(null);
  const [action, setAction] = React.useState(null);

  const onSubmit = async (data) => {
    let res;
    if (data.type === "FREE")
      data.amount = 0;
    try {
      if (action[0] === "create") {
        res = await plan.create(data);
      } else {
        res = await plan.update(data);
      }
      message.success(translate("system.message.success", "Successful"));
      myTableRef.current.reload();
      onCancel(true);
    } catch (err) {
      console.log(err);
      if (err.message === "ECM_LEVELUSED"){
        message.error(translate("plan.ecmlevel.error", "Ecm level has been set at another plan"));
      } else {
        message.error(translate("system.error", "Error"));
      }
    }

  };

  const onCancel = async () => {
    setAction(false);
  };

  const columns = useHeader({
    tableTitles: {
      no           : translate("system.number", "No."),
      name         : translate("plan.name", "Plan name"),
      description  : translate("plan.description", "	Description"),
      unitPrice    : translate("plan.additional", "Unit price"),
      amount       : translate("plan.price", "Price"),
      MessageLimit : translate("plan.message.limit", "Message limit"),
      account      : translate("plan.acc.limit", "Account connecton limit"),
      follower     : translate("plan.follower.limit", "Follower limit"),
      ecmLevel     : translate("plan.ecmlevel", "Ecm level"),
      rakurakuLevel: translate("plan.rakurakulevel", "Rakuraku level"),
      status       : translate("plan.payment.status", "Status"),
      action       : translate("system.action", "Action"),
      edit         : translate("system.edit", "Edit"),
      remove       : translate("system.delete", "Remove"),
      disabled     : translate("system.disabled", "Disabled"),
      enabled      : translate("system.enabled", "Enabled")
    },
    history,
    onAction: async (key, item) => {
      switch (key) {
        case "edit": {
          let res = await plan.get(item._id);
          setAction(["update", item]);
          break;
        }

        case "remove": {
          confirm({
            icon      : <ExclamationCircleOutlined />,
            content   : translate("system.confirmation.question", "Are you sure ?"),
            okText    : translate("system.yes", "Yes"),
            cancelText: translate("system.no", "No"),
            async onOk() {
              await plan.remove(item._id);
              message.success(translate("system.message.success", "Successful"));
              myTableRef.current.reload();
            },
            okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark } }
          });
          break;
        }
        default:
      }
    },
    translate
  });

  return (
    <PageContainer>
      <PageHeader title={translate("adminmenu.feesettings", "Fee settings")} extra={[
        <Button key={1} type="primary" onClick={() => setAction(["create"])} icon={<PlusOutlined />}>{translate("plan.add", "Add plan")}</Button>
      ]} />

      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={plan.list}
        />
      </PageContent>

      <Modal
        width={"790px"}
        maskClosable={false}
        title={action && action[0] === "create" ? translate("plan.add", "Create new plan") : translate("plan.add", "Create new plan")}
        visible={!!action}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate }) => {
  const columns = [
    {
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title    : tableTitles.name,
      dataIndex: "name"
    }, {
      title    : tableTitles.description,
      dataIndex: "desc"
    }, {
      title : tableTitles.unitPrice,
      render: (data) => {
        if (data.unitPrice > 0){
          return (<div>{data.unitPrice && data.unitPrice.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</div>);
        }
        return (<div>{translate("plan.additionalMessage.false", "Additional message disabled")}</div>);
      }
    }, {
      title : tableTitles.amount,
      render: (data) => (<div>{data.amount && data.amount.toLocaleString("ja-JP", { style: "currency", currency: "JPY" })}</div>)
    }, {
      title : tableTitles.MessageLimit,
      render: (item)=> {
        return (<div>{item.messageLimit === -1 ? translate("system.unlimited", "Unlimited") : item.messageLimit}</div>);
      }
    }, {
      title : tableTitles.account,
      render: (record) => (record.accountLimit === -1 ? translate("system.unlimited", "Unlimited") : record.accountLimit)
    }, {
      title : tableTitles.follower,
      render: (record) => (record.followerLimit === -1 ? translate("system.unlimited", "Unlimited") : record.followerLimit)
    }, {
      title : process.env.REACT_APP_WEBSITE_MODE === "rakuline" ? tableTitles.rakurakuLevel: tableTitles.ecmLevel,
      render: (record) => (
        process.env.REACT_APP_WEBSITE_MODE === "rakuline" ? record.rakurakuLevel || "-": record.ecmLevel || "-"
      )
    },
  ];
  if (process.env.REACT_APP_WEBSITE_MODE !== "rakuline"){
    columns.push({
      title : tableTitles.status,
      render: (item)=>{
        return (<div>{item.active === false ? <span ><Tag color="error">{tableTitles.disabled}</Tag></span> : <span style={{ color: colors.primaryDark }}><Tag style={{ color: colors.primaryDark }} color="success">{tableTitles.enabled}</Tag></span>}</div>);
      }
    });
  }
  columns.push({
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (record) => {
      return <RowAction actions={{
        edit  : tableTitles.edit,
        remove: tableTitles.remove,
      }} onClick={(key) => onAction(key, record)} />;
    }
  });
  return columns;
};


