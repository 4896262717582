import React from "react";
import Form from "./Form";
import { Button, message, Modal, Spin, Tag } from "antd";
import { usePermission, useTranslate } from "../../hooks";
import { rakuten, auth, account as accountApi } from "../../apis";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import colors from "../../colors";
import Check from "./Check";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import EcmAccessKeyForm from "../Admin/components/EcmAccessKeyForm";
import { ManualLink } from "../../components/Manual";

export default ()=>{
  const { translate } = useTranslate();
  const submitRef = React.useRef();
  const dispatch = useDispatch();
  const { checkRole } = usePermission();
  const [data, setData] = React.useState({});
  const [action, setAction] = React.useState();
  const [load, setLoad] = React.useState(false);
  const [ecmaccesskey, setEcmaccesskey] = React.useState(false);
  const { account } = useSelector(state => state.general);
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  const onSubmit = async ()=> {
    setLoad(true);
    let connect = await submitRef.current.validate();
    if (!connect) {
      setLoad(false);
      return ;
    }
    try {
      await rakuten.connective(connect.id, {
        rakutenLicenseKey   : connect.rakutenLicenseKey?.trim(),
        rakutenServiceSecret: connect.rakutenServiceSecret?.trim(),
        rakutenShopName     : connect.rakutenShopName?.trim(),
        rakutenShopPass     : connect.rakutenShopPass?.trim(),
        rakutenUserName     : connect.rakutenUserName?.trim(),
        rakutenUserPass     : connect.rakutenUserPass?.trim()
      });
      await auth.me()(dispatch);
      message.success(translate("system.message.success", "Success!"));
      setLoad(false);

    }
    catch (err){
      if (err?.message === "RAKUTEN_SERVICE_SECRET_USED"){
        message.error(translate("rakuten.connection.alert.key", "Your Secret Key for rakuten API is already in use !"));
      }
      if (err?.message === "RAKUTEN_LOGIN_USER_USED"){
        message.error(translate("rakuten.connection.alert.user", "Rakuten user is already in use !"));
      }
      if (err?.message === "RAKUTEN_UNAUTHORIZED"){
        message.error(translate("rakuten.connection.alert.unauthoried", "Rakuten Service secret or License key is invalid !"));
      }
      if (err?.message === "RAKUTEN_LICENSEKEY_CHECK_API_INVALID"){
        message.error(translate("rakuten.connection.alert.invalidapi", "Rakuten user is already in use !"));
      }
      setLoad(false);
    }
  };

  const onDisconnect =async ()=> {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      content   : translate("rakuten.connection.alert.disconnect", "When disconnected from Rakuten various integrated parts of the system will stop working, if it's okay with you click Yes?"),
      async onOk() {
        setLoad(true);
        let connect = await submitRef.current.validate();
        if (!connect) {
          setLoad(false);
          return ;
        }

        try {
          await rakuten.disconnect(connect.id);
          await auth.me()(dispatch);
          message.success(translate("system.message.success", "Success!"));
          setLoad(false);
        } catch (err){
          message.error(err.message);
        }
      } });
  };
  const onEcmDisconnect =async ()=> {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      content   : translate("ecm.disconnect.alert", "When disconnected from ECM various integrated parts of the system will stop working, if it's okay with you click Yes?"),
      async onOk() {
        setLoad(true);
        try {

          await accountApi.ecmDisconnect({ type: "ACCOUNT", id: account._id });
          await auth.me()(dispatch);
          setLoad(false);
          message.success(translate("system.message.success", "Success!"));
        } catch (err){
          message.error(err.message);
        }
      } });
  };

  const connectiveCheck =async ()=> {
    dispatch({ type: "checkRakutenConnective", payload: true });
    let res = await rakuten.check();
    setAction(["check", res]);
    dispatch({ type: "checkRakutenConnective", payload: false });
  };
  const ecmSync =async ()=> {
    setLoad(true);
    try {
      await accountApi.ecmSync(account._id);
      await auth.me()(dispatch);
      message.success(translate("system.message.success", "Success!"));
      setLoad(false);
    } catch (err){
      if (err.message === "INVALID_ACCESSKEY") {
        message.error(translate("ecm.accesskey.invalid", "Accesskey is invalid"));
      }
      else if (err.message === "RAKUTEN_LICENSE_OR_SECRET_INVALID") {
        message.error(translate("ecm.accesskey.rakuten.invalid", "Accesskey is invalid"));
      }
      else {
        message.error(translate("ecm.accesskey.invalid", "Accesskey is invalid!"));
      }
      setLoad(false);
    }
  };

  const onCancel=()=> {
    setAction(null);
  };

  const renderTitle =()=> {
    let status = action&&action[1]?.rakutenAccess?.status;
    switch (status){
      case "FAILED":
        return {
          title : "FAILED",
          color : "red",
          status: status
        };
      case "PARTLY_FAILED":
        return {
          title : "PARTLY_FAILED",
          color : "orange",
          status: status
        };
      case "SUCCESS":
        return {
          title : "SUCCESS",
          color : "green",
          status: status
        };
      default:
    }
  };

  const onChangeEcmaccesskey =async (data)=> {
    try {
      setLoad(true);
      await accountApi.ecm({ ...data, id: account._id });
      await auth.me()(dispatch);
      setLoad(false);
      message.success(translate("system.message.success", "Success"));
      setEcmaccesskey(false);
    } catch (err){
      setLoad(false);
      if (err.message === "INVALID_ACCESSKEY") {
        message.error(translate("ecm.accesskey.invalid", "Accesskey is invalid"));
      }
      else if (err.message === "RAKUTEN_LICENSE_OR_SECRET_INVALID") {
        message.error(translate("ecm.accesskey.rakuten.invalid", "Accesskey is invalid"));
      }
      else if (err.message === "ECM_ACCESSKEY_USED") {
        message.error(translate("ecm.accesskey.used", "Accesskey is used by another account"));
      }
      else {
        message.error(translate("ecm.accesskey.invalid", "Accesskey is invalid!"));
      }
    }
  };

  const actions =()=> {
    if (process.env.REACT_APP_WEBSITE_MODE === "rakuline" && disabled){
      return [
        <Button key="new" onClick={onDisconnect} type="primary" disabled={!account?.rakutenServiceSecret} danger>{translate("rakuten.connective.disconnect", "Disconnect")}</Button>,
        <Button loading={load} key="new" onClick={onSubmit} type="primary">{translate("system.save", "Save")}</Button>,
      ];
    }
    if (!account?.ecmaccesskey && disabled){
      return [
        <Button key="new" onClick={onDisconnect} type="primary" disabled={!account?.rakutenServiceSecret} danger>{translate("rakuten.connective.disconnect", "Disconnect")}</Button>,
        <Button loading={load} key="new" onClick={onSubmit} type="primary">{translate("system.save", "Save")}</Button>,
        <Button loading={load} key="new" onClick={()=>setEcmaccesskey(true)} type="primary">{account?.ecmaccesskey ? translate("ecm.change", "ECM change") : translate("ecm.add", "ECM Connect")}</Button>,
      ];
    }
    if (account?.ecmaccesskey && disabled){
      return [
        <Button loading={load} key="new" onClick={()=>setEcmaccesskey(true)} type="primary">{account?.ecmaccesskey ? translate("ecm.change", "ECM change") : translate("ecm.add", "ECM Connect")}</Button>,
        <Button key="new" onClick={onEcmDisconnect} type="primary" disabled={!account?.ecmaccesskey} danger>{translate("ecm.disconnect", "ECM Disconnect")}</Button>
      ];
    }
  };

  React.useEffect(()=> {
    setLoad(true);
    setData({

      rakutenServiceSecret: account?.rakutenServiceSecret,
      rakutenLicenseKey   : account?.rakutenLicenseKey,

      rakutenShopName: account?.rakutenShopName,
      rakutenShopPass: account?.rakutenShopPass,
      rakutenUserName: account?.rakutenUserName,
      rakutenUserPass: account?.rakutenUserPass,

      id: account?._id
    });
    setLoad(false);
  }, [account]);

  // if (load === true) return (<div></div>);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.connective"></ManualLink>}title={translate("rakuten.connection", "Rakuten connective")} extra={actions()}/>
      <PageContent>
        <Spin spinning={load === true}>
          <div style={{ height: "500px" }}>
            {load === false&&<Form ref={submitRef} connectiveCheck={connectiveCheck} ecmSync={ecmSync} action ={["update", data]}/>}
          </div>
        </Spin>
      </PageContent>

      {
        action && action[0] === "check" &&(
          <Modal
            width={800}
            maskClosable ={false}
            title={
              <div>
                <span>{translate("api.check.connection", "Check")}</span>
                <Tag style={{ marginLeft: "10px" }} color={renderTitle().color}>{translate(`api.check.status.${renderTitle().status}`, renderTitle().title)}</Tag>
              </div>
            }
            visible={!!action}
            onCancel={onCancel}
            destroyOnClose
            footer={false}>
            <Container>
              <Check action={action[1]} close={onCancel}/>
            </Container>
          </Modal>
        )
      }
      <Modal
        maskClosable={false}
        title={translate("ecm.accesskey.change", "ECM Accesskey change")}
        visible={ecmaccesskey}
        onCancel={()=>setEcmaccesskey(false)}
        destroyOnClose
        footer={false}>
        <EcmAccessKeyForm onCancel={setEcmaccesskey} onSubmit={onChangeEcmaccesskey} />
      </Modal>
    </PageContainer>
  );
};

const Container =styled.div`
  height:400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
      height: 1px;
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1; 
    }
    &::-webkit-scrollbar-thumb {
      background: ${colors.primaryDark}; 
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: ${colors.primary}; 
    }
`;