import React from "react";
import { PageContainer, PageContent, PageHeader } from "../../components/Layout";
import { message, Space, Input, Tree, Spin, Empty } from "antd";
import { Button } from "../../components/Design";
import { useHistory } from "react-router-dom";
import { account as accountApi, rakutencategoryApi } from "../../apis";
import { useTranslate } from "../../hooks";
import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";
import colors from "../../colors";
import confirm from "antd/lib/modal/confirm";
import { ManualAlert, ManualLink } from "../../components/Manual";

const RakutenCategories = () => {
  const { translate } = useTranslate();
  const [loading, setLoading] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [categorySync, setCategorySync] = React.useState(false);
  const [expandedKeys, setExpandedKeys] = React.useState([]);
  const [autoExpandParent, setAutoExpandParent] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [dataList, setDataList] = React.useState([]);
  const [isComposing, setIsComposing] = React.useState(false);
  const [filters, setFilters] = React.useState({
    query: "",
  });
  const getAccountSetting = async () => {
    try {
      const { rakutenCategorySync } = await accountApi.setting();
      setCategorySync(rakutenCategorySync);
    } catch (error) {
      message.error("Error fetching account settings.");
    }
  };

  React.useEffect(() => {
    getAccountSetting();
  }, []);

  const generateList = async (data) => {
    const list = [];
    const traverse = (nodes) => {
      nodes.forEach(node => {
        const { key, children } = node;
        list.push({ key, title: node.title });
        if (children) traverse(children);
      });
    };
    traverse(data);
    return list;
  };

  const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  const onChange = (e) => {
    if (isComposing) return;
    console.log("oncHange");
    const { value } = e.target;
    setQuery(value);
  };

  const handleCompositionStart = () => {
    setIsComposing(true);
  };

  const handleCompositionEnd = (e) => {
    console.log("oncomposeEnd");
    setIsComposing(false);
    setQuery(e.target.value);
  };

  // React.useEffect(() => {
  //   if (!isComposing && query) {
  //     const event = { target: { value: query } };
  //     onChange(event);
  //   }
  // }, [isComposing]);

  React.useEffect(() => {
    const newExpandedKeys = dataList.map((item) => {
      console.log("filter.qeury", filters.query);
      console.log(item.title.includes(filters.query));
      console.log(item.title);
      if (filters.query !== "" && item.title.includes(filters.query)) {
        return getParentKey(item.key, data);
      }
      return null;
    }).filter((item, i, self) => item && self.indexOf(item) === i);
    console.log("-----------expanded_key", newExpandedKeys);
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(true);
  }, [dataList]);

  React.useEffect(() => {
    const fetchCategoryData = async () => {
      setLoading(true);
      try {
        // setDataList([]);
        // setData([]);
        const { categoryTree } = await rakutencategoryApi.list({ filter: filters });
        const result = await generateList(categoryTree);
        setData(categoryTree);
        setDataList(result);
      } catch (error) {
        console.log(error);
        message.error("Error fetching category data.");
      } finally {
        setLoading(false);
      }
    };
    fetchCategoryData();
  }, [filters]);

  const treeData = React.useMemo(() => {
    console.log("usememo:", data);
    setLoading(true);
    const loop = (data) =>
      data.map((item) => {
        const index = item.title.indexOf(query);
        const beforeStr = item.title.substring(0, index);
        const afterStr = item.title.slice(index + query.length);
        const title = index > -1 ? (
          <span>
            {beforeStr}
            <span className="site-tree-search-value">{query}</span>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
        if (item.children) {
          return { title, key: item.key, children: loop(item.children) };
        }
        return { title, key: item.key };
      });
    setLoading(false);
    return loop(data);
  }, [data]);

  const createAll = () => {
    confirm({
      icon      : <ExclamationCircleOutlined />,
      content   : translate("system.confirmation.question", "Are you sure ?"),
      okText    : translate("system.yes", "Yes"),
      cancelText: translate("system.no", "No"),
      async onOk() {
        try {
          await rakutencategoryApi.create();
          await getAccountSetting();
          message.success(translate("system.message.success", "Successful"));
        } catch (err) {
          const errorMsg = err.message === "CATEGORY_SYNCING"
            ? translate("system.error.category.syncing", "Rakuten category syncing is in progress!")
            : translate("system.error", "Error");
          message.error(errorMsg);
        }
      },
      okButtonProps    : { style: { background: colors.primaryDark, border: "none" } },
      cancelButtonProps: { style: { borderColor: colors.primaryDark } }
    });
  };
  const onExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };
  return (
    <PageContainer>
      <PageHeader
        subTitle={<ManualLink link="manual.link.rakutencategory"></ManualLink>}
        title={translate("dashboard.rakutencategories", "Rakuten categories")}
        extra={[
          <Button disabled={categorySync} key="new" onClick={createAll} type="primary">
            {translate("rakutencategories.create", "Create all")}
          </Button>
        ]}
      />
      {categorySync && <ManualAlert translateKey={"rakuten.category.list.alert"} />}
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={onChange}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
        </Space>
        <Space />
      </PageFilter>
      <PageContent>
        <Spin spinning={loading}>
          {treeData.length > 0 ? (
            <CustomTree
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              treeData={treeData}
            />
          ) : (
            (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />)
          )}
        </Spin>
      </PageContent>
    </PageContainer>
  );
};
const CustomTree = styled(Tree)`
  .site-tree-search-value {
    background-color: ${colors.primaryDark};
    color : #ffffff;
  }
  .ant-tree-treenode {
    border-bottom: 1px solid #d9d9d9; 
    width: 100%;
    align-items: center;
    margin: 2px;
  }

  .ant-tree-treenode-level-0 > .ant-tree-node-content-wrapper {
    background-color: #f0f0f0;
    margin: 2px;
    width: 100%;
  }
  .ant-tree-treenode > .ant-tree-node-content-wrapper {
    margin: 2px;
    width: 100%;
  }
  .ant-tree-treenode > .ant-tree-switcher {
    margin: 2px;
  }
`;
const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;

export default RakutenCategories;
