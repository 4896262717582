import React from "react";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import Form from "./components/NotifMessageDelegateForm";
import styled from "styled-components";
import { notifMessageDelegateApi } from "../../apis";
import { useTranslate } from "../../hooks";
import { useHistory } from "react-router-dom";
import { message, Spin } from "antd";

export default () => {
  const { translate } = useTranslate();
  const history = useHistory();
  const [action, setAction] = React.useState(["update", null]);
  const [loading, setLoading] = React.useState(true);

  const reload = React.useCallback(
    async (signal) => {
      let res = await notifMessageDelegateApi.get("", { signal });
      setAction(["update", {
        ...res,
      }]);
      setLoading(false);
    },
    []
  );

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    reload(signal);
    return () => abortController.abort();
  }, [reload]);

  const onSubmit = async (data) => {
    try {
      await notifMessageDelegateApi.update(data);
      message.success(translate("notifmessage.delegate.save", "Updated delivery message delegate account information!"));
    } catch (error) {
      message.error(translate("system.error", "error!"));
    }
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.goBack()} title={translate("notifmessage.delegate.title", "Delivery message delegate account")} />
      <PageContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FormWrapper>
            {!loading ? <Form action={action} onSubmit={onSubmit} />: <Spin/>}
          </FormWrapper>
        </div>
      </PageContent>
    </PageContainer>
  );

};

const FormWrapper = styled.div`
  width: 600px;
`;