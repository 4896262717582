import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { general } from "../../apis";

export default () => {
  const dispatch = useDispatch();
  const { lang, translate } = useSelector(state => state.general);

  return {
    translate: (key, value) => {

      if (translate[lang] && translate[lang][key])
      {
        return translate[lang][key];
      }

      if (value === "#" && translate["JP"] && translate["JP"][key]) {
        return translate["JP"][key];
      }
      return value;
    },
    changeLanguage: (lang) => {
      general.localization({ lang: lang });

      dispatch({
        type   : "general.change.language",
        payload: lang
      });
    },
    lang
  };
};
