import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Input, FormItem, Select } from "formik-antd";
import { Formik } from "formik";
import { Button, Form as AntForm } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useTranslate } from "../../../hooks";
import colors from "../../../colors";

const { Option } = Select;
const { TextArea } = Input;

export default ({ nextPage }) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const { businessTypes } = useSelector(state => state.general);
  const companyName = useSelector(state => state.register.companyName);

  const onSubmit = async (data) => {
    dispatch({
      type   : "register/verify/CompanyInfo",
      payload: data
    });
    nextPage();
  };

  const [data, setFormData] = React.useState({
    companyName : undefined,
    address     : undefined,
    phoneNumber : undefined,
    businessType: undefined,
  });

  const FormSchema = Yup.object().shape({
    companyName : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    address     : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    phoneNumber : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
    businessType: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  });

  React.useEffect(() => {
    setFormData({ ...data, companyName });
  }, [companyName]);

  return (
    <StepOneWrapper>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={FormSchema}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical" style={{ width: "100%" }}>
            <Divider style={{ textAlign: "center" }}>{translate("useredit.company", "Company information")}</Divider>
            <FormItem label={<label className="form-input-title">{translate("useredit.companyname", "Company name")}</label>} name="companyName" required>
              <Input className="form-input" name="companyName" placeholder={translate("useredit.companyname", "Company name")} />
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("useredit.address", "Address")}</label>} name="address" required>
              <TextArea className="form-input" name="address" placeholder={translate("useredit.address", "Address")}/>
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("useredit.phone", "Phone")}</label>} name="phoneNumber" required>
              <Input className="form-input" name="phoneNumber" placeholder={translate("useredit.phone", "Phone")} />
            </FormItem>
            <FormItem label={<label className="form-input-title">{translate("useredit.business", "Business Type")}</label>} name="businessType" required>
              <Select style={{ borderRadius: "10px", height: "45px" }} name="businessType" placeholder={translate("useredit.business", "Business Type")}>
                {businessTypes.map(type => {
                  return <Option key={type._id} value={type.code}>{type.name}</Option>;
                })}
              </Select>
            </FormItem>
            <AntForm.Item>
              <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.confirm", "Confirm")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>

    </StepOneWrapper>
  );
};

const StepOneWrapper = styled.div`
     display : flex;
     justify-content : center;
`;

const Divider = styled.div`
  margin-bottom : 20px;
  font-size     : 15px;
  font-weight   : 500;
  // background : #e7e7e7;
  background    : ${colors.primaryDark};
  padding: 4px 8px;
  border-radius: 2px;
  color : white;
`;