import React from "react";
import { Form, FormItem, Input, Radio } from "formik-antd";
import { Formik, useFormikContext } from "formik";
import { Space, message, Tooltip } from "antd";
import * as Yup from "yup";
import { PictureOutlined, PlayCircleOutlined, MessageOutlined, FormOutlined, CloseOutlined, FolderOpenOutlined, ProfileOutlined, UpOutlined, DownOutlined, ColumnWidthOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { useDispatch } from "react-redux";
import Button from "../Design/Button";
import { TextMessage, ImageMessage, VideoMessage, StickerMessage, CooperationMessage, TemplateMessage, ImagemapMessage } from "./components";
import StickerIcon from "../../assets/sticker.svg";
import colors from "../../colors";
import CarouselMessage from "./components/CarouselMessage";
import EmojiTextField from "./components/EmojiTextField";


const FormSchema = (translate) => Yup.object({
  title   : Yup.string().required(translate("system.message.required", "Please fill out this field!")),
  children: Yup.array().of(Yup.object().shape({
    type: Yup.string().required(),
    text: Yup.string().when("type", {
      is  : "text",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    sticker: Yup.string().when("type", {
      is  : "sticker",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    image: Yup.string().when("type", {
      is  : "image",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    video: Yup.string().when("type", {
      is  : "video",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    cooperation: Yup.string().when("type", {
      is  : "cooperation",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    template: Yup.string().when("type", {
      is  : "template",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    imagemap: Yup.string().when("type", {
      is  : "imagemap",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
    carousel: Yup.string().when("type", {
      is  : "carousel",
      then: Yup.string().required(translate("system.message.required", "Please fill out this field!"))
    }),
  })).required(),
});

const SubmitForm = React.forwardRef((props, ref) => {
  const formik = useFormikContext();
  const { validateForm, submitForm, values } = formik;

  React.useImperativeHandle(ref, () => ({
    async submitForm() {
      await submitForm();
      let errors = await validateForm();

      if (Object.keys(errors).length > 0){
        // message.error(translate("system.error.required", "Insert necessary fields first!"));
        return false;
      }

      return values;
    },
    async isValid() {
      let errors = await validateForm();
      if (Object.keys(errors).length > 0){
        return false;
      }

      return true;
    }
  }));

  return null;
});

export default React.forwardRef((props, ref) => {
  const { translate } = useTranslate();
  const { editable, onSubmit, disabled, children } = props;
  const dispatch = useDispatch();
  const submitRef = React.useRef();
  const [data, setFormData] = React.useState({
    title   : undefined,
    children: [{
      type       : "text",
      text       : undefined,
      sticker    : undefined,
      image      : undefined,
      video      : undefined,
      cooperation: undefined,
      template   : undefined,
      imagemap   : undefined,
      carousel   : undefined,
      characters : []
    }],
    ...(editable && (editable[0] === "update" || editable[0] === "reply") ? editable[1] : {})
  });

  let storeTimer;

  const onMouseDown = () => {
    dispatch({ type: "message.preview", payload: true });
  };

  const onAddChildren = ({ values }) => {
    setFormData({
      ...values,
      children: [...values.children, {
        type: "text",
        text: undefined
      }]
    });
  };

  const removeMessageChild = ({ index, values }) => {
    if (values.children.length === 1) {
      message.error(translate("system.error", "Error"));
    } else {
      values.children.splice(index, 1);
      setFormData({
        ...values,
        children: values.children
      });
      dispatch({
        type   : "message.message.data",
        payload: values
      });
    }
  };

  const onChangeType = (setValues, values, index, e) => {
    if (disabled) return;

    let childrens = values.children;
    childrens[index] = {
      type       : e.target.value,
      text       : undefined,
      sticker    : undefined,
      image      : undefined,
      video      : undefined,
      cooperation: undefined,
      template   : undefined,
      imagemap   : undefined,
      characters : []
    };
    setValues({ ...values, children: childrens });
  };

  const updateStore = ({ values, children }, callback) => {
    if (storeTimer)
      clearTimeout(storeTimer);

    storeTimer = setTimeout(async () => {
      dispatch({
        type   : "message.message.data",
        payload: {
          ...values,
          children: children
        }
      });

      if (callback) callback();

      if (storeTimer)
        clearTimeout(storeTimer);

      storeTimer = null;
    }, 300);
  };

  const messageChildPossition =(index, values, posstion, setFieldValue, setValues)=> {
    console.log(index);
    console.log(values);
    if (posstion === "down"){
      let childrens = values.children;
      let child = childrens[index];
      childrens[index] = childrens[index+1];
      childrens[index+1] = child;
      setValues({ ...values, children: childrens });
    }
    if (posstion === "up"){
      let childrens = values.children;
      let child = childrens[index];
      childrens[index] = childrens[index-1];
      childrens[index-1] = child;
      setValues({ ...values, children: childrens });
    }
    dispatch({
      type   : "message.message.data",
      payload: {
        title: data.title,
        text : data.text
      } });
  };

  React.useEffect(() => {
    return () => {
      if (storeTimer)
        clearTimeout(storeTimer);
    };
  }, [storeTimer]);


  const renderMessage = ({ index, child, values, setValues, setFieldValue, disabled }) => {
    return (
      <WriteMessage key={index}>
        {!disabled && (
          <MessageToolbar>
            <Space className="left">
              <Radio.Group name={`children[${index}].type`} onChange={(e) => onChangeType(setValues, values, index, e)}>
                <Tooltip placement="top" title={translate("message.text.tooltip", "Text")}>
                  <Radio.Button value="text"> <MessageOutlined /></Radio.Button>
                </Tooltip>

                <Tooltip placement="top" title={translate("message.sticker.tooltip", "Sticker")}>
                  <Radio.Button value="sticker"><img src={StickerIcon} style={{ width: "14px", height: "14px" }} /></Radio.Button>
                </Tooltip>

                <Tooltip placement="top" title={translate("message.image.tooltip", "Image")}>
                  <Radio.Button value="image"><PictureOutlined /></Radio.Button>
                </Tooltip>

                <Tooltip placement="top" title={translate("message.video.tooltip", "Video")}>
                  <Radio.Button value="video"><PlayCircleOutlined /></Radio.Button>
                </Tooltip>

                {/* <Tooltip placement="top" title={translate("message.cooperation.tooltip", " Rakuten cooperation form")}>
                  <Radio.Button value="cooperation"><FormOutlined /></Radio.Button>
                </Tooltip> */}
                <Tooltip placement="top" title={translate("message.template.tooltip", "Simple card")}>
                  <Radio.Button value="template"><FolderOpenOutlined /></Radio.Button>
                </Tooltip>
                <Tooltip placement="top" title={translate("message.imagemap.tooltip", "Rich message")}>
                  <Radio.Button value="imagemap"><ProfileOutlined /></Radio.Button>
                </Tooltip>
                <Tooltip placement="top" title={translate("message.carousel.tooltip", "Carousel")}>
                  <Radio.Button value="carousel"><EditOutlined /></Radio.Button>
                </Tooltip>
              </Radio.Group>
            </Space>
            <Space>
              {values.children.length > index +1 && (<Button key={index} size="small" className="close-button" onClick={() => messageChildPossition(index, values, "down", setFieldValue, setValues)}><DownOutlined /></Button>)}
              {values.children.length > 1 && index > 0 && (<Button key={index} size="small" className="close-button" onClick={() => messageChildPossition(index, values, "up", setFieldValue, setValues)}><UpOutlined /></Button>)}
              {values.children.length > 1 && <Button key={index} size="small" className="close-button" onClick={() => removeMessageChild({ index, values })}><CloseOutlined /></Button>}
            </Space>
          </MessageToolbar>
        )}
        <MessageContent>
          {(() => {
            switch (child.type) {

              case "text":
                return (
                  <>
                    <Input type="hidden" name={`children[${index}].characters`} />
                    <Input type="hidden" name={`children[${index}].text`} />
                    <TextMessage
                      name={`children[${index}].text`}
                      text={child.text}
                      characters={child.characters}
                      onChange={({ text, characters }) => {
                        let children = values.children;
                        children[index] = {
                          type: child.type,
                          text,
                          characters
                        };

                        updateStore({ values, children }, () => {
                          setFieldValue(`children[${index}].characters`, characters);
                          setFieldValue(`children[${index}].text`, text);

                          if (characters.length === 0) {
                            submitRef.current.submitForm();
                          }
                        });
                      }}
                      onMouseDown={onMouseDown} />
                  </>
                );
              case "sticker":
                return <StickerMessage key={index} name={`children[${index}].sticker`} />;
              case "image":
                return <ImageMessage key={index} name={`children[${index}].image`} action="/api/message/upload" />;
              case "video":
                return <VideoMessage key={index} name={`children[${index}].video`} action="/api/message/upload" />;
              case "cooperation":
                return (
                  <div key={index}>
                    <Input type="hidden" name={`children[${index}].cooperationValues`} />
                    <CooperationMessage index={index} name={`children[${index}].cooperation`} values={child.cooperationValues} />
                  </div>
                );
              case "template":
                return (
                  <div key={index}>
                    <Input type="hidden" name={`children[${index}].templateValues`} />
                    <TemplateMessage index={index} name={`children[${index}].template`} values={child.templateValues} />
                  </div>
                );
              case "imagemap":
                return (
                  <div key={index}>
                    <Input type="hidden" name={`children[${index}].imagemapValues`} />
                    <ImagemapMessage index={index} name={`children[${index}].imagemap`} values={child.imagemapValues} />
                  </div>
                );
              case "carousel":
                return (
                  <div key={index}>
                    <Input type="hidden" name={`children[${index}].carouselValues`} />
                    <CarouselMessage index={index} name={`children[${index}].carousel`} values={child.carouselValues} />
                  </div>
                );
              default:
                return null;
            }
          })()}
        </MessageContent>
      </WriteMessage>
    );
  };

  React.useImperativeHandle(ref, () => ({
    async validate() {
      let isValid = await submitRef.current.submitForm();
      return isValid;
    }
  }));

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        validate={(data) => {
          updateStore({ values: data, children: data.children });
        }}
        onSubmit={onSubmit || (() => {})}>
        {({ values, setValues, setFieldValue }) => {
          return (
            <Form layout="vertical">
              <FormItem style={{ width: "100%" }} label={translate("message.title", "Title")} name="title" required>
                <EmojiTextField text={values.title} setFieldValue={setFieldValue} name="title" placeholder={translate("message.placeholder", "Title")} disabled={disabled} />
              </FormItem>
              {children}
              {values.children.map((child, index) => {
                return renderMessage({ index, child, values, setValues, setFieldValue, disabled });
              })}

              {!disabled && <Button type="default" disabled={values.children.length >= 3 || disabled} onClick={() => onAddChildren({ values })}>{translate("system.add", "Add")}</Button>}

              <SubmitForm ref={submitRef} translate={translate} />
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
});
const Container = styled.div`
  .ant-input-disabled {
    background: #fff!important;
    color: #333!important;
  }
`;

const MessageToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: rgba(0,0,0,0.03);
  border-bottom: 1px solid rgba(0,0,0,0.125);

  .close-button{
    background-color: #f7f7f7;
  }
`;

const MessageContent = styled.div`
  padding: 15px;
  .max-length {
    margin-top: 5px;
    text-align: right;
    font-size: 14px;
    color: #adb5bd;
    .length {
      font-weight: 500;
    }
  }
`;

const WriteMessage = styled.div`
  border: 1px solid #ddd;
  border-radius: 2px;
  margin-bottom: 20px;
  .ant-radio-button-wrapper {
    &:hover {
      color: ${colors.primary};
    }
  }
  .ant-radio-button-wrapper-checked {
    &:hover {
      color: ${colors.primary}!important;
    }
    color: ${colors.primaryDark};
    border-color: ${colors.primaryDark}!important;
    &:before {
      background-color: ${colors.primaryDark}!important;
    }
  }
`;