import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu, Badge, Popover, message } from "antd";
import { CreditCardOutlined, UserOutlined, LogoutOutlined, SettingOutlined, PicRightOutlined, PlusOutlined, ReconciliationOutlined, BulbOutlined, MailOutlined } from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import { auth, account as accountApi } from "../../apis";
import styled from "styled-components";
import { NavLink, useHistory } from "react-router-dom";
import colors from "../../colors";
import { useTranslate, usePermission } from "../../hooks";

export default () => {
  const { translate, changeLanguage } = useTranslate();
  const dispatch = useDispatch();
  const { accounts, account, customer, user, lang } = useSelector(state => state.general);
  const { notifications } = useSelector(state => state.socket);
  const { checkRole } = usePermission();
  const { disabledButton, setDisabledButton } = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"]));
  const history = useHistory();

  const logout = async () => {
    await auth.logout();

    dispatch({
      type   : "auth.change",
      payload: {
        logged: false
      }
    });
    dispatch({ type: "auth.logout", payload: "" });
  };

  const change = async (value) => {
    let res = await accountApi.change(value._id);

    dispatch({
      type   : "auth.account",
      payload: res
    });
    history.push("/");
    window.location.reload();

  };

  const onCreateAccount = () => {
    if (user.balance && user.balance.account === -1 || user.balance&&user.balance.account > 0)
      history.push({ pathname: "/account/new", state: { action: "new" } });
    else
      message.error(translate("line.alert.acc", "You have reached account connection limit"));
  };

  const menu = (
    <Menu>
      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "SUPPORT"]) && <Menu.Item>
        <a href={translate("system.contacts_us_url", "#")} target="_blank">
          <MailOutlined /> {translate("system.contacts_us", "Contacts")}
        </a>
      </Menu.Item>}
      {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "SUPPORT"]) && <Menu.Item>
        {/* checkRole(["AGENCY", "ACCOUNT"]) */}
        <a href={translate("system.manual_link", "#")} target="_blank">
          <BulbOutlined /> {translate("system.manual", "Manual")}
        </a>
      </Menu.Item>}
      {
        ((()=>{
          if (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN"])) {
            return (<>
              <Menu.Item >
                {/* checkRole(["AGENCY", "ACCOUNT"]) */}
                <NavLink to={customer&& "/customer/" + (customer && customer._id) || "/configuration"}>
                  <SettingOutlined /> {translate("user.edit", "Settings")}
                </NavLink>
              </Menu.Item>
            </>);
          }
          if (checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["STAFF", "NO_MESSAGE", "NO_REPORT"])) {
            return (<>
              <Menu.Item >
                {/* checkRole(["AGENCY", "ACCOUNT"]) */}
                <NavLink to={"/settings/staff"}>
                  <SettingOutlined /> {translate("user.edit", "Settings")}
                </NavLink>
              </Menu.Item>
            </>);
          }
          return (<></>);

        })())
      }

      {/* {checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT", "aff"]) && customer && (
        <Menu.Item>
          <NavLink to="/info/login">
            <ReconciliationOutlined /> {translate("login.info", "Info login")}
          </NavLink>
        </Menu.Item>
      )} */}

      {/*process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN"]) && customer && !customer?.ecmaccesskey && (
        <Menu.Item>
          <NavLink to="/payment/methods">
            <CreditCardOutlined /> {translate("payment.method", "Payment method")}
          </NavLink>
        </Menu.Item>
      )*/}
      {/*process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission&&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]) && customer && !customer?.ecmaccesskey && (
        <Menu.Item>
          <NavLink to="/plan/configuration" >
            <PicRightOutlined /> {translate("user.plan", "Plan configuration")}
          </NavLink>
        </Menu.Item>
      )*/}
      <Menu.Item onClick={() => logout()}>
        <LogoutOutlined />  {translate("logout", "Logout")}
      </Menu.Item>
    </Menu>
  );

  const changeLocalization = (e) => {
    changeLanguage(e.key === "EN" ? "EN" : "JP");
  };

  const localization = (
    <Menu onClick={(e) => changeLocalization(e)}>
      <Menu.Item key="EN" >
        EN
      </Menu.Item>
      <Menu.Item key="JP">
        JP
      </Menu.Item>
    </Menu>
  );

  return (
    <AccountBar>
      <div className="accounts">
        {customer && (
          <>
            {accounts.map((item, index) => {
              return (
                <a key={index} className={`account ${account && account._id === item._id && "selected"}`} onClick={() => change(item)}>
                  <Badge count={notifications.filter(n => n.account === item._id).length}>
                    <Popover content={item.displayName} placement="right">
                      <Avatar size={36} src={item.pictureUrl} icon={<UserOutlined />} />
                    </Popover>
                  </Badge>
                </a>
              );
            })}
            {((()=>{
              if (user && user.balnce === null){
                return (
                  message.warning(translate("plan.noplan", "Please buy a plan"))
                );
              }
              if (customer?.paymentBlock === false && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission &&checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT"])){
                return (<a className='account action' onClick={onCreateAccount}><PlusOutlined /></a>);
              }
            }))()}
            {((()=>{
              if (customer?.paymentBlock === false && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_MESSAGE", "NO_REPORT" ]) && account){
                return ((<a className={`account action settings  ${!disabledButton?"disabled":null}`} onClick={()=>history.push(`/account/${account._id}`)}><SettingOutlined /></a>));
              }
            }))()}
          </>
        )}

      </div>

      <div className="tools">
        <Dropdown className="dropdown-wrapper-customer" overlay={localization} placement="topCenter" trigger={["click"]} >
          <div>{lang && lang}</div>
        </Dropdown>

        <div className="action">
          <Dropdown overlay={menu} trigger={["click"]} placement="topLeft">
            <Badge count={0}>
              <Avatar size={32} icon={<UserOutlined />} />
            </Badge>
          </Dropdown>
        </div>

      </div>
    </AccountBar>
  );
};

const AccountBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${colors.primaryDark};
  min-height: 100%;
  padding: 15px 0;
  // box-shadow: 1px 0px 4px 0px rgb(0 0 0 / 8%);
  z-index: 2000;
  width: 70px;
  .dropdown-wrapper-customer {
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px;
    padding: 1px;
    border-radius: 3px;
    color: white;
    margin-bottom: 10px;
    background: ${colors.dark};
    border: 1px solid ${colors.dark};
    padding: 0 2px;
    cursor: pointer;
    line-height: 17px;
    font-size: 12px;
    font-weight: 500;
  }

  .ant-avatar {
    background: #fff;
    color: ${colors.primaryDark};
  }
  .accounts {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15px;
    .account {
      display: flex;
      // margin-bottom: 20px;
      border: 3px solid transparent;
      // border-radius: 50%;
      transition: all .2s ease-in;
      text-align: center;
      height: 58px;
      width: 100%;
      align-items: center;
      justify-content: center;
      
      &.selected {
        // border-color: rgb(0 0 0 / 25%);
        // border-color: red;
        background-color: white;
      }
      &:hover {
        // border-color: rgb(0 0 0 / 25%);
        background: rgb(0 0 0 / 25%);
      }
      &.action {
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        &.settings {
          .anticon {
            font-size: 18px;
          }
        }
        .anticon {
          color: #fff;
          text-align: center;
          font-size: 22px;
        }
      }
     
    }
  }
  .tools {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    font-size: 15px;
    .action { 
      display: flex;
      flex-direction: row;
      position: relative;
      cursor: pointer;
      border: 3px solid transparent;
      border-radius: 50%;
      transition: all .2s ease-in;
      margin-bottom: 15px;
      &:hover {
        border-color: rgb(0 0 0 / 25%);
        background: rgb(0 0 0 / 25%);
      }
    }
  }
`;