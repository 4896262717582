import React from "react";
import styled from "styled-components";
import Scenario from "../../components/Scenario";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { useFetch, useTranslate } from "../../hooks";
import { tags as tagsApi, scenario as scenarioApi } from "../../apis";
import { Button, message, Spin, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MessageForm } from "../../components/Message";
import { useHistory, useParams } from "react-router-dom";
import { validateChildren } from "../../reducers/process";
import TimerForm from "./components/TimerForm";
import Target from "../../components/Message/Target";
import NextStepForm from "./components/NextStepForm";
import SettingsForm from "./components/SettingsForm";
import colors from "../../colors";
import MessageDetail from "../../components/MessageDetail";
import InfoMessage from "./components/InfoMessage";
import TaggedForm from "./components/TaggedForm";
import { PlusOutlined } from "@ant-design/icons";
import Form from "../Tags/Form";

export default ({ editable }) => {
  const { translate } = useTranslate();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);

  const actionFormRef = React.useRef();
  const taggedFormRef = React.useRef();
  const timerFormRef = React.useRef();
  const nextFormRef = React.useRef();
  const conditionFormRef = React.useRef();
  const settingsFormRef = React.useRef();
  const judgementFormRef = React.useRef();

  const [loading, setLoading] = React.useState(false);
  const [current, setCurrent] = React.useState();
  const [action, setAction] = React.useState([]);

  useFetch(tagsApi.select(dispatch))();

  const { children, selected, childrenForm: form, index, isEditable = false } = useSelector(state => state.process);
  const [childrenForm, setChildrenForm] = React.useState({ ...(editable[2] || {}) });

  const updateChildrenForm = (payload) => {
    let formData = {
      ...childrenForm,
      [payload._id]: payload.form
    };

    dispatch({
      type   : "process.form",
      payload: payload
    });

    setChildrenForm(formData);

    return new Promise((resolve) => {
      resolve(formData);
    });
  };

  const onValidate = async (selected) => {
    if (!isEditable) return;

    switch (selected?.type) {
      case "tag": {
        if (!taggedFormRef.current) return;

        let valid = await taggedFormRef.current.validate();
        if (!valid) return false;

        return updateChildrenForm({
          _id : selected._id,
          form: {
            tags: valid.values
          }
        });
      }
      case "action": {
        if (!actionFormRef.current) return;

        let valid = await actionFormRef.current.validate();
        if (!valid) return false;

        return updateChildrenForm({
          _id : selected._id,
          form: {
            name   : valid.title,
            message: valid
          }
        });
      }
      case "condition": {
        if (!conditionFormRef.current) return;

        let valid = await conditionFormRef.current.validate();
        if (!valid) return false;

        return updateChildrenForm({
          _id : selected._id,
          form: {
            targets: valid.targets
          }
        });
      }
      case "judgement": {
        if (!judgementFormRef.current) return true;

        let valid = await judgementFormRef.current.validate();
        if (!valid) return childrenForm;

        return updateChildrenForm({
          _id : selected._id,
          form: {
            targets: valid.targets
          }
        });
      }
      case "next": {
        if (!nextFormRef.current) return;

        let valid = await nextFormRef.current.validate();
        if (!valid) return false;

        return updateChildrenForm({
          _id : selected._id,
          form: valid
        });
      }
      case "timer": {
        if (!timerFormRef.current) return;

        let valid = await timerFormRef.current.validate();
        if (!valid) return false;

        return updateChildrenForm({
          _id : selected._id,
          form: valid
        });
      }
      default:
        return childrenForm;
    }
  };

  const getChildrenFormData = ({ children, childrenForm }) => {
    return children.reduce((accumulator, iterator, i) => {
      return [...accumulator, {
        ...iterator,
        ...childrenForm[iterator._id],
        condition: iterator.condition,
        children : getChildrenFormData({ children: iterator.children || [], childrenForm }),
        index    : iterator.type === "step" ? index[iterator._id] : i
      }];
    }, []);
  };

  const reverseChildren = ({ children = [] }) => {
    return (children || []).reduce((accumulator, iterator) => {
      return [...accumulator, iterator, ...reverseChildren({ children: iterator.children })];
    }, []);
  };

  const validate = async (error) => {
    const forms = ["action", "tag", "condition", "next", "timer"];

    await settingsFormRef.current.validate();

    let valid = await onValidate(current);
    if (!valid) {
      error(translate("scenario.form.alert", "All parts must be valid!"));
      return false;
    }

    let invalid = (reverseChildren({ children }) || []).reduce((acccumulator, iterator) => {
      if (acccumulator) return true;

      if (forms.indexOf(iterator.type) === -1)
        return false;

      return !valid[iterator._id];
    }, false);

    if (invalid) {
      error(translate("scenario.form.alert.2", "All parts must be valid!"));
      return false;
    }

    const validate = validateChildren({ childrenForm: valid, children });
    const invalids = validate.filter(invalid => invalid.valid === false);

    if (invalids.length > 0) {
      error(translate("scenario.form.alert.2", "All parts must be valid!"));
      return false;
    }

    let settings = await settingsFormRef.current.validate();
    if (!settings) {
      error(translate("scenario.form.alert.3", "Please insert name or event type!"));
      return false;
    }

    return [settings, valid];
  };

  const onSubmit = async (data) => {
    try {
      await tagsApi.create(data);
      await tagsApi.select(dispatch)();
    } catch (err) {
      message.error(err.message);
    }

    message.success(translate("system.message.success", "Success!"));
    onCancel(true);
  };
  const onCancel = () => {
    setAction([]);
  };

  const onSave = async () => {
    let valid = await validate((error) => {
      message.error(error);
    });

    if (valid) {
      try {
        if (editable[0] === "update") {
          await scenarioApi.update(params.id, {
            ...valid[0],
            children: getChildrenFormData({ children, childrenForm: valid[1] })
          });
        } else {
          await scenarioApi.create({
            ...valid[0],
            children: getChildrenFormData({ children, childrenForm: valid[1] })
          });
        }
        history.push("/products/scenario");
        message.success(translate("system.message.success", "Success!"));

      } catch (error) {
        message.error(`${error.message}`);
      }
    }

    dispatch({ type: "general.isSubmit", payload: true });
  };

  const renderForm = () => {
    switch (current?.type) {
      case "action":
        return isEditable ? (<MessageForm ref={actionFormRef} editable={["update", childrenForm[current?._id]?.message]} autoSave >
          <div style={{ marginTop: "10px", marginBottom: "10px", color: "gray" }}>{translate("process.message.alert", "If you enter {AccountName}, it will be converted to your account name, and if you enter {Nickname}, it will be converted to your friend's display name and sent.")}</div>
          <div style={{ marginTop: "10px", marginBottom: "10px", color: "gray" }}>{translate("process.message.alert2", "However, friend display names will be sent blank to users who have not viewed their profile information.")}</div>
        </MessageForm>
        ) : <MessageDetail data={childrenForm[current?._id]?.message} /> ;
      case "tag":
        return <TaggedForm ref={taggedFormRef} editable={["update", childrenForm[current?._id]]} />;
      case "timer":
        return <TimerForm ref={timerFormRef} editable={["update", childrenForm[current?._id]]} />;
      case "next":
        return <NextStepForm ref={nextFormRef} editable={["update", childrenForm[current?._id]]} />;
      case "condition":
        return <Target ref={conditionFormRef} editable={["update", { segments: childrenForm[current?._id]?.targets }]} onlyCustomSegments disabled={!isEditable} />;
      case "judgement":
        return <Target ref={judgementFormRef} editable={["update", { segments: childrenForm[current?._id]?.targets }]} onlyCustomSegments disabled={!isEditable} />;
      case "start":
        return <InfoMessage text={translate("shape.info.start", "This part is used only for Common judgement. It starts the step corresponding to the common judgement.")} />;
      case "end":
        return <InfoMessage text={translate("shape.info.end", "This part ends the scenario for users at this juncture of the Scenario set . Users which meets the other conditions will continue to the next juncture of the Scenario")} />;
      default:
        return <InfoMessage text={translate("shape.info.step", "Scenario is tool used for multi messaging and auto reply to events(Follow, Rakuten Purchase etc.) based on the custom segments. Drag and Drop the parts from left side of the window to middle and set desired behaviors and actions to the said parts to build the Scenario set.")} />;
    }
  };

  const handleResize = () => {
    setHeight(window.innerHeight - 110);
    setWidth(window.innerWidth - 270 - 250 - 24 - 14);
  };

  const reload = React.useCallback(
    async () => {
      await onValidate(current);

      setLoading(true);

      setTimeout(() => {
        setCurrent(selected);
        setLoading(false);
      }, 300);
    }, [selected]);

  React.useEffect(() => {
    reload();
  }, [reload]);

  React.useEffect(() => {
    setChildrenForm(form);
  }, [form]);

  React.useEffect(() => {
    dispatch({ type: "process.selected", payload: { type: "" } });
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onCopy = async () => {
    let copied = await scenarioApi.copy(editable[1]._id);
    history.push(`/products/scenario/${copied._id}/editable`);
  };

  return (
    <PageContainer>
      <PageHeader onBack={() => history.push("/products/scenario")} title={(
        <SettingsForm
          ref={settingsFormRef}
          editable={["update", childrenForm[params.id]]}
          disabled={!isEditable}
        />
      )} extra={[
        editable[0] === "update" && <Button key={1} loading={loading} type="default" onClick={onCopy}>{translate("system.copy", "Copy")}</Button>,
        <Button key="cancel" type="default" onClick={() => history.push("/products/scenario")}>{translate("system.cancel", "Cancel")} </Button>,
        isEditable && <Button key="save" type="primary" onClick={() => onSave()}>{translate("system.save", "Save")} </Button>
      ]} />
      <PageContent>
        <Container>
          <Scenario height={height - 24} width={width} />

          <FormContent style={{ width: width / 1.5, height: height - 24 }}>
            <FormHeader>
              <div className="title">
                {(() => {
                  switch (current?.type) {
                    case "action":
                      return translate("scenario.form.title.action", "Action");
                    case "tag":
                      return (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                          {translate("scenario.form.title.tag", "Tag")}
                          {isEditable && <Button type="primary" size="small" onClick={() => setAction(["tag"])}><PlusOutlined /> {translate("scenario.form.create.tag", "Create new tag")}</Button>}
                        </div>
                      );
                    case "condition":
                      return translate("scenario.form.title.condition", "Condition");
                    case "judgement":
                      return translate("scenario.form.title.judgement", "Judgement");
                    case "timer":
                      return translate("scenario.form.title.timer", "Timer");
                    case "next":
                      return translate("scenario.form.title.next", "Next");
                    case "end":
                      return translate("scenario.form.title.end", "End");
                    case "start":
                      return translate("scenario.form.title.start", "Start");
                    default:
                      return translate("scenario.form.title.info", "Info");
                  }
                })()}
              </div>
              <Line />
            </FormHeader>
            <FormBody>
              <AntSpin spinning={loading}>
                {!loading && renderForm()}
              </AntSpin>
            </FormBody>
          </FormContent>
        </Container>
      </PageContent>

      <Modal
        maskClosable ={false}
        title={translate("system.add", "Add")}
        visible={action && action[0] === "tag"}
        onCancel={onCancel}
        destroyOnClose
        footer={false}>
        <Form onCancel={onCancel} onSubmit={onSubmit} action={action} />
      </Modal>
    </PageContainer>
  );
};

const AntSpin = styled(Spin)``;
const Container = styled.div`
  display: flex;
`;
const FormHeader = styled.div`
  display: inline;
  margin-bottom: 20px;
  .title { 
    margin-right: 5px;
    font-weight: bold;
  }
`;
const Line = styled.div`
  border-top: solid 1px ${colors.primaryDark};
  width : 100%;
  height : 0px;
  margin: 10px 0px;
`;
const FormContent = styled.div`
  border:solid 1px #ECECEC;
  border-radius : 8px;
  padding: 20px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.primaryDark}; 
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.primary};
  }
`;
const FormBody = styled.div``;