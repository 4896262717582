/* eslint-disable no-unreachable */
/* eslint-disable no-nested-ternary */
import React from "react";
import moment from "moment";
import { PageHeader, PageContent, PageContainer } from "../../components/Layout";
import { MyTable, RowAction, InvoiceMessage } from "../../components";
import { useHistory } from "react-router-dom";
import { message as notify, DatePicker, Empty, Input, Modal, Radio, Space, Tag } from "antd";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { message, tag } from "../../apis";
import { Button } from "../../components/Design";
import { SearchOutlined } from "@ant-design/icons";
import { usePermission, useTranslate } from "../../hooks";
import colors from "../../colors";
import styled from "styled-components";
import { useSelector } from "react-redux";
import usePlan from "../../hooks/usePlan";
import ManualDesc from "../../components/Manual/ManualDesc";
import { ManualLink } from "../../components/Manual";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default () => {
  const { translate } = useTranslate();
  const myTableRef = React.useRef();
  const { checkRole } = usePermission();
  const history = useHistory();
  const { customer } = useSelector(state=>state.general);
  const [query, setQuery] = React.useState("");
  const [pending, setPending] = React.useState();
  // const [balance, setBalance] = React.useState();
  const { checkPermission } = usePlan();
  const [rangePicker, setRangePicker] = React.useState({
    startDate: undefined,
    endDate  : undefined
  });

  const scheduled = useHeader({
    history,
    tableTitles: {
      title           : translate("system.title", "Title"),
      createdDate     : translate("message.createddate", "Created date"),
      sendDate        : translate("message.sentdate", "Send at"),
      type            : translate("message.type", "Target type"),
      totalSendMessage: translate("message.schedule.count", "Total send message"),
      sentType        : translate("message.senttype", "Sent type"),
      id              : translate("message.sendmessage.table.ID", "ID"),
      action          : translate("system.action", "Action"),
      edit            : translate("system.edit", "Edit"),
      remove          : translate("system.delete", "Remove"),
    },
    onAction: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/message/send/edit/${item._id}`);
          break;
        }
        case "remove": {
          confirm({
            icon   : <ExclamationCircleOutlined />,
            content: translate("system.confirmation.question", "Are you sure ?"),
            async onOk() {
              await message.remove(item._id);
              notify.success(translate("system.message.success", "Success"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
    translate,
    checkRole
  });
  const regularHeader = regular({
    history,
    tableTitles: {
      title      : translate("system.title", "Title"),
      createdDate: translate("message.createddate", "Created date"),
      regular    : translate("message.regular.setting", "Regular"),
      type       : translate("message.type", "Target type"),
      sentType   : translate("message.senttype", "Sent type"),
      action     : translate("system.action", "Action"),
      edit       : translate("system.edit", "Edit"),
      remove     : translate("system.delete", "Remove"),
    },
    onAction: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/message/send/edit/${item._id}`);
          break;
        }
        default:
      }
    },
    translate,
    checkRole
  });

  const failedHeader = failed({
    history,
    tableTitles: {
      title           : translate("system.title", "Title"),
      createdDate     : translate("message.createddate", "Created date"),
      sendDate        : translate("message.sentdate", "Send at"),
      type            : translate("message.type", "Type"),
      totalSendMessage: translate("message.totalsent", "Total send message"),
      sentType        : translate("message.senttype", "Sent type"),
      id              : translate("message.sendmessage.table.ID", "ID"),
      action          : translate("system.action", "Action"),
      edit            : translate("system.edit", "Edit"),
      error           : translate("message.failed", "Message error"),
      reply           : translate("system.resend", "Resend"),
      remove          : translate("system.delete", "Remove"),
    },
    onAction: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/message/send/edit/${item._id}`);
          break;
        }
        case "reply": {
          history.push(`/message/send/reply/${item._id}`);
          break;
        }
        default:
      }
    },
    translate,
    checkRole
  });

  const draftsHeader = drafts({
    history,
    tableTitles: {
      title      : translate("system.title", "Title"),
      createdDate: translate("message.createddate", "Created date"),
      action     : translate("system.action", "Action"),
      edit       : translate("system.edit", "Edit"),
      remove     : translate("system.delete", "Remove"),
    },

    onAction: (key, item) => {
      switch (key) {
        case "edit": {
          history.push(`/message/send/edit/${item._id}`);
          break;
        }
        default:
      }
    },
    checkRole
  });

  const [columns, setColumns] = React.useState("scheduled");
  const [filters, setFilters] = React.useState({
    query        : "",
    messageStatus: "SCHEDULED"
  });

  const selectTable = (e) => {
    setFilters({ ...filters, messageStatus: e.target.value });
    if (e.target.value === "SCHEDULED") {
      setColumns("scheduled");
    }
    else if (e.target.value === "DRAFT") {
      setColumns("draftsHeader");
    }
    else if (e.target.value === "FAILED") {
      setColumns("failedHeader");
    }
    else if (e.target.value === "REGULAR") {
      console.log("----REGULAR->");
      setColumns("regularHeader");
    }
  };

  const changeRange = (values) => {
    setFilters({ ...filters, startDate: values && moment(new Date(values[0])).startOf("day").toDate(), endDate: values && moment(new Date(values[1])).endOf("day").toDate() });
    setRangePicker({ startDate: values && values[0], endDate: values && values[1] });
  };

  const clearRange = () => {
    setFilters({ ...filters, startDate: null, endDate: null });
    setRangePicker({ startDate: undefined, endDate: undefined });
  };
  const columnsSwitch =(type)=>{
    switch (type){
      case "scheduled":
        return scheduled.filter(item => { return Object.keys(item).length > 0&& item;});
      case "regularHeader":
        console.log("=====REGULAR>");
        return regularHeader.filter(item => { return Object.keys(item).length > 0&& item;});
      case "draftsHeader":
        return draftsHeader.filter(item => { return Object.keys(item).length > 0&& item;});
      case "failedHeader":
        return failedHeader.filter(item => { return Object.keys(item).length > 0&& item;});
      default:
        return scheduled.filter(item => { return Object.keys(item).length > 0&& item;});
    }
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.send"></ManualLink>} title= { translate("message.sendmessage.sendlist", "Send list") } extra={[
        <Button key={1} type="primary" onClick={() => history.push("/message/send/new")}><PlusOutlined />{ translate("system.create", "New message") }</Button>
      ]} />
      <ManualDesc translateKey ="manual.desc.messagesend" />

      {/* {balance && !customer?.ecmaccesskey && <MessageBalance balanceMessage={balance.balance} plan={balance.plan} />} */}

      {pending && <InvoiceMessage message={pending} onFinish={() => {
        myTableRef.current.reload();
      }} />}
      {/* setFilters({ ...filters, startDate: moment(new Date(values[0])).startOf("day").toDate(), endDate: moment(new Date(values[1])).endOf("day").toDate() }) */}
      <PageFilter>
        <Space>
          <Input.Group compact>
            <Input
              name="query"
              placeholder={translate("system.search.button", "Search...")}
              onChange={e => setQuery(e.target.value)}
              style={{ width: 250 }}
              prefix={<SearchOutlined />}
            />
          </Input.Group>
          <Radio.Group defaultValue={filters.messageStatus} onChange={selectTable}>
            <Radio.Button value="SCHEDULED">{translate("message.schedule", "Scheduled")}</Radio.Button>
            {checkPermission("REGULAR") && <Radio.Button value="REGULAR">{translate("message.regular", "Regular")}</Radio.Button>}
            <Radio.Button value="DRAFT">{translate("message.draft", "Drafts")}</Radio.Button>
            <Radio.Button value="FAILED">{translate("message.failed", "Failed")}</Radio.Button>
          </Radio.Group>
        </Space>
        <Space>
          <RangePicker value={[rangePicker.startDate, rangePicker.endDate]} onChange={values => changeRange(values) } />
          <Button type="default" onClick={() => clearRange() }>{translate("system.clear", "Clear")}</Button>
        </Space>
      </PageFilter>

      <PageContent>
        <TableStyle>
          <MyTable
            filters={filters}
            ref={myTableRef}
            rowKey={(record) => record._id}
            // eslint-disable-next-line no-nested-ternary
            locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
            columns={columnsSwitch(columns)}
            onResult={({ pending, balance, plan } = {}) => {
              setPending(pending);
              // setBalance({ balance: balance, plan: plan });
            }}
            loadData={message.list("send")}
          />
        </TableStyle>
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate, checkRole }) => {
  return [{
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.createdDate,
    render: (item)=>{
      return (<span>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>);
    }
  }, {
    title : tableTitles.sendDate,
    render: (item)=>{
      return (<span>{moment(item.sendDate).format("YYYY-MM-DD HH:mm")}</span>);
    }
  }, {
    title : tableTitles.type,
    render: (record) => (
      <div style={{ display: "flex", gap: "5px" }}>
        <div>{translate(`system.constant.${record.targetType}`, record.targetType)}</div>
        <Tag>{translate(`system.constant.${record.messageType}`, record.messageType)}</Tag>
      </div>
    )
  }, {
    title : tableTitles.totalSendMessage,
    render: (record) => (
      <div>
        {record.totals}
      </div>
    )
  }, checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]) ? {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      if (order?.regularMessage){
        return <RowAction actions={{
          // edit  : tableTitles.edit,
          remove: tableTitles.remove
        }} onClick={(key) => onAction(key, order)} />;
      }
      return <RowAction actions={{
        edit  : tableTitles.edit,
        remove: tableTitles.remove
      }} onClick={(key) => onAction(key, order)} />;
    }
  }:{}];
};
const regular = ({ onAction, tableTitles, translate, checkRole }) => {
  return [{
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.createdDate,
    render: (item)=>{
      return (<span>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>);
    }
  }, {
    title : tableTitles.regular,
    render: (item)=>{
      return (<span>{item?.regular?.name}</span>);
    }
  }, {
    title : tableTitles.type,
    render: (record) => (
      <div>{translate(`system.constant.${record.targetType}`, record.targetType)}</div>
    )
  }, checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]) ? {
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      return <RowAction actions={{
        edit: tableTitles.edit,
      }} onClick={(key) => onAction(key, order)} />;
    }
  }:{}];
};
const failed = ({ onAction, tableTitles, translate, checkRole }) => {
  return [{
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.createdDate,
    render: (item)=>{
      return (<span>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>);
    }
  }, {
    title : tableTitles.sendDate,
    render: (item)=>{
      return (<span>{moment(item.messageSendDate).format("YYYY-MM-DD HH:mm")}</span>);
    }
  }, {
    title : tableTitles.type,
    render: (record) => (
      <div>{translate(`system.constant.${record.targetType}`, record.targetType)}</div>
    )
  }, {
    title : tableTitles.sentType,
    render: (record) => (
      <div>
        {translate(`system.constant.${record.sendType}`, record.sendType)}
      </div>
    )
  }, {
    title : tableTitles.error,
    render: (item)=>{
      if (item.messageError === "Request failed with status code 429"){
        return (<span>{"LINEの配信数上限を超えたため配信できませんでした"}</span>);
      }
      if (item.messageError === "INVALID_MESSAGE_BALANCE"){
        return (<span>{translate("sendlist.send.overlimit", "配信数上限を超えたため配信できませんでした")}</span>);
      }
      return (<span>{typeof(item.messageError) === "object" ? JSON.stringify(item.messageError, 2, null): item.messageError}</span>);

    }
  }, checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]) ?{
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      return <RowAction actions={{
        reply: tableTitles.reply,
      }} onClick={(key) => onAction(key, order)} />;
    }
  }:{}];
};

const drafts = ({ onAction, tableTitles, checkRole }) => {
  return [{
    title    : tableTitles.title,
    dataIndex: "title",
  }, {
    title : tableTitles.createdDate,
    render: (item)=>{
      return (<span>{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>);
    }
  }, checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT", "NO_MESSAGE"])?{
    title : tableTitles.action,
    key   : "action",
    width : 100,
    render: (order) => {
      return <RowAction actions={{
        edit: tableTitles.edit,
      }} onClick={(key) => onAction(key, order)} />;
    }
  }:{}];
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .ant-radio-button-wrapper {
    span {
      color: ${colors.primaryDark};
    }
  }
  .ant-radio-button-wrapper-checked {
    border-color: ${colors.primaryDark}!important;
    background: ${colors.primaryDark};
    span {
      color: #fff;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background: ${colors.primaryDark};
  }
`;

const TableStyle = styled.div`
.ant-table-row-expand-icon {
    display :none;
  }
`;