import styled from "styled-components";
import colors from "../../colors";

export default styled.div`
  background: #f8f8f8;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  justify-content: center;
  display: flex;
  flex-direction: center;
  align-items: center;

  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
  }
  
  .display-image-left {
    position: fixed;
    left: 0px;
    bottom: 0px;
    width: 278px;
  }
  .display-image-right {
    width : 278px;
  }
  .login {
    background: ${colors.primary};
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    width: 800px;
    margin: auto;
    padding-bottom: 10px;
    .alert {
      font-size: 12px;
      padding: 10px 10px 10px 35px;
      margin-bottom: 20px;
    }
    .form-input .icon {
      width : 14px
    }
    .form-input {
      height : 45px;
      border-radius: 10px;
    }
    .ant-select-selector {
      height        : 45px;
      border-radius : 10px;
    }
    .logo {
      text-align: center;
      margin: 10px 0 30px 0;

      img {
        height: 50px;
        margin: auto;
      }
    }
    .forgot {
      text-align: right;
      margin-top: 10px;
    }
    .form-text{
      color       : white;
      font-weight : 400;
      font-size   : 14px; 
      transition: all 0.3s ease-in;
      &:hover {
        color                       : #007b3b;
        text-decoration-line        : underline;
        // text-underline-position  : under;
      }
    }
    .logo-wrapper {
      display: flex;
      justify-content: center;
      margin-top: -91px;
    }
    .login-image{
      // position  : relative;
      // left      : -140px;
      // top       : -30px;
    }
    .logo-image{
      width: 140px;
      margin-bottom: 17px;
    }
    .form-button {
      height            : 40px;
      border-radius     : 10px;
      background-color  : ${colors.primaryDark};
      border            : none;
      font-size         : 21px;
      font-weight       : bold;
      transition: all 0.3s ease-in;
      &:hover {
        background-color  : #007b3b;
      }
    }
    .form-input-title {
      font-weight : 500;
      color       : white;
      font-size   : 12px;
      font-family : monospace;
    }
    .form-sub-title-text{
      color       : white;
      font-family : system-ui ,  unset;
      font-weight : 400;
    }
    .form-title-text {
      font-family : unset;
      font-size   : 18px;
      color       : white;
    }
    .register {
      text-align: center;
      margin-top: 10px;
    }
    .remember {
      margin-bottom: 10px;
    }
    .humans-image {
      position: relative;
      bottom: -20px;
      left: -20;
    }
    .form-collapse {
      background-color  : #6AD137;
    }
    .form-logo-image {
      position: absolute;
      left: -141px;
    }
    .form-footer-text{
      font-size: 12px;
      padding-top: 7px;
      font-weight: 500;
    }
    .ant-collapse-header {
      background        : white;
      margin-bottom     : 15px;
    }
    .form-title-one-text {
      color             : white;
      font-size         : 27px;
      font-weight       : bold;
    }
    .stepper-title {
      color: white;
      font-family: monospace;
      font-weight: 600;
    }
    .stepper-wrapper{
      display :flex;
      justify-content: center;

    }
    .stepper{
      width :349px;
    }
    .stepper-content-wrapper{
      display : flex;
      justify-content: center;
    }
    .stepper-content {
      width :300px;
      margin-top: 20px;
      border-radius : 5px;
      // height: 400px;
    }
    .form-image-cloud {
      position: absolute;
      width: 166px;
      top: 209px;
      right: 26px;
    }
    .form-image-bear{
      position: absolute;
      width: 233px;
      left: -87px;
      bottom: 135px;
    }
    .stepper-title {
      font-size: 14px;
      color: white;
      font-weight: 400;
      font-family: sans-serif;
    }
    .stepper-gustom{
      .ant-steps-item-process .ant-steps-item-icon {
        background-color :  ${colors.primaryDark};
        border-color     :  ${colors.primaryDark};
      }
      .ant-steps-item-wait .ant-steps-item-icon {
        border-color     : white;
        color            :  ${colors.primaryDark};
      }
      .ant-steps-item-finish .ant-steps-item-icon {
        border-color     :  ${colors.primaryDark};
        color            :  ${colors.primaryDark};
      }
      
      .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        border-color     :  ${colors.primaryDark};
        color            :  ${colors.primaryDark};
      
      }
      .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after  {
        background-color : ${colors.primaryDark};
      }
      .steps-action {
        margin-top: 24px;
      }
    }
    .ant-collapse-borderless >
     .ant-collapse-item:last-child,
      .ant-collapse-borderless > 
        .ant-collapse-item:last-child 
         .ant-collapse-header{
      border-radius: 10px;
      border: none;
    }
  }
`;
