import React from "react";
import ScenarioList from "./List";
import NewScenario from "./New";
import EditScenario from "./Edit";
import { scenario as scenarioApi } from "../../apis";
import { Space, message, Empty, Button } from "antd";
import { useHistory } from "react-router-dom";
import { MyTable, RowAction } from "../../components";
import { SearchOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { PageContainer, PageHeader, PageContent } from "../../components/Layout";
import { Modal, Input, Tag, Select } from "antd";
import { usePermission, useTranslate } from "../../hooks";
import styled from "styled-components";
import colors from "../../colors";
import Events from "./Events";
import { useSelector } from "react-redux";
import { ManualDesc, ManualLink } from "../../components/Manual";

export { ScenarioList, NewScenario, EditScenario };

const { Option } = Select;
const { confirm } = Modal;

export default () => {
  const { translate, lang } = useTranslate();
  const { checkRole } = usePermission();
  const myTableRef = React.useRef();
  const eventsRef = React.useRef();
  const history = useHistory();
  const [action, setAction] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const { events } = useSelector(state => state.process);
  const { processEvents } = useSelector(state => state.general);
  const [disabled] = React.useState(checkRole(["AGENCY", "ACCOUNT", "ADMIN"]) && checkRole(["AGENCY", "ACCOUNT", "ADMIN"]).permission(["ADMIN", "STAFF", "NO_REPORT"]));

  const [filters, setFilters] = React.useState({
    query      : "",
    eventType  : "",
    hide       : false,
    activeEvent: null
  });
  const convertArrayToObject = () => {
    const initialValue = {};
    if (processEvents){
      return processEvents.reduce((obj, item) => {
        return {
          ...obj,
          [item.code]: item,
        };
      }, initialValue);
    }
  };
  const columns = useHeader({
    history,
    translate,
    events,
    eventTypes : convertArrayToObject(),
    lang,
    tableTitles: {
      no       : translate("system.number", "No."),
      name     : translate("scenario.list.name", "Scenario set name"),
      eventType: translate("scenario.list.event", "Start event"),
      action   : translate("system.action", "Action"),
      edit     : translate("system.edit", "Edit"),
      remove   : translate("system.delete", "Remove"),
      copy     : translate("scenario.list.copy", "Copy"),
      status   : translate("system.status", "Status"),
      detail   : translate("system.detail", "Details"),
      activate : translate("scenario.list.activate", "Activate"),
      hide     : translate("scenario.list.hide", "Hide"),
      show     : translate("scenario.list.show", "Show")
    },
    onAction: async (key, item, subitem) => {
      switch (key) {
        case "activate":
          if (subitem) {
            setAction([item.eventType, { processId: item._id, index: subitem - 1 }]);
          } else {
            await scenarioApi.setEvent(item._id, { index: 0, type: item.eventType, active: true });
            eventsRef.current.reload();

            message.success(translate("system.message.success", "Success!"));
          }
          break;
        case "edit": {
          history.push(`/products/scenario/${item._id}/editable`);
          break;
        }
        case "hide": {
          await scenarioApi.hide(item._id);
          myTableRef.current.reload();
          message.success(translate("system.message.success", "Success!"));
          break;
        }
        case "show": {
          await scenarioApi.hide(item._id);
          myTableRef.current.reload();
          message.success(translate("system.message.success", "Success!"));
          break;
        }
        case "detail": {
          history.push(`/products/scenario/${item._id}`);
          break;
        }
        case "copy": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              let copied = await scenarioApi.copy(item._id);
              history.push(`/products/scenario/${copied._id}/editable`);
            },
          });
          break;
        }
        case "remove": {
          confirm({
            icon             : <ExclamationCircleOutlined />,
            content          : translate("system.confirmation.question", "Are you sure ?"),
            okText           : translate("system.yes", "Yes"),
            cancelText       : translate("system.no", "No"),
            okButtonProps    : { style: { backgroundColor: colors.primaryDark, borderColor: "white" } },
            cancelButtonProps: { style: { borderColor: colors.primaryDark, color: "black" } },
            className        : "btn-custom-class",
            async onOk() {
              await scenarioApi.remove(item._id);
              message.success(translate("system.message.success", "Success!"));
              myTableRef.current.reload();
            },
          });
          break;
        }
        default:
      }
    },
    disabled
  });

  const onSubmit = async (data) => {
    let id = await scenarioApi.create(data);

    message.success(translate("system.message.success", "Success!"));
    history.push(`/products/scenario/${id._id}/editable`);

    onCancel();
  };

  const onCancel = () => {
    setAction(null);
  };

  const onChangeEvent = (type) => {
    setFilters({
      ...filters,
      eventType: type
    });
  };

  React.useEffect(() => {
    let timer = setTimeout(() => {
      if (filters.query !== query)
        setFilters({ ...filters, query });
    }, 300);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <PageContainer>
      <PageHeader subTitle={<ManualLink link="manual.link.scenario"></ManualLink>} title={translate("scenario.list.title", "Scenario broadcast")} extra={[
        disabled && (<Button key="Create button" type="primary" onClick={() => history.push("/products/scenario/new")}><PlusOutlined />  {translate("scenario.list.create", "New scenario")}</Button>)
      ]} />
      <ManualDesc translateKey ="manual.desc.scenario" />
      <PageContent>
        <Events action={action} ref={eventsRef} disabled={disabled} scenarioTableRef={myTableRef} />
      </PageContent>

      <PageFilter>
        <Space>
          <Input
            name="query"
            placeholder={translate("system.search.button", "Search...")}
            onChange={e => setQuery(e.target.value)}
            style={{ width: 250 }}
            className="ant-input-custom"
            prefix={<SearchOutlined />}
          />
          <Select name="eventType" style={{ width: 250 }} onChange={onChangeEvent} placeholder={translate("scenario.report.filter.placeholder", "All scenario")} allowClear>
            {processEvents.map((event, index) => <Option value={event.code} key={index}>{event[`${lang?.toLowerCase()}`]}</Option>)}
          </Select>
          <Select name="isHide" defaultValue={filters.hide} style={{ width: 180 }} onChange={(e)=>setFilters({ ...filters, hide: e })} placeholder={translate("scenario.report.filter.placeholder", "Is show")} >
            <Option value={false}>{translate("scenario.list.filter.show", "Show")}</Option>
            <Option value={true}>{translate("scenario.list.filter.hide", "Hide")}</Option>
            <Option value={null}>{translate("system.all", "All")}</Option>
          </Select>
        </Space>
      </PageFilter>

      <PageContent>
        <MyTable
          locale={{ emptyText: (<Empty description={translate("system.nodata", "No data")} image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
          ref={myTableRef}
          filters={filters}
          rowKey={(record) => record._id}
          columns={columns}
          loadData={scenarioApi.list}
        />
      </PageContent>
    </PageContainer>
  );
};

const useHeader = ({ onAction, tableTitles, translate, events, disabled, eventTypes, lang }) => {
  if (Object.keys(eventTypes).length > 0){
    return [{
      title    : tableTitles.no,
      dataIndex: "key",
      width    : "20px",
    }, {
      title : tableTitles.name,
      render: (record) => {
        return `${record.name}`;
      },
    }, {
      title : tableTitles.eventType,
      render: (record) => {
        return eventTypes[record.eventType][lang.toLowerCase()];
      }
    }, {
      title : tableTitles.status,
      render: (row) => {
        let values = events.filter(event => event?.process?._id === row._id);

        if (["FOLLOW", "RAKUTEN_REGISTER", "RAKUTEN_PURCHASE"].indexOf(row.eventType) !== -1 && values.length > 0)
          return <Tag color="green">{translate("scenario.event.status.active", "Active")}</Tag>;


        if (values.length > 0) {
          return values.map((value, index) => {
            return (
              <Tag color="green" key={index}>{translate("scenario.event.priority", "Priority")}+{value.index + 1}</Tag>
            );
          });
        }

        switch (row.processStatus){
          case "FORCE_STOPPED":
          case "STOPPED":
            return <Tag color="orange">{translate("scenario.status.delivered", "Delivered")}</Tag>;
          case "NEW":
            return <Tag color="blue">{translate("scenario.status.new", "New")}</Tag>;
          default:
            return <div>-</div>;
        }
      }
    }, {
      title : tableTitles.action,
      key   : "action",
      width : 100,
      render: (row) => {
        let actions = {};

        // if (disabled === true&&!events.find(event => event?.process?._id === row._id)) {
        //   actions.activate = ["TAG", "TIME"].indexOf(row.eventType) !== -1 ? ({
        //     name    : tableTitles.activate,
        //     children: [1, 2, 3, 4, 5]
        //   }) : tableTitles.activate;
        // }
        if (disabled === true && !events.find(event => event?.process?._id === row._id && disabled === true)){
          actions.edit = tableTitles.edit;
          actions.remove = tableTitles.remove;
        }
        if (disabled === true && !events.find(event => event?.process?._id === row._id && disabled === true) && (row.processStatus !== "FORCE_STOPPED") && (row.processStatus !== "START") && (row.processStatus !== "STOPPED")) {
          actions.edit = tableTitles.edit;
          actions.hide = row.hide === true?tableTitles.show:tableTitles.hide;
          actions.remove = tableTitles.remove;
        }
        if (disabled === true && !events.find(event => event?.process?._id === row._id && disabled === true)) {
          actions.hide = row.hide === true?tableTitles.show:tableTitles.hide;
        }
        // if (disabled === true && !events.find(event => event?.process?._id === row._id && disabled === true) && row.processStatus !== "START") {
        //   // actions.edit = tableTitles.edit;
        //   actions.hide = tableTitles.hide;
        //   // actions.remove = tableTitles.remove;
        // }

        // if (disabled === true && !events.find(event => event?.process?._id === row._id && disabled === true) && row.processStatus === "FORCE_STOPPED") {
        //   actions.hide = tableTitles.hide;
        // }

        if (disabled)
          actions.copy = tableTitles.copy;

        actions.detail = tableTitles.detail;

        return (
          <RowAction
            actions={actions}
            onClick={(key, subitem) => onAction(key, row, subitem)}
          />
        );
      },
    }];
  }
};

const PageFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  justify-content: space-between;
  .ant-input-custom:hover{
    border-color :green !important;
  } 
  .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color :green !important;
  }
`;
