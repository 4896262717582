import React from "react";
import { imagemap as imagemapService } from "../../../apis";
import { useParams } from "react-router";
import Form from "./Form";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

export default () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { imageMapTemplates } = useSelector(state => state.general);
  const [editable, setEditable] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const reload = React.useCallback(async (signal) => {
    setLoading(true);
    try {
      let res = await imagemapService.get(params.id, { signal });

      let actions = res.actions.map(action => ({
        ...action,
        area: {
          x     : action.area.x / 3,
          y     : action.area.y / 3,
          width : action.area.width / 3,
          height: action.area.height / 3
        }
      }));

      let payload = {
        _id       : res._id,
        type      : res.type,
        template  : res.template,
        baseUrl   : res.baseUrl,
        baseSize  : res.baseSize,
        altText   : res.altText,
        previewUrl: res.previewUrl,
        isRakuraku: res.isRakuraku,
        actions
      };

      dispatch({
        type   : "general.imagemapSelect",
        payload: {
          ...payload,
          actions: actions
        }
      });

      if (payload.type !== "draw") {
        dispatch({
          type   : "general.imagemapSelect.templates",
          payload: imageMapTemplates[payload.type][payload.template] || []
        });
      }

      setEditable(["update", payload]);
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [params.id]);

  React.useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    reload(signal);

    return () => abortController.abort();
  }, [reload]);

  if (!editable || loading)
    return <Spin spinning={true}/>;

  return <Form editable={editable} />;
};