import React from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Alert, Card, Switch } from "antd";
import Button from "../../components/Design/Button";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { auth, general } from "../../apis";
import { useHistory, Link, useRouteMatch } from "react-router-dom";
import UserIcon from "../../assets/user.png";
import PasswordIcon from "../../assets/password.png";
import Logo from "../../assets/logo_white.png";
import RakuLineLogoImage from "../../assets/logo_rakuline_white.png";
import Humans from "../../assets/humans.png";
import styled from "styled-components";
import { useTranslate } from "../../hooks";
import { WarningOutlined, BulbOutlined } from "@ant-design/icons";
import EcLogo from "../../assets/ec-logo.png";
import colors from "../../colors";

const FormSchema = (translate) => Yup.object().shape({
  username: Yup.string().required(translate("system.message.required", "This field is required!")),
  password: Yup.string().required(translate("system.message.required", "This field is required!")),
});

export default (props) => {
  const { translate, changeLanguage, lang } = useTranslate();
  const [logginIn, isLoggingIn] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const { ecm_verifyUrl, mobileHost } =useSelector(state =>state.general);
  const history = useHistory();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const [alertBgColor, setAlertBgColor] = React.useState("#E35755");

  const onSubmit = async (data) => {
    isLoggingIn(true);

    try {
      const res = await auth.login(data);

      dispatch({
        type   : "auth.change",
        payload: res
      });
      console.log("======>", res);
      dispatch({ type: "loading", payload: true });
      history.replace("/");
      dispatch({ type: "balance.notfication", payload: true });

    } catch (error) {
      setAlertBgColor("#E35755");
      if (error.message === "AUTHENTICATION_FAILED")
        setMessage(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("login.invalid", "The password or email you entered was not valid..")}</div>);

      if (error.message === "USER_DISABLED")
        setMessage(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("system.alert.disabled", "Your user account is disabled by agency or admin.")}</div>);

      if (error.message === "USER_NOT_VERIFIED")
        setMessage(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("login.user.not.verified", "Your account hasn't been verified")}</div>);

      if (error.message === "PASSWORD_BLOCKED")
        setMessage(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("login.password.blocked", "Account has been blocked due to too many failed login attempts")}</div>);

      if (error.message === "CUSTOMER_PAYMENT_BLOCKED")
        setMessage(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("login.payment.blocked", "Your account has been blocked due to failed payment")}</div>);
    }

    isLoggingIn(false);
  };

  const ecmLogin =()=> {
    // console.log("ECM_LOGIN_REDIRECT_URL_DEV===>",env.ECM_LOGIN_REDIRECT_URL_DEV);

  };

  React.useEffect(() => {
    if (props.location.state && props.location.state.alert&&props.location.state.alert === true) {
      setMessage(<div style={{ color: "white", fontWeight: "600" }}><WarningOutlined style={{ marginRight: "6px" }} /> {translate("system.login.token", "Your account has been created. Use your mail address and password to login into your account")}</div>);
    }
  }, [props.location.state]);

  return (
    <AuthWrapper>
      <Card className="login">
        {process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <a style={{ color: "white", width: "max-content" }} href={mobileHost} >
            {translate("login.mobile", "Mobile")}
          </a>
        </div>}
        <div className="logo">
          <img className="logo" src={process.env.REACT_APP_WEBSITE_MODE == "rakuline" ? RakuLineLogoImage : Logo}/>
        </div>

        {message && <Alert style={{ marginBottom: 20, background: alertBgColor, border: "none", borderRadius: "10px" }} message={<div style={{ color: "white" }}>{message}</div>} />}
        <Formik
          initialValues={{
            username: undefined,
            password: undefined,
          }}
          validationSchema={() => FormSchema(translate)}
          onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form layout="vertical">
              <FormItem name="username">
                <Input className = "form-input" prefix={<img className="icon" src={UserIcon}/>} name="username" placeholder={translate("login.mail", "Example@yahoo.com")} />
              </FormItem>
              <FormItem name="password" style={{ marginBottom: 20 }}>
                <Input.Password className="form-input" prefix={<img className="icon" src={PasswordIcon} />} name="password" placeholder={translate("login.password", "Password")} />
                <div className="forgot">
                  <Link className="form-text" to="/forgot">{translate("login.forgetpassword", "Forgot password!")}</Link>
                </div>
              </FormItem>
              <Button className="form-button" htmlType="submit" type="primary" loading={isSubmitting} block>
                <span>{logginIn ? translate("system.loading", "Logging in...") : translate("system.login", "Login")}</span>
              </Button>
              {/* <div
                style={{ display: "flex", justifyContent: "space-between" }}
              > */}
              {process.env.REACT_APP_WEBSITE_MODE !== "rakuline" && <div>
                <div className="register">
                  <a style={{ color: "white" }} href={translate("login.register_link", "#")} target="_blank">
                    {translate("login.register", "Register")}
                  </a>
                </div>
                <div className="register">
                  <a style={{ color: "white" }} href={translate("system.manual_link", "#")} target="_blank">
                    <BulbOutlined /> {translate("system.manual", "Manual")}
                  </a>
                </div>
                <hr/>
                <div className="register" style={{ color: "white" }} >{translate("ecm.login.info", "Click below to login with EC Masters club")}</div>
                <div className="register">
                  <a style={{ color: "white" }} href={ecm_verifyUrl} >
                    <div className="register-logo-box">
                      <img width={"100%"} src={EcLogo} className="register-logo"/>
                    </div>
                  </a>
                </div>
              </div>}
            </Form>
          )}
        </Formik>
        <Switch style={{ marginTop: 5 }} checkedChildren="JP" unCheckedChildren="EN" onChange={() => changeLanguage(lang === "EN" ? "JP" : "EN") } defaultChecked={lang === "EN"} />
        <img className="humans-image" src={Humans}/>
      </Card>
    </AuthWrapper>
  );
};

const AuthWrapper = styled.div`
  background          : #f8f8f8;
  position            : absolute;
  top                 : 0;
  bottom              : 0;
  right               : 0;
  left: 0;
  justify-content     : center;
  display             : flex;
  flex-direction      : center;
  align-items         : center;
  
  .ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
  }

  .login {
    background        : ${colors.loginPrimary};
    border-radius     : 10px;
    width             : 400px;
    margin            : auto;
    padding-bottom    : 10px;

    .form-input .icon {
      width           : 14px
    }
    .form-input {
      height          : 45px;
      border-radius   : 10px;
    }
    .logo {
      text-align      : center;
      margin          : 10px 0 30px 0;
      img {
        height        : 80px;
        margin        : auto;
      }
    }
    .forgot {
      text-align      : right;
      margin-top      : 10px;
    }
    .form-text{
      color           : white;
      font-weight     : 400;
      font-size       : 14px; 
      transition      : all 0.3s ease-in;
      &:hover {
        color         : #007b3b;
        text-decoration-line : underline;
      }
    }
    .form-button {
      height            : 40px;
      border-radius     : 10px;
      background-color  : ${colors.loginButton};
      border            : none;
      font-size         : 21px;
      font-weight       : bold;
      transition: all 0.3s ease-in;
      &:hover {
        background-color :${colors.dark}
      }
    }
    .register {
      text-align      : center;
      margin-top      : 10px;
      display: flex;
      justify-content: center;

      .register-logo-box {
        border:solid 1px;
        background : white;
        width : 111px;
      }
    }
    .humans-image {
      position        : relative;
      bottom          : -20px;
      left            : -20;
    }
    .form-logo-image {
      position        : absolute;
      left            : -141px;
    }
  }
`;