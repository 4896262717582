import React, { useEffect } from "react";
import { Form, Input, FormItem } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Button } from "antd";
import * as Yup from "yup";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";

const DataSchema = (translate) => Yup.object().shape({
  ecmaccesskey: Yup.string().optional().nullable().trim(),
  ecmid       : Yup.string().optional().nullable().trim(),
});

const FormSchema = (translate) => Yup.object().shape({
  customerData: DataSchema(translate),
  accountsData: Yup.array().of(DataSchema(translate)).min(0),
});

export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = React.useState({
    accountsData: [{
      id           : null,
      name         : null,
      shopId       : "",
      rakurakuToken: ""
    }],
    customerData: {
      companyId   : "",
      id          : null,
      name        : null
    },
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  return (
    <Formik
      initialValues={data}
      validationSchema={() => FormSchema(translate)}
      onSubmit={onSubmit}>
      {({ isSubmitting, values }) => {

        return (
          <Form layout="vertical">
            <h3>{values.customerData.name}</h3>
            <Input type="hidden" name={"customerData.id"} />
            <ItemsWrapper >
              <FormItem label={translate("rakuraku.companyid", "Company ID")} name="customerData.companyId" required>
                <Input name="customerData.companyId" placeholder={translate("rakuraku.companyid", "Company ID")} />
              </FormItem>
            </ItemsWrapper>

            {values?.accountsData?.map((item, index) => {
              return <div key={index} style={{ marginTop: "20px" }}>
                <h3>{item.name}</h3>
                <Input type="hidden" name={`accountsData[${index}].id`} />
                <ItemsWrapper>
                  <FormItem label={translate("rakuraku.shopid", "Shop ID")} name={`accountsData[${index}].shopId`} required>
                    <Input name={`accountsData[${index}].shopId`} placeholder={translate("rakuraku.shopid", "Shop ID")}  />
                  </FormItem>
                  <FormItem label={translate("rakuraku.rakurakutoken", "Rakuraku API Token")} name={`accountsData[${index}].rakurakuToken`} required>
                    <Input name={`accountsData[${index}].rakurakuToken`} placeholder={translate("rakuraku.rakurakutoken", "Rakuraku API Token")}  />
                  </FormItem>
                </ItemsWrapper>
              </div>;
            })}
{/* 
            <AntForm.Item>
              <Button style={{ float: "right", marginRight : 10, marginTop: "10px", width: "200px" }} htmlType="submit" type="primary" loading={isSubmitting} block >{translate("system.save", "Save")}</Button>
            </AntForm.Item> */}
          </Form>
        );
      }}
    </Formik>
  );
};

const ItemsWrapper = styled.div `
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 20px;
  background: #fcfcfc; 
`;
