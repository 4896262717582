export default {
  "manual.alert.staff"          : "System does not allow multiple logins to one login account. We recommend adding additional staff for each number of staff who will be logging in.",
  "manual.desc.staff"           : "You can add an ID (email address, password) that can log in to System.",
  "manual.alert.messagesent"    : "Please check the Report menu to check the open rate, click rate, etc",
  "manual.alert.template"       : "Use the LINE login function. If you cannot open the page, please check your LINE login settings.",
  "manual.alert.richmenu"       : "To display the rich menu when adding new friends, please set the default settings.",
  "manual.alert.tags"           : "Once registered, only the tag name can be changed.",
  "manual.alert.broadcastreport": "The first 20 items are the latest data. The data after 20 items will be updated by Show details. (up to 60 items can be updated in 1 hour)",
  "manual.alert.multicastreport": "Since opening data is not provided by LINE, the aggregation logic is based on own specifications.",
  "manual.alert.scenarioreport" : "The aggregation period is only the cumulative total of all periods.",
  "manual.alert.triggerreport"  : "The aggregation period is only the cumulative total of all periods.",
  "manual.alert.operationreport": "Distribution via LINE Official Account Manager is not counted.",
  "manual.desc.follower"        : "You can check the list of friends. It is possible to add tags individually.",
  "manual.desc.messagesend"     : "You can check messages scheduled for delivery or create new ones.",
  "manual.desc.messagesent"     : "You can check the number of messages delivered and copy them. You can also check the scenario and trigger action of the delivered message.",
  "manual.desc.regular"         : "This is a function that allows you to send messages on a fixed day every month or week, such as Wonderful Day or days with 5 and 0.",
  "manual.desc.template"        : "This is a function to open a web page created. Friends who visit your page can be automatically tagged.",
  "manual.desc.targetform"      : "You can register frequently used custom segments. Registered custom segments can be called up in distribution or rich menus.",
  "manual.desc.image"           : "This function creates images with links. It is possible to send by distribution, trigger action, etc.",
  "manual.desc.video"           : "This function allows you to create videos with links. It is possible to send by distribution, trigger action, etc.",
  "manual.desc.carousel"        : "This function allows you to create card-type messages that can be slid left and right.",
  "manual.desc.richmenu"        : "This is a function to set up a rich menu in the talk room. You can also create a tab switching rich menu from here._",
  "manual.desc.scenario"        : "This function allows you to automatically send messages and automatically add tags based on specific start conditions. Step delivery by specifying a date is possible.",
  "manual.desc.trigger"         : "This function allows you to automatically send messages and automatically add tags based on specific start conditions.",
  "manual.desc.tags"            : "This is a function to manage tags (labels) given to friends. You can create or delete new tags.",
  "manual.desc.webhook"         : "This function forwards webhooks notified from LINE to a specified URL. Please set this when using other tools together.",
  "manual.desc.liff"            : "This function allows you to create a special URL or QR code that can be automatically tagged when you register as a friend.",
  "manual.desc.token"           : "You can set an API token that allows you to tag your friends externally.",
  "manual.desc.cooperation"     : "Create a form to link your friends' Rakuten order information. The created linked form can be opened from the rich menu or rich message.",
  "manual.desc.coupon"          : "Create a coupon for Rakuten (create a coupon for RMS). Coupon specifications follow Rakuten.",
  "manual.desc.reports"         : "You can check the usage status of issued coupons. The number of tickets used indicates the number of tickets used by users whose order information has been linked.",
  "manual.desc.payment"         : "System payment history. It will not be displayed for those using the EC Masters Club collaboration.",
  "manual.desc.broadcastreport" : "You can check the distribution results of Overall or Narrowcast. The specifications of the report are based on LINE, and numbers such as opens and clicks that are less than 20 will be displayed as "-".",
  "manual.desc.multicastreport" : "You can check the results of distribution by specifying custom segment.",
  "manual.desc.scenarioreport"  : "You can check the operating status of the distribution set in Scenario Distribution. You can check the number of people passing through each step and action.",
  "manual.desc.triggerreport"   : "You can check the operating status of the distribution set in Trigger Action. You can check the number of people passing through each step and action.",
  "manual.desc.operationreport" : "This is a chronological aggregation of all distribution data, including overall distribution, segment distribution, trigger actions, etc.",
  "manual.desc.log"             : "This is a list of operation logs including staff.",
  "manual.desc.webhookreport"   : "If you are using webhook transfer, you can check the error log for transfer failures.",
  "manual.desc.file"            : "You can download reports such as operation reports that have been exported as files.",
  "manual.alert.trigger"        :	"With this feature, you cannot set automatic date and time settings such as sending a message 3 or 5 days after adding a friend. Please use scenario distribution.",
};