import React, { useState } from "react";
import { Form, FormItem, Select, Checkbox } from "formik-antd";
import { Formik } from "formik";
import { Form as AntForm, Spin } from "antd";
import * as Yup from "yup";
import { Button } from "../../../components/Design";
import { useTranslate } from "../../../hooks";
import styled from "styled-components";
import colors from "../../../colors";

const FormSchema = (translate) => Yup.object().shape({
  notifMessageStatus: Yup.string().required(translate("system.message.required", "Please fill out this field!")),
});
const { Option } = Select;

const notifStatuses = [
  { code: "UNAPPLIED", name: "未申請" },
  { code: "APPLIED", name: "利用申請中" },
  { code: "APPROVED", name: "利用中" },
  { code: "STOPPED", name: "停止中" },
];

export default ({ action, onSubmit }) => {
  const { translate } = useTranslate();
  const [data] = useState({
    notifMessageStatus  : action[1].settings?.notifMessageStatus || undefined,
    notifMessageDelegate: action[1].settings?.notifMessageDelegate || false
  });

  return (
    <Spin spinning={false}>
      <Formik
        initialValues={data}
        validationSchema={() => FormSchema(translate)}
        onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form layout="vertical">
            <FormItem label={translate("debug.account.settings.form.notifmessage", "Delivery message status")} name="notifMessageStatus" required>
              <Select name="notifMessageStatus" placeholder={translate("debug.account.settings.form.notifmessage", "Delivery message status")}>
                {notifStatuses.map(type => {
                  return <Option key={type._id} value={type.code}>{type.name}</Option>;
                })}
              </Select>
            </FormItem>
            <FormItem name="notifMessageDelegate" label={translate("delivery.message.delegate.label", "Delegate")} required>
              <SwitchStyle>
                <Checkbox name="notifMessageDelegate">{translate("delivery.message.delegate.text", "Use delegate account")}</Checkbox>
              </SwitchStyle>
            </FormItem>
            <AntForm.Item>
              <Button htmlType="submit" type="primary" loading={isSubmitting} block>{translate("system.save", "Save")}</Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </Spin>
  );
};
const SwitchStyle = styled.div`
  display :flex;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color : ${colors.primaryDark};
  }
  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-wrapper {
    padding-top:3px;
  }
  .ant-checkbox-inner::hover {
    border-color : yellow;
  }
`;
